// // Autocomplete.js
import React, { useState, useEffect, useRef,Component } from 'react';
const google = window.google;

// const options = {
//         types: ["establishment"],
//     componentRestrictions: { country: ['in', 'us'] } 
// };
// const AutoComplete = (props) => {

//     const [inputValue, setinputValue] = useState();
//     let  searchInput = useRef(null);
//     let  autoComplete = useState();
//     const [value, setValue] = useState(null);


//     useEffect(() => {
//         console.log(21)
//         setValue(props.value);
//         // console.log(searchInput)
//         autoComplete = new google.maps.places.Autocomplete(
//             searchInput,
//             options
//         );
//         autoComplete.addListener('place_changed', onPlaceChanged);
//     }, []); 

//     useEffect(() => {
//         searchInput.current = value; // cache current value for next render
//      },[value]);

//     const onPlaceChanged = () => {
//         let place = autoComplete.getPlace();
//         console.log(place)
//         if (!place.geometry) return; 
        
//         // searchInput.blur();
//         setinputValue(place.formatted_address);
//         console.log(inputValue)
//         // searchInput.current = place.formatted_address;
//         setValue(place.formatted_address);
//         props.addplace(place);
//     }

//     return (
//             <input ref={searchInput}  defaultValue={inputValue} type="text" placeholder="Enter a location"  />
//     );

    
// }

// export default AutoComplete;


class AutoComplete extends Component {
    constructor(props) {
        super(props);
        // this.clearSearchBox = this.clearSearchBox.bind(this);
        this.inputValue=props.value;
        // this.searchInput.value = props.value;
    }
    componentDidMount() {
        const options = {
              types: ['address'],
            componentRestrictions: { country: ['in', 'us'] } 
        };
        this.autoComplete = new google.maps.places.Autocomplete(
            this.searchInput,
            options
        );
        // this.autocomplete.setTypes(['address', 'establishment']);
        this.autoComplete.addListener('place_changed', this.onPlaceChanged);
    }

    componentWillUnmount() {
        new google.maps.event.clearInstanceListeners(this.searchInput);
    }

    componentDidUpdate() {
         this.searchInput.value = this.props.value;
    }
    onPlaceChanged = ({ addplace, value} = this.props) => {
        const place = this.autoComplete.getPlace();
        // console.log(place)
        if (!place.geometry) return;
        addplace(place);
        // console.log(place)
        this.searchInput.blur();
        this.inputValue=place.formatted_address;
        this.searchInput.value = place.formatted_address;
    };

    // clearSearchBox() {
    //     // this.searchInput.value = '';
    // }

    render() {
        return (
                <input
                   
                    ref={(ref) => {
                        this.searchInput = ref;
                    }}
                    type="text"
                    defaultValue={this.inputValue}
                    placeholder="Enter a location"
                />
        );
    }
}

export default AutoComplete;