import { Link } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import MetaHelmet from './../metaHelmet'; //Include metaHelmet 
// import Service from './../../service'; 
// import toast, { Toaster } from 'react-hot-toast';
// import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
const WhyChooseUs = (props) => {

	const [config, setconfig] = useState(props.config);


	useEffect(() => {
		document.title = "Move It | Why Choose Us";
		window.gtag('config', config.G_TAG, {
			page_title: document.title,
			page_path: window.location.pathname,
		});
	}, []);


	return (
		<>
			<MetaHelmet title="Move It | Why Choose Us" description="" />
			{/* <Toaster position="top-right" reverseOrder={false} /> */}
			<Header></Header>
			<main>


				<section className="hero clean faq-andimg" style={{ backgroundImage: `url('web/img/05.jpg')` }}>
					<article>
						<div className="grid">
							<div className="unit-2-3 unit-1-1-md">
								<h1 className="h1 f-46">Why Choose Move It</h1>
							</div>
						</div>
					</article>
				</section>



				<section className="slate cspt-steps-counter">
					<article>
						<div className="grid-center margin-bottom-sm text-center">
							<div className="unit-2-1 unit-1-1-md mb-5">
								<h3 className="flourish text text-left whychoosepage"><span className="flourish-square z-dx1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(255,149,162)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span> <b>Why Choose Move It </b> <br />
									<small>Why Move It is leading the way for small move & delivery service</small></h3>
							</div>
						</div>



						<div className="row mb-5">
							<div className="col-md-4 col-lg-4">
								<div className="themestek-ihbox-inner">
									<div className="themestek-ihbox-icon">
										<div className="why-imgicons"><img src="web/img/delivery-time.png" alt="" /></div>
									</div>
									<div className="themestek-ihbox-contents">
										<div className="themestek-vc_general">
											<h2 className="themestek-custom-heading ">On Demand Scheduling</h2>
										</div>
										<div className="themestek-cta3-content-wrapper">Set a time that works for you. Whether it is right away or schedule in advance, Move It will be there for you.</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-lg-4">
								<div className="themestek-ihbox-inner">
									<div className="themestek-ihbox-icon">
										<div className="why-imgicons"><img src="web/img/bank.png" alt="" /></div>
									</div>
									<div className="themestek-ihbox-contents">
										<div className="themestek-vc_general">
											<h2 className="themestek-custom-heading ">Guaranteed Pricing</h2>
										</div>
										<div className="themestek-cta3-content-wrapper">Get a guaranteed quote when you book. Save up to 50-80% compared to traditional moving companies.</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-lg-4">
								<div className="themestek-ihbox-inner">
									<div className="themestek-ihbox-icon">
										<div className="why-imgicons"><img src="web/img/booking.png" alt="" /></div>
									</div>
									<div className="themestek-ihbox-contents">
										<div className="themestek-vc_general">
											<h2 className="themestek-custom-heading ">Quick and Easy</h2>
										</div>
										<div className="themestek-cta3-content-wrapper">Our in app and online technology makes booking very easy. Request a Move It Pro or Muscle in less than a minute.</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-4 col-lg-4">
								<div className="themestek-ihbox-inner">
									<div className="themestek-ihbox-icon">
										<div className="why-imgicons"><img src="web/img/feedback.png" alt="" /></div>
									</div>
									<div className="themestek-ihbox-contents">
										<div className="themestek-vc_general">
											<h2 className="themestek-custom-heading ">Customer Reviewed</h2>
										</div>
										<div className="themestek-cta3-content-wrapper">All Move It Pros & Muscles are reviewed by customers just like you. You can review what others have rated before you accept.</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-lg-4">
								<div className="themestek-ihbox-inner">
									<div className="themestek-ihbox-icon">
										<div className="why-imgicons"><img src="web/img/protection.png" alt="" /></div>
									</div>
									<div className="themestek-ihbox-contents">
										<div className="themestek-vc_general">
											<h2 className="themestek-custom-heading ">You are Protected</h2>
										</div>
										<div className="themestek-cta3-content-wrapper">Every item delivered or moved is backed by our top of the line, limited commercial insurance policy.</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-lg-4">
								<div className="themestek-ihbox-inner">
									<div className="themestek-ihbox-icon">
										<div className="why-imgicons"><img src="web/img/award.png" alt="" /></div>
									</div>
									<div className="themestek-ihbox-contents">
										<div className="themestek-vc_general">
											<h2 className="themestek-custom-heading ">Superior Service</h2>
										</div>
										<div className="themestek-cta3-content-wrapper">Move It helpers have a commitment to excellence. Always striving for 100% customer satisfaction.</div>
									</div>
								</div>
							</div>
						</div>


					</article>
				</section>


				<section className="vc_custom_banner">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								{/*<Link to="" className="video-play-button" ><span></span></Link>*/}
							</div>
						</div>
					</div>
				</section>

				<Link className="section red padd-50" to="/contact-us">
					<article className="text-center">
						<h6 className="h2">Still have  <strong> questions? </strong></h6>
						<span className="button outline inverse">Contact Us <i className="fa fa-chevron-right"></i></span>
					</article>
				</Link>
			</main>

			<Footer></Footer>
		</>
	);
}
export default WhyChooseUs;

