
import { Link, useLocation } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import CustomerSideBar from './../layout/CustomerSideBar';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet

import Service from './../../service';
import toast, { Toaster } from 'react-hot-toast';
import React, { useState, useEffect } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import ProfileSection from './profileSection';
import moment from 'moment-timezone';
import alertConfirm, { Button } from "react-alert-confirm";
import "react-alert-confirm/dist/index.css";
// import PaginationComponent from "react-reactstrap-pagination";
import PaginationComponent from '../../PaginationComponent';

import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import { useMount } from 'react-use';


const api = new Service();
const MyMoves = (props) => {
    const location = useLocation();
    const [loading, setloading] = useState(false);
    const [page_no, setpage_no] = useState(1);
    const [pagesCount, setpagesCount] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    const [activeTab, setactiveTab] = useState(location.state == null ? 'tab4' : 'tab1');
    const [reason, setReason] = useState('');
    const [requestId, setrequestId] = useState(0);
    const [showModal, setshowModal] = useState(false);
    const [deleteMoveType, setdeleteMoveType] = useState(false);
    const [otherReason, setotherReason] = useState(false);
    const [reasonError, setreasonError] = useState(false);


    const hideModal = () => {
        setshowModal(false);
    };

    const navigate = useNavigate();

    useEffect(() => {
        // GetCustomerPendingMoves();
        // GetCustomerDraftApi();
        if (activeTab == 'tab1') {
            GetCustomerPendingMoves();
        }
        else {
            GetCustomerDraft();
        }
    }, [])

    const GetCustomerPendingMoves = (page_no = 1) => {
        setloading(true)
        setpage_no(page_no)
        api.postApi('get-customer-pending-move', { page_no: page_no, timezone: moment.tz.guess() }).then(response => {
            setDataArray(response.data);
            console.log(response);
            setpagesCount(response.totalItemsCount)
            setloading(false)
        }).catch(error => {
            setloading(false)
            toast.error(error.message);
        });
    }


    const GetCustomerUpcomingMoves = (page_no = 1) => {
        setloading(true)
        setpage_no(page_no)
        api.postApi('get-customer-move-request', { page_no: page_no, timezone: moment.tz.guess() }).then(response => {
            setDataArray(response.data);
            setpagesCount(response.totalItemsCount)
            setloading(false)
        }).catch(error => {
            setloading(false)
            console.log(error)
            toast.error(error.message);
        });
    }

    const GetCustomerCompleteMoves = (page_no = 1) => {
        setloading(true)
        setpage_no(page_no)
        api.postApi('get-customer-completed-move', { page_no: page_no, timezone: moment.tz.guess() }).then(response => {
            setDataArray(response.data);
            setpagesCount(response.totalItemsCount)
            setloading(false)
        }).catch(error => {
            setloading(false)
            toast.error(error.message);
        });
    }

    const GetCustomerDraft = (page_no = 1) => {
        setloading(true)
        setpagesCount(page_no)
        api.postApi('get-customer-draft', { timezone: moment.tz.guess() }).then(response => {
            setDataArray(response.draft_data);
            setloading(false)
        }).catch(error => {
            setloading(false)
            console.log(error)
        })
    }

    const changeTab = (tab) => {
        setpagesCount(0)
        setactiveTab(tab)
        setDataArray([]);
        if (tab == 'tab1') {
            GetCustomerPendingMoves(1);
        } else if (tab == 'tab2') {
            GetCustomerUpcomingMoves(1);
        } else if (tab == 'tab3') {
            GetCustomerCompleteMoves(1);
        } else if (tab == 'tab4') {
            GetCustomerDraft(1);
        }
    }
    const getRecord = (page) => {
        if (page != page_no) {
            if (activeTab == 'tab1') {
                GetCustomerPendingMoves(page);
            } else if (activeTab == 'tab2') {
                GetCustomerUpcomingMoves(page);
            } else if (activeTab == 'tab3') {
                GetCustomerCompleteMoves(page);
            } else if (activeTab == 'tab4') {
                GetCustomerDraft(page);
            }
        }
    }


    const Component = (option, deleteType) => {
        // Here it's ReactElement
        if (deleteType == 'upcoming' && option.is_cancellection_charge == 1 && option.job_started == false && option.job_on_the_way == false) {
            return <div>Are you sure you want to cancel this move? <br />
                There will be a 20% cancellation fee for canceling within 24 hours of scheduled service.
            </div>

        } else if (deleteType == 'upcoming' && option.job_started == false && option.job_on_the_way == false) {
            return <div>Are you sure you want to cancel this move? </div>
        } else if (deleteType == 'upcoming' && (option.job_started == true || option.job_on_the_way == true)) {
            return <div>Are you sure you want to cancel this move?  <br />
                There will be a 50% cancellation fee for canceling after your helper(s) are in their way to you location or started service.
            </div>
        } else if (deleteType == 'pending') {
            return <div>Are you sure you to cancel this pending move?</div>
        } else if (deleteType == 'draft') {
            return <div>Are you sure you to delete this draft?</div>
        }
    }
    // delete-draft-by-id
    const confirmDeleteMove = async (e, option, deleteType = '') => {
        e.preventDefault();
        setdeleteMoveType(deleteType)
        alertConfirm({
            title: "",
            content: Component(option, deleteType),
            style: { position: "fixed" },
            className: "setconfirm",
            footer(dispatch) {
                return (
                    <>
                        <Button className="yesnobtn" onClick={() => dispatch('ok')}><span>Yes</span></Button>
                        <Button className="yesnobtn-no" onClick={() => dispatch('no')} styleType="primary">
                            <span>NO</span>
                        </Button>
                    </>
                );
            },
            async closeBefore(action, close) {
                if (action == 'no') {
                    close();
                } else {
                    setshowModal(true)
                    setrequestId(option.request_id)
                    close();
                }
            }
        });
    }

    const deleteMove = () => {
        if (reason != '') {
            setreasonError(false)
            if (deleteMoveType == 'pending') {
                setloading(true)
                api.postApi('customer-delete-move-by-id', { reason: reason, request_id: requestId }).then(response => {
                    setDataArray(dataArray.filter(item => item.request_id != requestId));
                    setloading(false)
                    toast.success(response.message);
                    setshowModal(false)
                }).catch(error => {
                    setloading(false)
                    setshowModal(false)
                    toast.error(error.message);

                });
            } else if (deleteMoveType == 'upcoming') {
                setloading(true)
                api.postApi('delete-upcoming-move', { request_id: requestId, reason: reason }).then(response => {
                    setDataArray(dataArray.filter(item => item.request_id != requestId));
                    setloading(false)
                    setshowModal(false)
                    toast.success(response.message);

                }).catch(error => {
                    setloading(false)
                    setshowModal(false)
                    toast.error(error.message);
                });
            }
        }
        else {
            setreasonError(true)
        }
    }

    const setReasonValue = (value) => {
        if (value == 'Other') {
            setReason('')
            setotherReason(true);

        } else {
            setotherReason(false)
            setReason(value)
        }
    }

    const deleteDraft = (id) => {
        setloading(true)
        api.postApi('delete-draft-by-id', { request_id: id }).then(response => {
            // setDataArray(dataArray.filter(item => item.request_id != requestId));
            setloading(false)
            // setshowModal(false)
            GetCustomerDraft();
            toast.success(response.message);
        }).catch(error => {
            setloading(false)
            setshowModal(false)
            toast.error(error.message);
        });
    }

    return (
        <>
            <MetaHelmet title="Move It | Moves" description="" />
            <Toaster position="top-right" reverseOrder={false} />
            <Header></Header>
            <main>

                <section className="coverphoto p-0"></section>

                <ProfileSection ></ProfileSection>


                <section className="work white p40">
                    <article className="container">

                        <div className="row">
                            <CustomerSideBar />

                            <div className="col-md-8 col-lg-9">
                                <div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
                                    <div className="col-6 col-md-6 col-lg-5">
                                        <h3>My Moves</h3>
                                    </div>

                                    <div className="col-6 col-md-6 col-lg-5">
                                        <Link to="/book-move" className="admoves"><i className="fas fa-box"></i> Book-Move</Link>
                                    </div>

                                    <div className="col-md-12 col-lg-10">
                                        <div className="contact-wrapper">

                                            <div id="tsum-tabs">

                                                <input id="tab4" type="radio" name="tabs" onChange={() => changeTab('tab4')} checked={activeTab == 'tab4'} disabled={loading} />
                                                <label htmlFor="tab4">Draft</label>

                                                <input id="tab1" type="radio" name="tabs" onChange={() => changeTab('tab1')} checked={activeTab == 'tab1'} disabled={loading} />
                                                <label htmlFor="tab1">Pending</label>

                                                <input id="tab2" type="radio" name="tabs" onChange={() => changeTab('tab2')} checked={activeTab == 'tab2'} disabled={loading} />
                                                <label htmlFor="tab2">Upcoming</label>

                                                <input id="tab3" type="radio" name="tabs" onChange={() => changeTab('tab3')} checked={activeTab == 'tab3'} disabled={loading} />
                                                <label htmlFor="tab3">Past</label>


                                                <section id="content1" className="mymovesbox">
                                                    <div className="notification-scroll" id="style-4">
                                                        <div className="row">
                                                            <div className="col-12 col-md-12">
                                                                {activeTab == 'tab1' && dataArray.length > 0 && dataArray.map((option, index) => (
                                                                    <div className="verticlebox" key={index} >
                                                                        <div className="leftimgmove">
                                                                            {option.items[0].item_photos.length > 0 ? <img src={option.items[0].item_photos[0]} alt="" /> : <img src="web/img/item-5.png" alt="" />}
                                                                        </div>
                                                                        <div className="content-mvebox">
                                                                            <h5>{option.item_name}</h5>
                                                                            <h6><i className="fa fa-calendar"></i> {option.pickup_date ? moment(option.pickup_date).format('ddd, MMM DD') : ''}</h6>
                                                                            <p><i className="fa fa-clock"></i> {option.pickup_start_time} - {option.pickup_end_time}</p>
                                                                            <p className="pnk-txt">Connecting with helper.</p>
                                                                        </div>
                                                                        <div className="btncontentmove">

                                                                            <Link to={'/move-detail/' + option.request_id} className="editbtn"  >View</Link>
                                                                            <Link to={'/book-move/' + option.request_id} className="editbtn"  >Edit</Link>
                                                                            <Link to="#" className="delete" onClick={(e) => confirmDeleteMove(e, option, 'pending')} >Cancel</Link>

                                                                        </div>
                                                                    </div>
                                                                ))}

                                                                {dataArray.length == 0 && loading == false ? <p className="nomoveimg"><img src={'web/img/no_moves_placeholder.png'} alt="place" /> <br /> No Pending Moves Yet!</p> : ''}
                                                            </div>	 </div></div>
                                                </section>

                                                <section id="content2" className="mymovesbox">
                                                    <div className="notification-scroll" id="style-4">
                                                        <div className="row">
                                                            <div className="col-12 col-md-12">
                                                                {activeTab == 'tab2' && dataArray.length > 0 && dataArray.map((option, index) => (
                                                                    <div className="verticlebox" key={index} >
                                                                        <div className="leftimgmove">
                                                                            {option.items[0].item_photos.length > 0 ? <img src={option.items[0].item_photos[0]} alt="" /> : <img src="web/img/item-5.png" alt="" />}
                                                                        </div>
                                                                        <div className="content-mvebox">
                                                                            <h5>{option.item_name}</h5>
                                                                            <h6><i className="fa fa-calendar"></i> {option.pickup_date ? moment(option.pickup_date).format('ddd, MMM DD') : ''}</h6>
                                                                            <p><i className="fa fa-clock"></i> {option.pickup_start_time} - {option.pickup_end_time}</p>
                                                                            {option.is_reconfirm == 1 ? <p style={{ color: "red" }}> {option.reconfirm_message}</p> :
                                                                                <p>Scheduled time {option.meeting_slot}</p>}

                                                                            {option.helper_edited == 1 ? <p style={{ color: "red" }}> Your job has been edited by the helper, to finish this move effectively, you must <Link to={'/'} style={{ textDecoration: "revert" }} >install</Link> the application and login using same User ID.</p> :
                                                                                ''}
                                                                        </div>
                                                                        <div className="btncontentmove">

                                                                            <Link to={'/move-detail/' + option.request_id} className="editbtn"  >View</Link>
                                                                            {option.job_on_the_way != true && option.job_started != true ?
                                                                                <Link className="editbtn" to={'/book-move/' + option.request_id}   >Edit</Link>
                                                                                : ''}

                                                                            <Link to="#" className="delete" onClick={(e) => confirmDeleteMove(e, option, 'upcoming')}>Cancel</Link>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                {dataArray.length == 0 && loading == false ?
                                                                    <p className="nomoveimg"><img src={'web/img/no_moves_placeholder.png'} alt="" /> <br /> No Upcoming Moves Yet!</p> : ''}
                                                            </div>	 </div> </div>
                                                </section>

                                                <section id="content3" className="mymovesbox">
                                                    <div className="notification-scroll" id="style-4">
                                                        <div className="row">
                                                            <div className="col-12 col-md-12">
                                                                {activeTab == 'tab3' && dataArray.length > 0 && dataArray.map((option, index) => (
                                                                    <div className="verticlebox" key={index} onClick={() => navigate('/move-detail/' + option.request_id)}>
                                                                        <div className="leftimgmove">
                                                                            {option.items[0].item_photos.length > 0 ? <img src={option.items[0].item_photos[0]} alt="" /> : <img src="web/img/item-5.png" alt="" />}
                                                                        </div>
                                                                        <div className="content-mvebox">
                                                                            <h5>{option.item_name}</h5>
                                                                            <h6><i className="fa fa-calendar"></i> {option.pickup_date ? moment(option.pickup_date).format('ddd, MMM DD') : ''}</h6>
                                                                            <p><i className="fa fa-clock"></i> {option.pickup_start_time} - {option.pickup_end_time}</p>
                                                                        </div>
                                                                        <div className="btncontentmove">
                                                                            {option.is_deleted ? <Link to="#" className="delete-caclled">Canceled</Link> : ''}
                                                                            {option.job_status ? <Link to="#" style={{ color: "#25b420" }}>Completed</Link> : ''}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                {dataArray.length == 0 && loading == false ?
                                                                    <p className="nomoveimg"><img src={'web/img/no_moves_placeholder.png'} alt="" /> <br /> No Past Moves Yet!
                                                                    </p> : ''}
                                                            </div> </div> </div>
                                                </section>

                                                <section id="content4" className="mymovesbox">
                                                    <div className="notification-scroll" id="style-4">
                                                        <div className="row">
                                                            <div className="col-12 col-md-12">
                                                                {activeTab == 'tab4' && dataArray.length > 0 && dataArray.map((option, index) => (
                                                                    <div className="verticlebox" key={index} >
                                                                        <div className="leftimgmove">
                                                                            {option.items.length > 0 ?
                                                                                <img src={option.items[0].item_photos[0]} alt="" style={{ cursor: 'pointer' }} onClick={() => navigate('/book-moves/draft/' + option.request_id)} />
                                                                                :
                                                                                <img src="web/img/move_placeholder.png" alt="" style={{ cursor: 'pointer' }} onClick={() => navigate('/book-moves/draft/' + option.request_id)} />
                                                                            }
                                                                        </div>
                                                                        <div className="content-mvebox">
                                                                            {option.items.length > 0 ?
                                                                                <h5 style={{ cursor: 'pointer' }} onClick={() => navigate('/book-moves/draft/' + option.request_id)}>{option.items[0].item_name}</h5>
                                                                                :
                                                                                <h5><br /></h5>
                                                                            }
                                                                            <h6><i className="fa fa-calendar"></i> {option.pickup_date ? moment(option.pickup_date).format('ddd, MMM DD') : ''}</h6>
                                                                            <p><i className="fa fa-clock"></i> {option.pickup_start_time} - {option.pickup_end_time}</p>
                                                                        </div>
                                                                        <div className="btncontentmove">
                                                                            <Link to={'/book-moves/draft/' + option.request_id} className="editbtn"  >Edit</Link>
                                                                            <Link to="#" className="delete" onClick={() => deleteDraft(option.request_id)} >Delete</Link>

                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                {dataArray.length === 0 && loading == false ? <p className="nomoveimg"><img src={'web/img/no_moves_placeholder.png'} alt="place" /> <br /> No Drafts Yet!</p> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>

                                            </div>

                                        </div>

                                        {pagesCount > 0 && dataArray.length > 0 ?
                                            <>
                                                <div className="pagination-wrapper">
                                                    <PaginationComponent className="pagination_custom"
                                                        totalItems={pagesCount}
                                                        pageSize={20}
                                                        maxPaginationNumbers={3}
                                                        onSelect={(e) => getRecord(e)}
                                                    />
                                                </div>
                                            </>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </article>
                </section>

                <div className={showModal ? "modal display-block" : "modal display-none"} id="otp-model" tabIndex="-1" role="dialog" aria-labelledby="def" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel2">Select Reason</h4>
                                <button type="button" className="close" onClick={hideModal} ><span aria-hidden="true" onClick={hideModal}>&times;</span></button>
                            </div>
                            <div className="modal-body add-addressw0form">
                                <form className="job-application-form login-page vechicleinfo row">

                                    <div className="form-row col-md-12 col-lg-12">
                                        <select className="form-control" name="reason" onChange={(e) => setReasonValue(e.target.value)}>
                                            <option value="">Select Reason</option>
                                            <option value="Helpers did not accpet job within customer timeframe">Helpers did not accpet job within customer timeframe</option>
                                            <option value="Service is no longer needed">Service is no longer needed</option>
                                            <option value="Other">Other</option>
                                        </select>

                                    </div>
                                    {otherReason ?
                                        <>
                                            <div className="form-row col-md-12 col-lg-12">
                                                <input type="text" className="pl-15" placeholder="Type Reason" onChange={(e) => setReason(e.target.value)} />
                                            </div>
                                        </>
                                        : ''}

                                    <div className="form-row col-md-12 col-lg-12 text-center text-danger">
                                        {reasonError == true && reason == '' && otherReason == false ?
                                            <>
                                                <p>Please select reason</p>
                                            </>
                                            :
                                            reasonError == true && reason == '' && otherReason == true ?
                                                <>
                                                    <p>Please enter reason</p>
                                                </>
                                                : ''}
                                    </div>
                                    <div className="form-row">
                                        <button className="full" type="button" onClick={deleteMove} ><span>Submit</span></button>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>

            </main>

            <Footer loading={loading}></Footer>
        </>
    );
}
export default MyMoves;

