import { Link } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import CustomerSideBar from './../layout/CustomerSideBar';
import Service from './../../service';
import toast, { Toaster } from 'react-hot-toast';
import React, { } from "react";
import MetaHelmet from './../metaHelmet'; //Include metaHelmet
import ProfileSection from './profileSection';
import moment from 'moment-timezone';
import PaginationComponent from '../../PaginationComponent';
// import PaginationComponent from "react-reactstrap-pagination";
import "bootstrap/dist/css/bootstrap.min.css";
const api = new Service();
class Announcement extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			notification_type: "OFFER",
			tabId: "tab1",
			page_no: 1,
			timezone: moment.tz.guess(),
			pagesCount: 0,
			dataArray: [],
			next_page: true,
			loading: false,
		}
	}

	componentDidMount() {
		this.getNotificationList(this.state.page_no, this.state.tabId, this.state.notification_type);
	}

	getNotificationList(page_no, tabId, notification_type) {
		this.setState({ page_no: page_no })
		this.setState({ tabId: tabId })
		this.setState({ notification_type: notification_type })
		this.setState({ loading: true })
		api.postApi('get-customer-notification-list', { page_no: page_no, timezone: this.state.timezone, notification_type: notification_type }).then(response => {
			if (response && response.data.length > 0) {
				this.setState({ next_page: response.next_page });
				this.setState({ pagesCount: response.totalItemsCount });
				this.setState({ dataArray: response.data });
			}
			this.setState({ loading: false })
		}).catch(error => {
			toast.error(error.message);
		});
	}

	changeTab(tabId, notification_type) {
		this.setState({ page_no: 1 });
		this.setState({ tabId: tabId });
		this.setState({ notification_type: notification_type });
		this.setState({ dataArray: [] });
		this.getNotificationList(1, tabId, notification_type);
	}

	getRecord(page) {
		if (page != this.state.page_no) {
			this.getNotificationList(page, this.state.tabId, this.state.notification_type);
		}
	}

	getTime(created_datetime) {
		var startTime = new Date(created_datetime);
		return moment(startTime).fromNow()
	}

	render() {
		return (
			<>
				<MetaHelmet title="Move It | Announcement" description="" />
				<Toaster position="top-right" reverseOrder={false} />
				<Header></Header>
				<main>
					<section className="coverphoto p-0"></section>

					<ProfileSection ></ProfileSection>
					<section className="work white p40">
						<article className="container">

							<div className="row">
								<CustomerSideBar />
								<div className="col-md-8 col-lg-9">
									<div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
										<div className="col-12 col-md-12 col-lg-10">
											<h3>Announcement</h3>
										</div>
										<div className="col-md-12 col-lg-10">
											<div className="contact-wrapper">

												<div id="tsum-tabs">
													<input id="tab1" type="radio" name="tabs" checked={this.state.tabId == 'tab1'} onClick={() => this.changeTab('tab1', 'OFFER')} readOnly />
													<label htmlFor="tab1">Offers</label>

													<input id="tab2" type="radio" name="tabs" checked={this.state.tabId == 'tab2'} onClick={() => this.changeTab('tab2', 'UPDATE')} readOnly />
													<label htmlFor="tab2">Updates</label>


													<section id="content1" className="mymovesbox">
														<div className="row">
															<div className="col-md-12">
																{this.state.dataArray.length > 0 && this.state.dataArray.map((option, index) => (
																	<div className="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header" key={index} >
																		<div className="font-weight-bold mr-3 notification-hrf">
																			<Link to=""><div className="">{option.notification_title}</div>
																				<div className="small">{option.notification_text}</div></Link>
																		</div>
																		<span className="ml-auto mb-auto">
																			<div className="text-right text-muted pt-1">{option.created_at}</div>
																		</span>
																	</div>
																))}
															</div>


															{this.state.dataArray.length == 0 && this.state.loading == false ?
																<div className="col-md-12 mt-3">
																	<img src="web/img/no_notification_placeholder.png" className="noimg-img" alt="" />
																	<h5 className="text-center">No Notifications Yet!</h5>
																</div>
																: ''}
														</div>
													</section>

													<section id="content2" className="mymovesbox">
														<div className="row">
															<div className="col-sm-12">
																{this.state.dataArray.length > 0 && this.state.dataArray.map((option, index) => (
																	<div className="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header" key={index}>
																		<div className="font-weight-bold mr-3 notification-hrf">
																			<Link to=""><div className="">{option.notification_title}</div>
																				<div className="small">{option.notification_text}</div></Link>
																		</div>
																		<span className="ml-auto mb-auto">
																			<div className="text-right text-muted pt-1">{option.created_at}</div>
																		</span>
																	</div>
																))}
															</div>
															{this.state.dataArray.length == 0 && this.state.loading == false ?
																<div className="col-md-12 mt-3">
																	<img src="web/img/no_notification_placeholder.png" className="noimg-img" alt="" />
																	<h5 className="text-center">No Notifications Yet!</h5>
																</div>
																: ''}
														</div>
													</section>
												</div>
											</div>
											{this.state.dataArray.length > 0 ?
												<div className="pagination-wrapper">

													<PaginationComponent className="pagination_custom"
														totalItems={this.state.pagesCount}
														pageSize={20}
														maxPaginationNumbers={3}
														onSelect={(e) => this.getRecord(e)}
													/>
												</div>
												: ''}

										</div>
									</div>
								</div>
							</div>


						</article>
					</section>
				</main>
				<Footer loading={this.state.loading}></Footer>
			</>
		);
	}
}
export default Announcement;