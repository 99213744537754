import React, {useState} from 'react';

import { css } from "@emotion/react";
import Loader from "react-spinners/HashLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: '#f0bac2'; 
`;

const Loading = (props) => {
    // const [loading, setLoading] = useState(props.loading);
    const [color] = useState('#f0bac2');
  return (
     <div className="sweet-loading custom-loader"> 
      <Loader color={color} loading={props.loading} css={override} size={150}  className="custom-loader-span" />
    </div>
  )
}; 

export default Loading;