
import Header from './layout/HeaderMoveitLicense';
import Footer from './layout/FooterMoveitLicense';
import Service from './../service';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import toast, { Toaster } from 'react-hot-toast';
import MetaHelmet from './metaHelmet'; //Include metaHelmet
import { useNavigate } from 'react-router-dom';
import Modal from './modal/modal';

const api = new Service();
function RequestMoreInfo(props) {

    const [config, setconfig] = useState(props.config);


    const [serviceChargeModal, setserviceChargeModal] = useState(false);

    const hideServiceChargeModal = () => {
        setserviceChargeModal(false);
    };

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const Formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            referredBy: '',
            investmentRange: '',
            selectTimetable: '',
            need_financing: '',
            text: '',
            can_email: ''
        },
        validationSchema: yup.object({
            firstName: yup.string().required('Please enter your first name'),
            lastName: yup.string().required('Please enter your last name'),
            email: yup.string().email('Invalid email address').required('Please enter your email address'),
            phone: yup.string().required('Please enter your phone number'),
            referredBy: yup.string().required('Please enter who referred you'),
            investmentRange: yup.string().required('Please select your investment range'),
            selectTimetable: yup.string().required('Please select a timetable'),
            text: yup.string().required('Please indicate if you need financing'),
            can_email: yup.string().required('Please select an option')
        }),

        onSubmit: values => {
            setLoading(true);
            console.log(values)
            // Uncomment below when ready to integrate API
            api.postApi('faq-add', values).then(response => {
                if (response) {
                    toast.success('Thank you for contacting us. We will respond ASAP.');
                    navigate('/thankyou');
                }
                Formik.resetForm();
            }).catch(error => {
                toast.error(error.message);
            }).finally(() => {
                setLoading(false);
            });
        }
    });

    useEffect(() => {
        document.title = "Request More Information - Move It";
        window.gtag('config', config.G_TAG, {
            page_title: document.title,
            page_path: window.location.pathname,
        });
    }, []);

    return (
        <>
            <MetaHelmet title="Request More Information - Move It" description="If you need help with anything or have questions / concerns please don't hesitate to ask. You can send us a message and also send an email." />
            <Toaster position="top-right" reverseOrder={false} />
            <Header></Header>
            <main>
                <section className="clean bg-pod" style={{ padding: "50px 0", background: "rgb(231 130 147)" }}>
                    <article>
                        <div className="grid" style={{ textAlign: "center" }}>
                            <div className="unit-2-3 unit-1-1-md">
                                <h1 className="h2 f-46 text-center">Request More Information</h1>
                            </div>
                        </div>
                    </article>
                </section>

                <div className="flex flex-col items-center p-2 border rounded-md shadow-lg animate-fade-up" style={{ background: "#dae4e6" }}>
                    <div className="flex flex-col md:flex-row items-center">
                        <div className="mb-4 md:mb-0 md:mr-4" style={{ textAlign: "center" }}>
                            <img src="https://static.mywebsites360.com/c1606f9634984c97a37982171abd6740/i/a8a77009bf3c4666848ba61465a54fb6/1/GCuCv726gZycFxatRCb7iU/2.png?dpr=2" alt="icon of calendar" title="icon of calendar" className="w-full md:w-48 rounded" />
                        </div>
                        <div className="text-center md:text-left">
                            <h2 className="text-2xl font-semibold mb-2">Schedule Online</h2>
                            <p className="mb-2">Submit the form below to request more information.</p>
                            <p className="mb-4">Or, schedule a meeting with us.</p>
                            {/* <a href="https://calendly.com/nicolekingkathyirelandlicensing/business-licensing-opportunity" target="_blank" rel="noopener noreferrer" className="inline-block px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition">Schedule Via Calendly</a> */}
                        </div>
                    </div>
                </div>

                <section className="slate" style={{ padding: "20px 0px" }}>
                    <article>
                        <div className="form-container" style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
                            <form onSubmit={Formik.handleSubmit} style={{ background: "white", padding: "20px", maxWidth: "500px", width: "100%", borderRadius: "8px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                                <div className="form-group">
                                    <label htmlFor="firstName" style={{ display: "flex", alignItems: "center", margin: " 0px" }}>First Name <span className="required m-2" style={{ color: "red" }}>*</span></label>
                                    <input type="text" {...Formik.getFieldProps("firstName")} name="firstName" className="form-control" style={{ width: "100%" }} />
                                    {Formik.touched.firstName && Formik.errors.firstName ? <span className="text-danger">{Formik.errors.firstName}</span> : null}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="lastName" style={{ display: "flex", alignItems: "center", margin: " 0px" }}>Last Name <span className="required m-2" style={{ color: "red" }}>*</span></label>
                                    <input type="text" id="lastName" {...Formik.getFieldProps("lastName")} name="lastName" required className="form-control" style={{ width: "100%" }} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="email" style={{ display: "flex", alignItems: "center", margin: " 0px" }}>Email <span className="required m-2" style={{ color: "red" }}>*</span></label>
                                    <input type="email" id="email" {...Formik.getFieldProps("email")} name="email" required className="form-control" style={{ width: "100%" }} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="phone" style={{ display: "flex", alignItems: "center", margin: " 0px" }}>Phone <span className="required m-2" style={{ color: "red" }}>*</span></label>
                                    <input type="tel" id="phone" name="phone" {...Formik.getFieldProps("phone")} required className="form-control" style={{ width: "100%" }} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="referredBy" style={{ display: "flex", alignItems: "center", margin: " 0px" }}>Referred By <span className="required m-2" style={{ color: "red" }}>*</span></label>
                                    <input type="text" id="referredBy" name="referredBy" {...Formik.getFieldProps("referredBy")} required className="form-control" style={{ width: "100%" }} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="investmentRange" style={{ display: "flex", alignItems: "center", margin: " 0px" }}>Investment Range <span className="required m-2" style={{ color: "red" }}>*</span></label>
                                    <select id="investmentRange" name="investmentRange" {...Formik.getFieldProps("investmentRange")} className="form-control" style={{ width: "100%" }}>
                                        <option value="" disabled hidden>Please select</option>
                                        <option value="$19k-$39k">$19k-$39k</option>
                                        <option value="$39k-$99k">$39k-$99k</option>
                                        <option value="$100k-$250k">$100k-$250k</option>
                                        <option value="$250k+ or Above">$250k+ or Above</option>

                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="selectTimetable" style={{ display: "flex", alignItems: "center", margin: " 0px" }}>Select Timetable <span className="required m-2" style={{ color: "red" }}>*</span></label>
                                    <select id="selectTimetable" name="selectTimetable" {...Formik.getFieldProps("selectTimetable")} className="form-control" style={{ width: "100%" }}>
                                        <option value="" disabled hidden>Please select </option>
                                        <option value="1 - 3 months">1 - 3 months</option>
                                        <option value="3 - 6 months">3 - 6 months</option>
                                        <option value="6 - 12 months">6 - 12 months</option>
                                        <option value="12+ months">12+ months</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="need_financing" style={{ display: "flex", alignItems: "center", margin: " 0px" }}>Need Financing? <span className="required m-2" style={{ color: "red" }}>*</span></label>
                                    <select id="need_financing" name="need_financing" {...Formik.getFieldProps("need_financing")} required className="form-control" style={{ width: "100%" }}>
                                        <option value="" disabled hidden>Please select </option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="text" style={{ display: "flex", alignItems: "center", margin: " 0px" }}>Anything else you want to share? <span className="required m-2" style={{ color: "red" }}>*</span></label>
                                    <textarea id="text" name="text" required {...Formik.getFieldProps("text")} className="form-control" style={{ width: "100%" }} ></textarea>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="can_email" style={{ display: "flex", alignItems: "center", margin: " 0px" }}>May we email you about this? <span className="required m-2" style={{ color: "red" }}>*</span></label>
                                    <select id="can_email" name="can_email" {...Formik.getFieldProps("can_email")} className="form-control" style={{ width: "100%" }}>
                                        <option value="" disabled hidden>Please select </option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>

                                <style>
                                    {`
                                    button:after,
                                    #IDX-main #IDX-action-buttons #IDX-submitBtn:after,
                                    #IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):after,
                                    #IDX-main #IDX-action-buttons .IDX-btn-primary:after,
                                    #IDX-main .IDX-form-actions #IDX-submitBtn:after,
                                    #IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):after,
                                    #IDX-main .IDX-form-actions .IDX-btn-primary:after,
                                    #IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn:after,
                                    #IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):after,
                                    #IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary:after,
                                    #IDX-registrationSignup .IDX-form-actions #IDX-submitBtn:after,
                                    #IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):after,
                                    #IDX-registrationSignup .IDX-form-actions .IDX-btn-primary:after,
                                    .button:after,
                                    .idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:after {
                                        content: none !important;
                                        display: none !important;
                                        background: none !important;
                                        clip-path: none !important;
                                    }
                                    `}
                                </style>

                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    style={{
                                        width: "100%",
                                        background: "#ff95a2",
                                        color: "white",
                                        padding: "12px 0",
                                        border: "none",
                                        borderRadius: "30px", // Make it fully rounded on the sides
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        textAlign: "center"
                                    }}
                                >
                                    REQUEST INFORMATION
                                </button>

                            </form>
                        </div>

                    </article>
                </section >

            </main >
            {/* <Modal modal="ContactUsThankYou" show={serviceChargeModal} handleClose={hideServiceChargeModal}></Modal> */}

            < Footer loading={loading} ></Footer >
        </>
    );
}

export default RequestMoreInfo;
