
import Header from './layout/Header'; 
import Footer from './layout/Footer'; 
import Service from './../service';
import {useFormik} from 'formik';
import * as yup from 'yup';
import React, {useState,useEffect} from "react";
import { useParams } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import toast, { Toaster } from 'react-hot-toast';
import MetaHelmet from './metaHelmet'; //Include metaHelmet
import alertConfirm, { Button } from "react-alert-confirm";
import { useNavigate } from 'react-router-dom';


const api = new Service();
const DeleteAccountConfirmation = (props) => {

    const { token } = useParams();
	const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [mssageDeleteAccount, setMessageDeleteAccount] = useState(false);
   
    useEffect(() => {
		GetDeleteAccountRequest()
	}, [token])

    const GetDeleteAccountRequest = () => {
        setLoading(true)
		api.getApi('delete-account-request/'+token).then(response => {
			if (response && response.success) {
                setLoading(false)
			} else {
				navigate('/404')
			}
		}).catch(error => {
			toast.error(error.message);
		});
	}
    
    return (
        <>
        {loading==false? 

           <>
            <MetaHelmet title="Delete Account Confirmation - Move It" description="If you need help with anything or have questions / concerns please don't hesitate to ask. You can send us a message and also send an email." />
            <Toaster position="top-right" reverseOrder={false} />
            <Header></Header>
                <main>
                    <section className="hero clean bg-pod conus-img" style={{backgroundImage: `url("web/img/c-us.jpg")`}}>
                        <article>
                            <div className="grid">
                                <div className="unit-2-3 unit-1-1-md">
                                    <h1 className="h2 f-46">Your account has been successfully deleted.</h1>
                                </div>
                            </div>
                        </article>
                    </section>
        
                
                </main>
                
            <Footer loading={loading}></Footer>
            </> 
        :''}
        </> 
    ); 
}

export default DeleteAccountConfirmation;
