import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import CustomerSideBar from './../layout/CustomerSideBar';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet
import Service from './../../service'; 
import toast, { Toaster } from 'react-hot-toast';
import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useFormik} from 'formik';

import * as yup from 'yup';
import ProfileSection from './profileSection';

const api = new Service();
const ReportProblem=()=>{
    const [loading, setLoading] = useState(false);
    const formik=useFormik({
        initialValues:{
          message:''
        },
        validationSchema: yup.object({
            message: yup.string().required('Please enter your message')
        }),
        onSubmit:values=>{
            setLoading(true)
            api.postApi('customer-report',values).then(response => {
                toast.success(response.message);
                formik.resetForm()
                setLoading(false)
            }).catch(error => {
                setLoading(false)
                toast.error(error.message);
            });
        }
    });
 
    return (
        <>
        <MetaHelmet title="Move It | Report" description="" />
        <Toaster position="top-right" reverseOrder={false} />
        <Header></Header>
        <main>
         
        
		
	<section className="coverphoto p-0"></section>
		
		<ProfileSection ></ProfileSection>
		
		
					  
		<section className="work white p40">
            <article className="container">	  
			<form className="login-page " onSubmit={formik.handleSubmit} >
   
			 <div className="row">
			      <CustomerSideBar />
				 
				 
				
				<div className="col-md-8 col-lg-9">
					<div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
						<div className="col-12 col-md-12 col-lg-10">
						  <h3 className="text-left">Report a problem</h3>
						</div>
						
						
						
						<div className="col-md-12 col-lg-10">  
						   <div className="contact-wrapper"> 
						   <div className="form-group check-group">
								<p className="pstep-from">We opologize for the inconvenience . Please describe your issue and send a report to help us to analyze the cause of the problem. </p>
							</div>
							<div className="repotrpbolem">
								<div className="innereport">
								<div className="form-group check-group">
									<textarea className="form-control" id="inputText" name="message" {...formik.getFieldProps("message")} ></textarea>
									<label htmlFor="inputText">Describe your problem</label>
								</div>
								{formik.touched.message && formik.errors.message ? <span  className="text-danger">{formik.errors.message}</span> : null}
								<div className="form-row">
									  <button className="full" type="submit"><span>SUBMIT</span></button>
								</div>
                                </div>								
							 </div> 
						   </div>
						 </div>
					  </div>
					  
					 

				</div>			
				 
				 
			   
			   </div>
			   </form>

			 </article>
         </section>
          
		
		
		
         
      </main>
     
        <Footer loading={loading}></Footer>
        </> 
    ); 
}
export default ReportProblem;

