import React, {useState, useEffect} from "react";
import { Link,useNavigate } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import MetaHelmet from './../metaHelmet'; //Include metaHelmet
import Service from './../../service'; 
import toast, { } from 'react-hot-toast';

import {useFormik} from 'formik';
import * as yup from 'yup';

import { requestForToken } from './../firebaseNotifications/firebase';

const api = new Service();
const Login=(props)=>{
    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
     const [message, setMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    useEffect(() => {
       getFCMToken();
    }, [])
    
    const getFCMToken = () => {
            console.log('currentToken')
        requestForToken().then((currentToken) => {
                console.log(currentToken)
              formik.setFieldValue('device_token', currentToken);
        });
    }
    
     useEffect(()=>{
        if(errorMessage) {
            setTimeout(function() {
                setErrorMessage(false)
            }, 3000);
        }
    }, [errorMessage])
    
    const formik=useFormik({
        initialValues:{
          email_id:'',
          password:'',
          device_token :''
    
        }, 
        validationSchema: yup.object({
            password: yup.string().required('Please enter your password'),
            email_id: yup.string().email('Invalid email address').required('Please enter your email address')
        }),
        onSubmit:values=>{
            setloading(true)
            api.postApiHelper('helper-sign-in',values).then(response => {
               
                localStorage.setItem("user_token",response.web_auth_key);
                localStorage.setItem("user_type",'helper');
                localStorage.setItem("user_detail",response);
                if(response.is_verified == 0){
                    navigate('/helper-pending')  
                } else {
                    toast.success('Login successfully');
                    navigate('/helper-moves')
                }
                  formik.resetForm()
            }).catch(error => {
                setloading(false)
                //  seterror(false)
                setErrorMessage(true)
                setMessage(error.message)
            });
        }
    });
    const [passwordType,setPasswordType] = useState("password");
    const [passwordInput,setPasswordInput] = useState("");
    const togglePassword =()=>{
      if(passwordType=="password"){
        setPasswordType("text")
        return;
      }
      setPasswordType("password")
    }
    return (
        <>
         <MetaHelmet title="Move It | Helper Login" description="" /> 
            <Header/>
            <main>
                <section className="work white helopadd-login">
                    <article className="container">
                        <div className="row align-items-center d-flex align-center" data-aos="fade-left" data-aos-delay="100">
                            <div className="col-md-6">
                                <div className="contact-wrapper lpage helplogin">
                                    <div className="title">
                                        <h3 className="text-center">
                                            Move It Pro & Muscle <br /> Log In 
                                        </h3>
                                    </div>
                                   {errorMessage && message?
                                   <p className="text-danger text-center" style={{textAlign: "initial", color : "red"}}>{message}</p>
                                   : '' }
                                    <form className="login-page"  onSubmit={formik.handleSubmit} autoComplete="off" >
                                        <div className="form-row">
                                            <i className="fa fa-envelope"></i>
                                            <input type="text"   name="email_id"  autoComplete="off" {...formik.getFieldProps("email_id")} />
                                            <span>Email</span>
                                        </div>
                                        {formik.touched.email_id && formik.errors.email_id ? <span  className="text-danger">{formik.errors.email_id}</span> : null}
                                        <div className="form-row">
                                            <i className="fa fa-lock"></i>
                                            <input id="password-field" name="password" type={passwordType} autoComplete="new-password"  {...formik.getFieldProps("password")} />
                                            <span>Password</span>
                                            <div toggle="#password-field" className={(passwordType=="password"? 'fa fa-fw fa-eye-slash field-icon toggle-password ' : 'fa fa-fw field-icon toggle-password fa-eye')} onClick={togglePassword}></div>
                                        </div>
                                        {formik.touched.password && formik.errors.password ? <span  className="text-danger">{formik.errors.password}</span> : null}
                                        <div className="form-row"></div>
                                        <div className="forgot-password"><Link to="/helper-forgotpassword"> Forgot Password?</Link></div>
                                        <div className="form-row">
                                            <button className="full" type="submit"><span>LOG IN</span></button>
                                        </div>
                                    </form>
                                    <div className="socials-wrapper">
                                        <h6 className="text-center dont-acct">Don't have an account yet? <Link to="/helper-service-type">Signup</Link></h6>
                                    </div>
                                </div>
                            </div>
            
                            <div className="col-md-6">
                                <img src="web/img/06.jpg" alt="moveit" className="login-right-img" />
                            </div>
                        </div>
                    </article>
                </section>
            </main>
            <Footer loading={loading} />
        </>
    )
}
export default Login;