import { Link } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Service from './../service';
import toast, { } from 'react-hot-toast';
import React, { useState, useEffect } from "react";
import moment from 'moment-timezone';
import MetaHelmet from './metaHelmet'; //Include metaHelmet

const api = new Service();

function Pages(props) {

   const [PageContent, setdata] = useState({});

   const [config, setconfig] = useState(props.config);


   useEffect(() => {
      GetPageDetails();
   }, [])

   const GetPageDetails = () => {
      api.getApi('get-news-pages', { page_name: 'news', timezone: moment.tz.guess() }).then(response => {
         setdata(response);
         console.log(response);
      }).catch(error => {
         toast.error(error.message);
      });
   }




   useEffect(() => {
      document.title = "Move It - News";
      window.gtag('config', config.G_TAG, {
         page_title: document.title,
         page_path: window.location.pathname,
      });
   }, []);

   return (
      <>
         <MetaHelmet title="Move It - News" description="Move It is becoming one of the fastest-growing technology platforms. Connect with our news section to get the latest information we post every week." />
         <Header></Header>
         {PageContent && PageContent.blog_img ?
            <section className="hero clean" style={{ backgroundImage: "url(" + PageContent.blog_img + ")" }} >
               <article>
                  <div className="grid">
                     <div className="unit-2-3 unit-1-1-md">
                        <h1 className="h2 blog_sub_title f-46">{PageContent.blog_title}</h1>

                     </div>
                  </div>
               </article>
            </section>
            : ''}


         {PageContent && PageContent.content_title && PageContent.content_title.length > 0 && PageContent.content_title.map((option, index) => (
            <section key={index}>
               {option.page_content.length > 0 ?
                  <>
                     <article>
                        <div className="grid grid-center margin-bottom-lg text-left">
                           <div className="unit-2-3 unit-1-1-md blog-t">
                              <h2 className="flourish text-left"><span className="flourish-square"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(232,154,167)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span>{option.title}</h2>
                           </div>
                        </div>

                        <div className="row">
                           <div className="col-lg-12">
                              <div className="row align-items-center d-flex align-center justify-content-center">

                                 {option.page_content && option.page_content.length > 0 && option.page_content.map((option_1, index_1) => (

                                    <div className={option.page_content.length == 1 || (option.page_content.length == 4 && index_1 == 3) ? "col-md-12 col-lg-9" : option.page_content.length == 2 ? "col-md-6 col-lg-5" : "col-md-6 col-lg-4"} key={index_1}>
                                       <div className="blogbox">
                                          <Link to={'/' + option_1.page_title_alias}>
                                             <div className="ovlay"></div>
                                             <img src={option_1.content_photo} alt="" />
                                             <div className="contentbox-blog">
                                                <h3>{option_1.content_title} </h3>
                                                <div className="profileimg">
                                                   <span><small>{option_1?.created_datetime ? option_1.created_datetime : ''}</small></span>
                                                </div>
                                                {/* <div className="profileimg">
                                         <img src="web/img/blog-user.png" alt=""/>
                                         <span>John smith <br/> <small>05 May, 2022</small></span>
                                       </div>*/}
                                             </div>
                                          </Link>
                                       </div>
                                    </div>
                                 ))}
                              </div>
                           </div>
                        </div>


                     </article>
                  </>
                  : ''}
            </section>
         ))}



         <Footer></Footer>
      </>
   );
}

export default Pages;
