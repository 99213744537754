import React from 'react';
import {Link} from 'react-router-dom';

import Header from './layout/Header';
import Footer from './layout/Footer';

const ThankYou = () => {
    return (
        <>
            <Header />

            <main>
            

                <section className="thankYouSection">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                         
                         <div className="col-md-6">
                                   <img src="/web/img/smileemail.svg" alt="" className="imgIconThankyou"/>
									<h2 style={{textAlign:"center", marginTop:"30px", fontWeight:"800",fontSize:"60px",}}>THANK YOU!</h2>
									<p className="thanksTxtPraNew ">for contacting us, we will reply promptly once your message is received.</p>
                                    <p style={{textAlign:"center", marginTop:"0px", fontWeight:"500",color:"#242424",}}>Find out why Move It is becoming the go-to app for the convenience of safe store pick up and delivery, moving your belongings from one place to another, junk removal, or just extra muscle.
                                    <b>Truck & Muscle Anytime You Need It ™</b></p>

                                    <p style={{textAlign:"center", marginTop:"0px", fontWeight:"500",}}>Move It will provide you the peace of mind and convenience of safe store pick up and delivery, moving your belongings from one place to another, or just extra Muscle. 
                                    <b>Truck & Muscle Anytime You Need It ™</b>
                                    </p> 

                                    <Link className="backtoHome" to="/"> <span><i class="fa-solid fa-angles-left"></i> Back to home </span></Link>
                            </div>
                            <div class="col-md-6"> 
					           <img src="/web/img/06.jpg" alt="moveit" class="login-right-img"/>
					        </div>


                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default ThankYou;
