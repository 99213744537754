import React, {useState,useEffect} from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import {Link } from 'react-router-dom';


const SetupForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
   useEffect(() => {
     props.properties.setloading(false)
   },[])

    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(()=>{
        if(errorMessage) {
            setTimeout(function() {
                setErrorMessage(false)
            }, 3000);
        }
    }, [errorMessage])

  const handleSubmit = async (event) => {
      props.properties.setloading(true)
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    // console.log(elements.getElement(CardElement))
    const {radarSession, error} = await stripe.createRadarSession();
    if (error) {
      // Typically you should not abort your checkout flow if an error is returned.
      console.error('error',error);
    } else {
      // console.log('radarSession',radarSession)
    
      const result = await stripe.confirmSetupIntent(
          props.properties.data.payment_method_nonce,
          elements.getElement(CardElement)
      ); 
    
      if (result.error) {
          props.properties.setloading(false)
        if(result.error && result.error.code == 'setup_intent_unexpected_state') {
      //         // props.getPaymentResponse(result)
              props.properties.prev(props.data);  
        } else {
          setErrorMessage(true);
        }
      } else {
          props.getPaymentResponse(result,radarSession.id)
      //   // Your customer will be redirected to your `return_url`. For some payment
      //   // methods like iDEAL, your customer will be redirected to an intermediate
      //   // site first to authorize the payment, then redirected to the `return_url`.
      }

    }
    
  };
  var num2 = parseFloat(props.properties.finalPrice).toFixed(2)
  // console.log(num2)
  return (
    <form onSubmit={handleSubmit} className="job-application-form">
     
        <div className="row d-flex align-item justify-content-center">
    		<div className="col-md-8 col-lg-8">
    	
    		
    		   	<div className="row ufg-job-application-wrapper ptb-0 text-right">
					<div className="col-md-12 job-application-form">
					  	 <CardElement options={{ hidePostalCode: true }}  className="form-control" style={{
                  base: {
                   lineHeight: '1.6'
                  }
                 }} />
                   
                       {errorMessage?
                       <p className="text-danger" style={{textAlign: "initial", color : "red"}}>Please fill correct & complete card detail</p>
                       : '' }
                       
						<Link to="" className="prev prev-btn-bokmove prev-btn" onClick={() => props.properties.setCurrentStep((prev) => prev - 3)} style={{marginRight: '2em',textDecoration: 'none'}} ><span><i className="fa fa-arrow-left"></i>Previous</span></Link>
						
						   <button disabled={!stripe || props.properties.loading}  className="btn step-btn-bokmove step-btn" >
						   <span>Final Charge (${num2?num2:'0.00'})</span></button>
					
					</div>
				</div>
    		</div>
    	</div>
    </form>
  )
};

export default SetupForm;