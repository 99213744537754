import { Link, useParams } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import MetaHelmet from './../metaHelmet'; //Include metaHelmet

import CustomerSideBar from './../layout/CustomerSideBar';
import Service from './../../service';
import toast, { Toaster } from 'react-hot-toast';
import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Tooltip from "react-simple-tooltip"
import ProfileSection from './profileSection';
import moment from 'moment-timezone';
import { css } from "styled-components"
import ImageViewer from 'react-simple-image-viewer';


var api = new Service();
const MoveDetail = (props) => {
	const [config] = useState(props.config);
	//  const navigate = useNavigate();
	const { id } = useParams();
	const [loading, setloading] = useState(false);
	const [timezone] = useState(moment.tz.guess());
	const [moveDetail, setmoveDetail] = useState({});
	const [helperDetail, sethelperDetail] = useState({});
	const [viewotemdetail, setviewotemdetail] = useState(false);
	const [chargesdetail, setchargesdetail] = useState(false);
	const [servicechargesdetail, setservicechargesdetail] = useState(false);
	const [itemDetail, setitemDetail] = useState({});
	const [helperInfo, sethelperInfo] = useState({});
	const [step, setstep] = useState(0);
	// const [helperRequired, sethelperRequired] = useState(true);
	const [photos, setphotos] = useState([]);

	const [iframeUrl, setiframeUrl] = useState("");
	useEffect(() => {
		getCustomerMoveDetail();

	}, [])


	const getMoveHelperDetail = () => {

		api.postApi('get-move-helper-info', { request_id: id }).then(response => {
			if (response) {
				sethelperDetail(response.data);
			}
			setloading(false)
		}).catch(error => {
			toast.error(error.message);
			setloading(false)
		});
	}

	const getCustomerMoveDetail = () => {
		setloading(true)
		api.postApi('get-customer-move-detail-by-id', { request_id: id, timezone: timezone }).then(response => {
			if (response) {

				if (response.is_deleted) {
					setstep(3);
					setmoveDetail(response);
				} else if (response.required_pros == 0 && response.required_muscle == 0) {
					// sethelperRequired(false)
					setstep(2);
					setmoveDetail(response);
				} else {
					setstep(1);
					setmoveDetail(response);
				}
				setiframeUrl(config.DOMAIN + "customer/get-move-invoice/" + id)
			}

			getMoveHelperDetail();
		}).catch(error => {
			setloading(false)
			toast.error(error.message);
		});
	}

	const getItemDteail = (option) => {
		setitemDetail(option);
		setviewotemdetail(true);
	}

	const getStartTime = () => {
		let startTime = moment(moveDetail.pickup_start_time, "HH:mm");
		return startTime.format("hh:mm A");
	}

	const getEndTime = () => {
		let startTime = moment(moveDetail.pickup_end_time, "HH:mm");
		return startTime.format("hh:mm A");
	}

	const getAdditionalCharges = () => {

		let charges = moveDetail.pickup_stair_charge + moveDetail.drop_off_stair_charge + moveDetail.miles_distance_price + moveDetail.helping_service_price + moveDetail.insurance_charge;
		if (charges > 0) {
			return charges.toFixed(2)
		}
		return charges;
	}




	const setStepAnDHelper = (option) => {
		sethelperInfo(option)
		setstep(3)
	}

	const [currentImage, setCurrentImage] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);

	const openImageViewer = (index, photos_data) => {
		setphotos(photos_data)
		setCurrentImage(index);
		setIsViewerOpen(true);
	}

	const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};

	return (
		<>
			<MetaHelmet title="Move It | Move Detail" description="" />
			<Toaster position="top-right" reverseOrder={false} />
			<Header></Header>
			<main>
				<section className="coverphoto p-0"></section>

				<ProfileSection ></ProfileSection>



				<section className="work white p40">
					<article className="container">

						<div className="row">
							<CustomerSideBar />


							{step == 1 ?
								<>
									<div className="col-md-8 col-lg-9">
										<div className="row d-flex align-items-center justify-content-center" >
											<div className="col-md-12 col-lg-10">
												{helperDetail.length > 0 && helperDetail.map((option, index) => (
													<div className="contact-wrapper helperinfo-box" key={index}>

														<div className="d-block w-100 float-right mb-3">
															<div className="p-3 d-flex align-items-center bg-white border-1 osahan-post-header">
																<div className="dropdown-list-image mr-3">
																	<img className="rounded-circle" src={option.photo_url} alt="" />
																</div>
																<div className="font-weight-bold mr-3 notification-hrf">
																	<Link to=""><div className="">{option.first_name + ' ' + option.last_name}</div>
																		<div className="small">{option.selected_service_type == 1 ? 'Pro' : option.selected_service_type == 2 ? 'Muscle' : option.selected_service_type == 3 ? 'Pro & Muscle' : ''}</div>
																	</Link>
																</div>
																<span className="ml-auto mb-auto">
																	<div className="small text-right">
																		<span className="heper-revew"><i className="fas fa-star fa-fw"></i>{option.avarage_rating}</span>
																	</div>
																</span>
															</div>
														</div>
														<div className="row accpetstatus-helper">
															<div className="col-md-12 text-center">
																<Link to="" className="helper-msg-info" onClick={() => setStepAnDHelper(option)} ><i className="fas fa-info fa-fw"></i></Link>
																<br />
																<p className="mt-3" onClick={() => setstep(3)}>View Move Info</p>
																<p>{option.helper_pending_text}</p>
															</div>
														</div>
													</div>
												))}

												{helperDetail.length == 0 ?
													<>
														<div className="contact-wrapper helperinfo-box">
															<div className="d-block w-100 float-right mb-3">
															</div>
															<div className="row accpetstatus-helper">
																<div className="col-md-12 text-center">
																	<Link to="" className="helper-msg-info" onClick={() => setstep(3)} ><i className="fas fa-info fa-fw"></i></Link>
																	<br />
																	<p className="mt-3" onClick={() => setstep(3)}>View Move Info</p>
																	{moveDetail && moveDetail.required_muscle && moveDetail.required_pros ?
																		<>
																			<p>Please wait. We are connecting with Pro and Muscle.</p>
																		</>
																		: ''}

																	{moveDetail && moveDetail.required_muscle && moveDetail.required_pros == 0 ?
																		<>
																			<p>Please wait. We are connecting with Muscle.</p>
																		</>
																		: ''}

																	{moveDetail && moveDetail.required_muscle == 0 && moveDetail.required_pros ?
																		<>
																			<p>Please wait. We are connecting with Pro.</p>
																		</>
																		: ''}

																</div>
															</div>
														</div>
													</>
													: ''}
											</div>
										</div>
									</div>
								</>
								: ''}


							{step == 2 ?
								<>
									<div className="col-md-8 col-lg-9">
										<div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
											<div className="col-12 col-md-6 col-lg-6">
												<h5>Scheduled Time: {moveDetail.meeting_slot}</h5>
											</div>
											<div className="col-md-12 col-lg-10">
												{helperDetail.length > 0 && helperDetail.map((option, index) => (
													<div className="contact-wrapper helperinfo-box" key={index}>

														<div className="d-block w-100 float-right mb-3">
															<div className="p-3 d-flex align-items-center bg-white border-1 osahan-post-header">
																<div className="dropdown-list-image mr-3">
																	<img className="rounded-circle" src={option.photo_url} alt="" />
																</div>
																<div className="font-weight-bold mr-3 notification-hrf">
																	<div className="">{option.first_name + ' ' + option.last_name}</div>
																	<div className="small">{option.selected_service_type == 1 ? 'Pro' : option.selected_service_type == 2 ? 'Muscle' : option.selected_service_type == 3 ? 'Pro & Muscle' : ''}<span className="heper-revew"><i className="fas fa-star fa-fw"></i>{option.avarage_rating}</span>

																		{moveDetail.job_status == 0 && moveDetail.is_deleted == 0 ?
																			<>
																				<Tooltip content={option.phone_num} customCss={css`
                                                                  white-space: nowrap; 
                                                                  
                                                                `}>
																					<span className="callmsg-icon"><i className="fas fa-phone fa-fw"></i></span>
																				</Tooltip>
																				<Link to={'/message/' + option.helper_id} ><span className="callmsg-icon" ><i className="fas fa-envelope fa-fw" ></i></span>	</Link>
																			</>
																			: ''}
																	</div>

																</div>
																<span className="ml-auto mb-auto">
																	<div className="small text-right">
																		{/* <Link to="" data-toggle="modal" data-target="#addtip-model" className="helper-msg-map">
    										    <i className="fas fa-location-arrow"></i>
    										  </Link> 
    										  <Link to="" data-toggle="modal" data-target="#give-helper-review" className="helper-msg-review">
    										     <i className="far fa-star"></i>
    										  </Link>*/}
																		<Link to="" onClick={() => setStepAnDHelper(option)} className="helper-msg-info"><i className="fas fa-info fa-fw"></i></Link>
																	</div>
																</span>
															</div>
														</div>
														{option.service_type == 1 || option.service_type == 3 ?
															<>
																<p className="mb-0">Helper Vechicle Info</p>
																<div className="d-block w-100 float-right mb-3">
																	<div className="row no-gutters bg-white p-10all-side move-dmap">
																		<div className="col-xl-6 col-lg-6 col-md-6">
																			<div className="row">
																				<div className="col-md-12">
																					<i className="pr-1 maploc"></i><small>Vehicle Type</small>
																					<br />
																					<span className="maptxt">{option.type}</span>
																				</div>
																				<div className="col-md-12">
																					<i className="pr-1 maploc"></i><small>Vehicle License Plate Number</small>
																					<br />
																					<span className="maptxt">{option.plate_num}</span>
																				</div>
																			</div>
																		</div>
																		<div className="col-xl-6 col-lg-6 col-md-6">
																			<div className="row">
																				<div className="col-12 col-lg-12 align-self-center">
																					<i className="pr-1 maploc-1"></i><small>Vehicle Make</small>
																					<br />
																					<span className="maptxt">{option.company_name}</span>
																				</div>
																			</div>
																			<div className="row">
																				<div className="col-12 col-lg-12 align-self-center">
																					<i className="pr-1 maploc-1"></i><small>Vehicle Model</small>
																					<br />
																					<span className="maptxt">{option.model}</span>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="row itemstep item-photomovedetal">
																	<div className="col-md-12"><h5>Vechicle Photos</h5></div>

																	{option.vehicle_photo_url.length > 0 && option.vehicle_photo_url.map((option_imgae, image_index) => (
																		<div className="col-6 col-md-3 col-lg-3 mb-2" key={image_index} >
																			<div className="cardbox add-item">
																				<img alt="" src={option_imgae} onClick={() => openImageViewer(image_index, option.vehicle_photo_url)} />
																			</div>
																		</div>
																	))}
																</div>
															</>
															: ''}
														<div className="row accpetstatus-helper">
															<div className="col-md-12">
																<ul className="timeline" id="timeline">
																	<li className={option.helper_status > 0 ? "li complete" : "li"}>
																		<div className="timestamp">
																		</div>
																		<div className={option.helper_status > 1 ? "status line-status done-line " : "status line-status"} >
																			<h6> Accepted</h6>
																		</div>
																	</li>
																	<li className={option.helper_status > 1 ? "li complete" : "li"}>
																		<div className="timestamp">
																		</div>
																		<div className={option.helper_status > 2 ? "status line-status done-line " : "status line-status"} >
																			<h6> On the way </h6>
																		</div>
																	</li>
																	<li className={option.helper_status > 2 ? "li complete" : "li"}>
																		<div className="timestamp">
																		</div>
																		<div className={option.helper_status > 3 ? "status line-status done-line " : "status line-status"}>
																			<h6> Start Service </h6>
																		</div>
																	</li>
																	<li className={option.helper_status > 3 ? "li complete" : "li"}>
																		<div className="timestamp">
																		</div>
																		<div className="status lastline-none">
																			<h6> End Service </h6>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									</div>
								</>
								: ''}
							{step == 3 ?
								<>
									<div className="col-md-8 col-lg-9">
										<div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">

											<div className="col-6 col-md-6 col-lg-5">
												<h3>Move detail</h3>
											</div>
											{moveDetail.job_status == 0 && moveDetail.is_deleted == 0 ?

												<>
													<div className="col-6 col-md-6 col-lg-5">
														<Link to="" className="admoves" onClick={() => setchargesdetail(true)}><i className="fas fa-file-invoice-dollar"></i> Charges detail</Link>
													</div>
												</>
												: ''}
											{moveDetail.job_status == 1 || moveDetail.is_deleted == 1 ?
												<div className="col-6 col-md-6 col-lg-5">
													<Link to="" onClick={() => setstep(4)} className="admoves"><i className="fas fa-file-invoice-dollar"></i> Invoice</Link>
												</div>
												: ''}
											<div className="col-md-12 col-lg-10 mt-3">
												<div className="contact-wrapper">
													<div className="d-block w-100 float-right mb-2">
														<div className="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header">
															{moveDetail ?
																<div className="font-weight-bold mr-3 notification-hrf">

																	<Link to="#"><div className="">Move ID #{moveDetail.request_id}</div>
																		<div className="small">{moveDetail.dropoff_service_name}</div>
																	</Link>
																</div>
																: ''}
															{moveDetail && moveDetail.helping_service_name ?
																<span className="ml-auto mb-auto">
																	{moveDetail.job_status == 1 && moveDetail.is_deleted == 0 ?
																		<div className="text-right price-helper" style={{ color: "#25b420" }}>Completed</div>
																		: ''}
																	{moveDetail.job_status == 0 && moveDetail.is_deleted == 1 ?
																		<div className="text-right price-helper">Canceled</div>
																		: ''}
																	<div className="small text-right">{moveDetail.helping_service_name}</div>
																</span>
																: ''}
														</div>
														<div className="row no-gutters bg-light p-10all-side move-dmap movesummary">

															{moveDetail && moveDetail.move_type && moveDetail.move_type.is_pickup_address ?
																<div className="col-md-6 col-lg-6">
																	<i className="pr-1 maploc"><img src="/web/img/from_icon.png" alt="" /></i><small>From</small>
																	<br />
																	<span className="maptxt">{moveDetail.pickup_address}
																		{moveDetail.pickup_address_type == 1 && moveDetail.pickup_apartment ?
																			<p className="apt-unit">Unit # {moveDetail.pickup_apartment} </p>
																			: ''}

																		{moveDetail.pickup_address_type == 2 && moveDetail.pickup_apartment ?
																			<p className="apt-unit">Apt. # {moveDetail.pickup_apartment} </p>
																			: ''}

																		{moveDetail.pickup_gate_code ?
																			<p className="apt-unit">Gate Code: # {moveDetail.pickup_gate_code} </p>
																			: ''}
																	</span>

																</div>
																: ''}
															{moveDetail && moveDetail.move_type && moveDetail.move_type.is_dropoff_address ?
																<div className="col-md-6 col-lg-6">
																	<i className="pr-1 maploc"><img src="/web/img/to_location_icon.png" alt="" /></i><small>To</small>
																	<br />
																	<span className="maptxt">{moveDetail.dropoff_address}

																		{moveDetail.dropoff_address_type == 1 && moveDetail.dropoff_apartment ?
																			<p className="apt-unit">Unit # {moveDetail.dropoff_apartment} </p>
																			: ''}

																		{moveDetail.dropoff_address_type == 2 && moveDetail.dropoff_apartment ?
																			<p className="apt-unit">Apt. # {moveDetail.dropoff_apartment} </p>
																			: ''}

																		{moveDetail.dropoff_gate_code ?
																			<p className="apt-unit">Gate Code: # {moveDetail.dropoff_gate_code} </p>
																			: ''}
																	</span>

																</div>
																: ''}
														</div>

														<div className="row no-gutters bg-light p-10all-side pb-2">
															{moveDetail && moveDetail.meeting_slot ?
																<div className="col-6 col-lg-6">
																	<i className="far fa-calendar-alt pr-1 maploc-1"></i><small>Scheduled Time/Date</small>
																	<br />
																	<span className="maptxt">{moveDetail.meeting_slot}</span>
																</div>
																: ''}


															<div className="col-6 col-lg-6">
																<i className="far fa-calendar-alt pr-1 maploc-1"></i><small>Pickup Time/Date</small>
																<br />
																<span className="maptxt">{moment(moveDetail.pickup_date).format('ddd, MMM DD')}, {getStartTime()} - {getEndTime()}</span>
															</div>
														</div>



													</div>
												</div>



												<div className="movedetail-boxcustomer">
													<div className="row itemstep">
														{moveDetail && moveDetail.items && moveDetail.items.map((option, index) => (
															<div className="col-12 col-md-6 col-lg-6 mb-1" key={index} >
																<div className="cardbox add-item">
																	<h5>{option.item_name} <span className="t-item">{option.quantity}</span></h5>
																	<p><Link to="#" onClick={() => getItemDteail(option)} >View item detail</Link></p>
																</div>
															</div>
														))}
													</div>
												</div>
												<hr />
												{moveDetail && moveDetail.move_type && moveDetail.move_type.is_pickup_address ?
													<div className="row">
														<div className="col-12 col-md-12 col-lg-12 mb-1 mt-2">
															<h6>Pickup Location Details</h6>
														</div>
														<div className="col-6 col-md-6 col-lg-6">
															<div className="pick-l-detail">
																<h5>Need to use Stairs</h5>
																<h6>{moveDetail.pickup_stairs} Flight(s)</h6>
															</div>
														</div>
														<div className="col-6 col-md-6 col-lg-6">
															<div className="pick-l-detail">
																<h5>Need to use Elevator</h5>
																<h6>{moveDetail.use_pickup_elevator ? 'Yes' : 'No'}</h6>
															</div>
														</div>
													</div>
													: ''}

												{moveDetail && moveDetail.move_type && moveDetail.move_type.is_dropoff_address ?
													<div className="row">
														<div className="col-12 col-md-12 col-lg-12 mb-1 mt-2">
															<h6>DropOff Location Details</h6>
														</div>
														<div className="col-6 col-md-6 col-lg-6">
															<div className="pick-l-detail">
																<h5>Need to use Stairs</h5>
																<h6>{moveDetail.drop_off_stairs} Flight(s)</h6>
															</div>
														</div>
														<div className="col-6 col-md-6 col-lg-6">
															<div className="pick-l-detail">
																<h5>Need to use Elevator</h5>
																<h6>{moveDetail && moveDetail.use_dropoff_elevator ? 'Yes' : 'No'}</h6>
															</div>
														</div>
													</div>
													: ''}


												{helperInfo && helperInfo.pickup_photos_url && helperInfo.pickup_photos_url.length > 0 ?
													<>
														<div className="row itemstep item-photomovedetal">
															<div className="col-md-12"><h5>Pickup Location Photos</h5></div>
															{helperInfo && helperInfo.pickup_photos_url && helperInfo.pickup_photos_url.length > 0 && helperInfo.pickup_photos_url.map((image, ind) => (
																<div className="col-6 col-md-3 col-lg-3 mb-2" key={ind}>
																	<div className="cardbox add-item">
																		<img src={image} alt="" />
																	</div>
																</div>
															))}

														</div>
													</>
													: ''}

												{helperInfo && helperInfo.dropoff_photos_url && helperInfo.dropoff_photos_url.length > 0 ?
													<>
														<div className="row itemstep item-photomovedetal">
															<div className="col-md-12"><h5>Dropoff Location Photos</h5></div>
															{helperInfo && helperInfo.dropoff_photos_url && helperInfo.dropoff_photos_url.length > 0 && helperInfo.dropoff_photos_url.map((image, ind) => (
																<div className="col-6 col-md-3 col-lg-3 mb-2" key={ind}>
																	<div className="cardbox add-item">
																		<img src={image} alt="" />
																	</div>
																</div>
															))}
														</div>
													</>
													: ''}



											</div>
										</div>
									</div>
								</>
								: ''}

							{step == 4 ?
								<>
									<div className="col-md-8 col-lg-9">
										<div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
											<div className="col-md-12 col-lg-12" style={{ height: "500px" }}>
												<iframe src={iframeUrl} title="Invoice" style={{ height: "100%" }}></iframe>
											</div>
										</div>
									</div>
								</>
								: ''}
						</div>
					</article>
				</section>


				<div className={viewotemdetail ? "modal right display-block" : "modal  right  display-none"} id="viewotemdetail" tabIndex="-1" role="dialog" aria-labelledby="viewotemdetail">
					<div className="modal-dialog modal-right-side" role="document">
						<div className="modal-content ufg-job-application-wrapper">
							<div className="modal-header">
								<h4 className="modal-title" id="myModalLabel2">Move Details</h4>
								<button type="button" className="close" onClick={() => setviewotemdetail(false)} aria-label="Close"><span aria-hidden="true" onClick={() => setviewotemdetail(false)} >&times;</span></button>
							</div>
							<div className="modal-body job-application-form">
								<div className="add-normal-itam-content">
									{itemDetail ?
										<>
											<div className="movedetail-boxcustomer">
												<div className="row itemstep">
													<div className="col-12 col-md-12 col-lg-12 mb-1">
														<div className="cardbox add-item">
															<h5>{itemDetail.item_name}<span className="t-item">{itemDetail.quantity}</span></h5>
														</div>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-12 col-md-12 col-lg-12 mb-1 mt-2">
													<h6>Additional Details</h6>
												</div>
												<div className="col-6 col-md-4 col-lg-4">
													<div className="pick-l-detail">
														<h5>Width</h5>
														<h6>{itemDetail.item_width}</h6>
													</div>
												</div>
												<div className="col-6 col-md-4 col-lg-4">
													<div className="pick-l-detail">
														<h5>Height</h5>
														<h6>{itemDetail.item_height}</h6>
													</div>
												</div>
												<div className="col-6 col-md-4 col-lg-4">
													<div className="pick-l-detail">
														<h5>Depth</h5>
														<h6>{itemDetail.item_depth}</h6>
													</div>
												</div>
												<div className="col-6 col-md-6 col-lg-6">
													<div className="pick-l-detail">
														<h5>Weight</h5>
														<h6>{itemDetail.item_weight}</h6>
													</div>
												</div>
												<div className="col-6 col-md-6 col-lg-6">
													<div className="pick-l-detail">
														<h5>Assemble/Disassemble</h5>
														<h6>{itemDetail.can_assamble ? 'Yes' : 'No'}</h6>
													</div>
												</div>

												<div className="col-12 col-md-12 col-lg-12">
													<h5>About Item</h5>
													<p className="pstep-from">{itemDetail.additional_info}</p>
												</div>

											</div>

											<div className="row itemstep item-photomovedetal">
												<div className="col-md-12"><h5>Item Photos</h5></div>

												{itemDetail && itemDetail.item_photos && itemDetail.item_photos.length > 0 && itemDetail.item_photos.map((option, index) => (
													<div className="col-6 col-md-4 col-lg-4 mb-2" key={index}>
														<div className="cardbox add-item">
															<img alt="" src={option} onClick={() => openImageViewer(index, itemDetail.item_photos)} />
														</div>
													</div>
												))}
											</div>
										</>
										: ''}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className={chargesdetail ? "modal right  charge-model display-block" : "modal  right charge-model display-none"} id="charges-detail" tabIndex="-1" role="dialog" aria-labelledby="charges-detail">
					<div className="modal-dialog modal-right-side" role="document">
						<div className="modal-content ufg-job-application-wrapper">


							<div className="modal-header">
								<h4 className="modal-title" id="myModalLabel2">Charges Details</h4>
								<button type="button" className="close" onClick={() => setchargesdetail(false)}><span aria-hidden="true" onClick={() => setchargesdetail(false)}>&times;</span></button>
							</div>

							<div className="modal-body job-application-form">
								<div className="add-normal-itam-content">
									<div className="row itemstep">
										<div className="col-6 col-lg-6 mb-2 ttt-price">
											{moveDetail && moveDetail.required_pros == 0 && moveDetail.required_muscle == 0 ?
												<b>Total Amount</b>
												: <b>Total Hold Amount</b>
											}
										</div>
										<div className="col-6 col-lg-6 mb-2 tprice">
											<b>${moveDetail.final_price}</b>
										</div>
									</div>
									<div className="row mb-3">
										{moveDetail.items && moveDetail.items.length > 0 && moveDetail.items.map((option, index) => (
											<div className="col-md-12" key={index}>
												<div className="last-itme-box">
													<h6 className="fontname">{option.item_name}</h6>
													<span className="edit">
														<Link to="#">${option.total_amount ? option.total_amount.toFixed(2) : 0.00}</Link>
													</span>
												</div>
											</div>
										))}
										{moveDetail.is_base_price ?
											<div className="col-md-12">
												<div className="last-itme-box">
													<h6 className="fontname">Base Charge</h6>
													<span className="edit">
														<Link to="#">${moveDetail.base_price ? moveDetail.base_price.toFixed(2) : 0.00}</Link>
													</span>
												</div>
											</div>
											: ''}
										<div className="col-md-12">
											<div className="last-itme-box">
												<h6 className="fontname" onClick={() => setservicechargesdetail(true)} ><Link to="" onClick={() => setservicechargesdetail(true)} >Additional Charges</Link></h6>
												<span className="edit">
													<Link to="#">${getAdditionalCharges()}</Link>
												</span>
											</div>
										</div>
										<div className="col-md-12">
											<div className="last-itme-box">
												<h6 className="fontname">Service Discount Amount</h6>
												<span className="edit">
													<Link to="#">-${moveDetail.service_discount ? moveDetail.service_discount.toFixed(2) : 0.00}</Link>
												</span>
											</div>
										</div>
										<div className="col-md-12">
											<div className="last-itme-box">
												<h6 className="fontname">Promocode Discount</h6>
												<span className="edit">
													<Link to="#">-${moveDetail.promocode_discount ? moveDetail.promocode_discount.toFixed(2) : 0.00}</Link>
												</span>
											</div>
										</div>
										{moveDetail && moveDetail.estimate_hour > 0 && moveDetail.move_type && moveDetail.move_type.is_estimate_hour ?
											<>
												<div className="col-md-12">
													<div className="last-itme-box">
														<h6 className="fontname">Estimated Hour Charge
															<br />
															<span style={{ fontWeight: "unset", fontSize: "small", color: "gray" }}
															>{moveDetail.estimate_hour - 1} extra hour added</span>
														</h6>

														<span className="edit">
															<Link to="#">${moveDetail.estimate_hour_price ? moveDetail.estimate_hour_price.toFixed(2) : 0.00}</Link>
														</span>
													</div>
												</div>
											</>
											: ''}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className={servicechargesdetail ? "modal right  charge-model display-block" : "modal  right charge-model display-none"} id="addtinal-charges" tabIndex="-1" role="dialog" aria-labelledby="addtinal-charges">
					<div className="modal-dialog  modal-right-side" role="document">
						<div className="modal-content ufg-job-application-wrapper">

							<div className="modal-header">
								<h4 className="modal-title" id="myModalLabel2">Services Charges Details</h4>
								<button type="button" className="close" onClick={() => setservicechargesdetail(false)}><span aria-hidden="true" onClick={() => setservicechargesdetail(false)}>&times;</span></button>
							</div>

							<div className="modal-body job-application-form">
								<div className="add-normal-itam-content">
									<div className="row itemstep">
										<div className="col-6 col-lg-6 mb-2 ttt-price">
											<b>Total</b>
										</div>
										<div className="col-6 col-lg-6 mb-2 tprice">
											<b>${getAdditionalCharges()}</b>
										</div>
									</div>
									<div className="row mb-3">
										<div className="col-md-12">
											<div className="last-itme-box">
												<h6 className="fontname">Distance Charge</h6>
												<span className="edit">
													<Link to="#">${moveDetail.miles_distance_price ? moveDetail.miles_distance_price.toFixed(2) : 0.00}</Link>
												</span>
											</div>
										</div>
										<div className="col-md-12">
											<div className="last-itme-box">
												<h6 className="fontname">Pickup Stairs</h6>
												<span className="edit">
													<Link to="#">${moveDetail.pickup_stair_charge ? moveDetail.pickup_stair_charge.toFixed(2) : 0.00}</Link>
												</span>
											</div>
										</div>
										<div className="col-md-12">
											<div className="last-itme-box">
												<h6 className="fontname">Dropoff Service</h6>
												<span className="edit">
													<Link to="#">${moveDetail.drop_off_stair_charge ? moveDetail.drop_off_stair_charge.toFixed(2) : 0.00}</Link>
												</span>
											</div>
										</div>
										<div className="col-md-12">
											<div className="last-itme-box">
												<h6 className="fontname">Helper Service Charge</h6>
												<span className="edit">
													<Link to="#">${moveDetail.helping_service_price ? moveDetail.helping_service_price.toFixed(2) : 0.00}</Link>
												</span>
											</div>
										</div>
										<div className="col-md-12">
											<div className="last-itme-box">
												<h6 className="fontname">Insurance Charge</h6>
												<span className="edit">
													<Link to="#">${moveDetail.insurance_charge ? moveDetail.insurance_charge.toFixed(2) : 0.00}</Link>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{photos && photos.length > 0 && isViewerOpen && (
					<ImageViewer
						src={photos}
						currentIndex={currentImage}
						disableScroll={false}
						closeOnClickOutside={true}
						onClose={closeImageViewer}
					/>
				)}
			</main>
			<Footer loading={loading}></Footer>
		</>
	)
}
export default MoveDetail;

