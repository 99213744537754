import { useParams } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import CustomerSideBar from './../layout/CustomerSideBar';
import Service from './../../service';
import toast, { Toaster } from 'react-hot-toast';
import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import ProfileSection from './profileSection';
import moment from 'moment-timezone';
import parse from 'html-react-parser';
import socket from './../../socket';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet
var api = new Service();



const Message = props => {
  const [config] = useState(props.config);

  const { helper_id } = useParams();
  const [loading, setloading] = useState(false);
  const [dataArray, setdataArray] = useState([]);
  const [chatArray, setchatArray] = useState([]);
  const [message, setmessage] = useState('');
  const [chatDetail, setchatDetail] = useState({});
  const [chatResponse, setchatResponse] = useState({});
  const [pageno, setpageno] = useState(0);
  const [newMessage, setnewMessage] = useState(0);
  const [countData, setcountData] = useState(0);
  const [datarender, setdatarender] = useState(false);
  // const [countData,setcountData] =  useState(0);

  useEffect(() => {
    if (newMessage) {

      if (newMessage.customer_id == chatDetail.customer_id && chatDetail.helper_id == newMessage.helper_id) {
        setchatArray([...chatArray, newMessage])
      }
      getAllCustomerChat()
    } else {
      getAllCustomerChat();
    }
  }, [newMessage])

  useEffect(() => {
    console.log(757567)
    if (helper_id > 0 && dataArray.length > 0 && datarender == false) {
      var option = dataArray.find(x => x.helper_id == helper_id)
      console.log(option)
      if (option) {
        setloading(false)
        setchatArray([]);
        setchatDetail(option);
        getChatMessage(1, option)
      }
      setdatarender(true)
    }
  }, [dataArray])

  useEffect(() => {
    if (helper_id > 0) {
      api.getApi('get-helper-detail', { helper_id: helper_id }).then(response => {
        console.log(response)
        setloading(false)
        setchatArray([]);
        setchatDetail(response);
        getChatMessage(1, response)
      }).catch(error => {
        setloading(false);
        toast.error(error.message);
      });
    }
  }, [helper_id])

  socket.on('message', (data) => setnewMessage(data));

  const setScroll = () => {
    var objDiv = document.getElementById("chat-conversation");
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }

  }
  const getAllCustomerChat = (page_no = 1) => {
    setloading(true)
    api.postApi('get-all-chat', { page_no: page_no, timezone: moment.tz.guess() }).then(response => {
      setloading(false);
      setdataArray(response.data);

      setScroll();
      let countValue = countData + 1;
      setcountData(countValue);


    }).catch(error => {
      setloading(false);
      toast.error(error.message);
    });
  }

  const setUserChatList = (option) => {
    setloading(false)
    setchatArray([]);
    setchatDetail(option);
    getChatMessage(1, option)
  }

  const getChatMessage = (page_no = 1, option) => {
    console.log(loading)
    if (loading == false) {
      setloading(true)
      setpageno(page_no)
      api.postApi('get-chat-message', { to_user: option.helper_id, request_id: option.request_id, page_no: page_no, timezone: moment.tz.guess() }).then(response => {
        setchatResponse(response)
        if (response && response.data.length > 0) {
          if (page_no == 1) {
            setchatArray(response.data.reverse())
            var objDiv = document.getElementById("chat-conversation");
            if (objDiv) {
              objDiv.scrollTop = objDiv.scrollHeight;
            }
          } else {
            var objDiv = document.getElementById("chat-conversation");
            setchatArray(response.data.reverse().concat(chatArray))
            console.log(objDiv.scrollTop)
            if (objDiv) {
              objDiv.scrollTop = 300
            }
          }
        }
        setloading(false)
      }).catch(error => {
        setloading(false)
        toast.error(error.message);
      });
    }
  }


  const getTime = (created_datetime) => {
    var startTime = new Date(created_datetime);
    return moment(startTime).fromNow()
  }

  const sendMessage = () => {
    console.log(message)

    if (message != '') {
      setloading(true)
      api.postApi('send-message', { request_id: chatDetail.request_id, message: message, msg_type: 1, sent_by: 'C', to_user: chatDetail.helper_id, timezone: moment.tz.guess() }).then(response => {
        if (response && response.data.length > 0) {
          socket.emit('message', response.data[0]);
        }
        setmessage('')
        // setloading(false)
      }).catch(error => {
        setloading(false)
        toast.error(error.message);
      });
    }
  }

  const getScroll = (event) => {
    var objDiv = document.getElementById("chat-conversation");
    if (objDiv.scrollTop < 5 && chatResponse.next_page) {
      let page_no = pageno + 1;
      getChatMessage(page_no, chatDetail)
    }
  }

  useEffect(() => {
    document.title = "Move It | Chat";
    window.gtag('config', config.G_TAG, {
      page_title: document.title,
      page_path: window.location.pathname,
    });
  }, []);

  return (
    <>
      <MetaHelmet title="Move It | Chat" description="" />
      <Toaster position="top-right" reverseOrder={false} />
      <Header></Header>
      <main>
        <section className="coverphoto p-0"></section>
        <ProfileSection ></ProfileSection>
        <section className="work white p40">
          <article className="container">
            <div className="row">
              <CustomerSideBar countData={countData} />
              <div className="col-md-8 col-lg-9">
                <div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
                  <div className="col-12 col-md-12 col-lg-10">
                    <h3 className="text-left">Message</h3>
                  </div>
                  <div className="col-md-12 col-lg-10">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="home-page__content messages-page">
                          <div className="container-fluid h-100 p-0">
                            <div className="row px-0 h-100">
                              {dataArray.length == 0 && loading == false && helper_id == undefined ? <p className="nomoveimg"><img src="/web/img/no_messages__placeholder.png" /> <br /> No Messages Yet!</p> : ''}
                              {/* {dataArray.length>0?
                                            <> */}
                              <div className="col-12 col-md-4 col-lg-5 col-xl-5 px-0 messages-page__list-scroll">
                                <ul className="messages-page__list pb-5 px-1 px-md-3" id="chatMessage">
                                  {dataArray.length > 0 && dataArray.map((option, index) => (
                                    <li className="messaging-member messaging-member--new messaging-member--online" key={index} onClick={() => setUserChatList(option)}  >
                                      <div className="messaging-member__wrapper">
                                        <div className="messaging-member__avatar">
                                          <img src={option.photo_url} alt={option.name} loading="lazy" />
                                          {/*<div className="user-status"></div>*/}
                                        </div>

                                        <span className="messaging-member__name">{option.name} (#{option.request_id}) </span>

                                        <span className="messaging-member__message">{parse(option.message)}</span>
                                        {/* <span className="messaging-member__message"><small>{getTime(option.created_datetime)}</small></span> */}
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              {chatDetail && chatDetail.helper_id ?
                                <>

                                  <div className="chat col-12 col-md-8 col-lg-7 col-xl-7 px-0 pl-md-1">
                                    <div className="chat__container">
                                      <div className="chat__wrapper py-2 pt-mb-2 pb-md-3">
                                        <div className="chat__messaging messaging-member--online pb-2 pb-md-2 pl-2 pl-md-4 pr-2">
                                          <div className="chat__previous d-flex d-md-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon svg-icon--previous" viewBox="0 0 45.5 30.4">
                                              <path d="M43.5,13.1H7l9.7-9.6A2.1,2.1,0,1,0,13.8.6L.9,13.5h0L.3,14v.6c0,.1-.1.1-.1.2v.4a2,2,0,0,0,.6,1.5l.3.3L13.8,29.8a2.1,2.1,0,1,0,2.9-2.9L7,17.2H43.5a2,2,0,0,0,2-2A2.1,2.1,0,0,0,43.5,13.1Z" fill="#f68b3c" />
                                            </svg>
                                          </div>
                                          <div className="chat__notification d-flex d-md-none chat__notification--new">
                                            <span>1</span>
                                          </div>
                                          <div className="chat__infos pl-2 pl-md-0">
                                            <div className="chat-member__wrapper" data-online="true">
                                              <div className="chat-member__avatar">
                                                <img src={chatDetail.photo_url} alt={chatDetail.name} loading="lazy" />
                                                {/*<div className="user-status user-status--large"></div>*/}
                                              </div>
                                              <div className="chat-member__details">
                                                <span className="chat-member__name">{chatDetail.name}</span>
                                                {/*<span className="chat-member__status">Online</span>*/}
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                        <div className="chat__content pt-4 px-3" onScroll={(e) => getScroll(e)} id="chat-conversation">
                                          <ul className="chat__list-messages"    >

                                            {chatArray.length > 0 && chatArray.map((option, index) => (
                                              <li key={index} >
                                                {option.sent_by == 'H' ? (
                                                  <>
                                                    <div className="chat__bubble chat__bubble--you">
                                                      {parse(option.message)}
                                                    </div>
                                                    <div className="chat__time chat__time__left">{option.created_datetime}</div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="chat__bubble chat__bubble--me">
                                                      {parse(option.message)}
                                                    </div>
                                                    <div className="chat__time">{option.created_datetime}</div>
                                                  </>
                                                )
                                                }
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                        <div className="chat__send-container px-2 px-md-3 pt-1 pt-md-3">
                                          <div className="custom-form__send-wrapper text-center">
                                            {chatResponse.job_status || helper_id > 0 ?
                                              <>
                                                <input type="text" value={message} className="form-control custom-form" placeholder="Type your message here" autoComplete="off" onChange={(e) => setmessage(e.target.value)} onKeyDown={e => e.key === 'Enter' && sendMessage()} />

                                                <button type="button" className="custom-form__send-submit" onClick={() => sendMessage()} >
                                                  <i className="fas fa-paper-plane fa-fw"></i>
                                                </button>
                                              </>
                                              :
                                              <b>Your Move It job is no longer active with this helper.</b>

                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                                : ''}
                              {/* </>
                                            : '' } */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </main>
      <Footer loading={loading}></Footer>
    </>
  )

}
export default Message;

