import React, { useState, useEffect } from "react";
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import HelperSideBar from './../layout/HelperSideBar';
import ProfileSection from './profileSection';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet

import Service from './../../service';
import toast, { Toaster } from 'react-hot-toast';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useFormik } from 'formik';

import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { firebase, auth } from './../firebase.js';
let verify;
let recaptchaWrapperRef;
const api = new Service();
const MyProfile = (props) => {
    const [helperProfile, sethelperProfile] = useState({});
    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const [country, setcountry] = useState([]);
    const [oldPhoneNumber, setOldPhoneNumber] = useState('');
    const [contryCode, setContryCode] = useState('');
    const [OTP, setOTP] = useState('');
    const [final, setfinal] = useState('');
    const [show, setShow] = useState(false);
    const [showOtpModel, setshowOtpModel] = useState(false);
    useEffect(() => {
        GethelperProfile();
    }, [])
    const GethelperProfile = () => {
        setloading(true)
        api.getApiHelper('get-helper-info').then(response => {
            if (response && response.is_verified == 0) {
                setloading(false);
                navigate('/helper-pending')
            } else {
                sethelperProfile(response);
                setOldPhoneNumber(response.phone_num);
                setContryCode(response.dial_code)
                setloading(false);
                getCountry()
            }
        }).catch(error => {
            setloading(false);
            toast.error(error.message);
        });
    }

    const getCountry = () => {
        api.getApi('get-country').then(response => {
            setcountry(response)
        }).catch(error => {
        });
    }
    const formik = useFormik({
        initialValues: {
            dial_code: "",
            phone_num: "",
            valid_phone_num: true,
            otp_verified: false,
            vaild_otp: true
        },
        validationSchema: yup.object({
            dial_code: yup.string().required('Please select your country.'),
            phone_num: yup.number().typeError('you must specify a number').required('Please enter your number'),
        }),
        onSubmit: values => {
            setloading(true);
            if (formik.values.otp_verified === false) {
                sentOTP();
            } else {
                api.postApiHelper('update-helper-profile', values).then(response => {
                    toast.success(response.message);
                    formik.resetForm()
                    GethelperProfile();
                }).catch(error => {
                    toast.error(error.message);
                });
            }
        }
    });

    const formikPaswword = useFormik({
        initialValues: {
            password: "",
            passwordConfirmation: '',
        },
        validationSchema: yup.object({
            password: yup.string().required('Password is required'),
            passwordConfirmation: yup.string().required('Confirm Password is required')
                .oneOf([yup.ref('password'), null], 'Passwords must match')
        }),
        onSubmit: values => {
            api.postApiHelper('reset-helper-password', values).then(response => {
                toast.success(response.message);
                formikPaswword.resetForm();
            }).catch(error => {
                toast.error(error.message);
            });
        }
    });

    const sentOTP = () => {
        if (verify && recaptchaWrapperRef) {
            verify.clear()
            recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`
        }
        verify = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible'
        });
        auth.signInWithPhoneNumber(formik.values.dial_code + '' + formik.values.phone_num, verify).then((result) => {
            setfinal(result);
            formik.setFieldValue('valid_phone_num', true);
            setshowOtpModel(true);
            setOTP('');
            setShow(false);
            setloading(false);
        }).catch((err) => {
            setloading(false);
            formik.setFieldValue('valid_phone_num', false);
        });
    }

    const ValidateOtp = () => {
        setloading(true);
        if (OTP == null || final == null)
            return;
        final.confirm(OTP).then((result) => {
            formik.setFieldValue('otp_verified', true);
            formik.setFieldValue('vaild_otp', true);
            setshowOtpModel(false);
            setOldPhoneNumber(formik.values.dial_code + '' + formik.values.phone_num)
            setloading(false);
        }).catch((err) => {
            setloading(false);
            formik.setFieldValue('vaild_otp', false);
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        ValidateOtp();
    }


    const [config, setconfig] = useState(props.config);


    useEffect(() => {
        document.title = "Move It | Helper Profile";
        window.gtag('config', config.G_TAG, {
            page_title: document.title,
            page_path: window.location.pathname,
        });
    }, []);


    return (
        <>
            <Header helperProfile={helperProfile} />
            <MetaHelmet title="Move It | Helper Profile" description="" />
            <main >
                {helperProfile.is_verified ?
                    <>
                        <section className="coverphoto-helper p-0"></section>
                        <ProfileSection helperProfile={helperProfile} />
                        <section className="work white p40">
                            <article className="container">
                                <div className="row">
                                    <HelperSideBar helperProfile={helperProfile} />
                                    <div className="col-md-8 col-lg-9">
                                        <div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
                                            <div className="col-12 col-md-12 col-lg-10 mb-3">
                                                <h3 className="flourish text">
                                                    <span className="flourish-square">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}>
                                                            <defs>
                                                                <clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath>
                                                            </defs>
                                                            <g clipPath="url(#__lottie_element_147)">
                                                                <g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1">
                                                                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                                                        <path
                                                                            strokeLinecap="butt"
                                                                            strokeLinejoin="miter"
                                                                            fillOpacity="0"
                                                                            strokeMiterlimit="4"
                                                                            stroke="rgb(232,154,167)"
                                                                            strokeOpacity="1"
                                                                            strokeWidth="10"
                                                                            d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"
                                                                        ></path>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    Edit Profile
                                                </h3>
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-10">
                                                <div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
                                                    <div className="col-md-12 col-lg-10">
                                                        <div className="contact-wrapper">
                                                            <form className="job-application-form login-page row" onSubmit={formik.handleSubmit}>

                                                                <div className="form-row col-md-6 col-lg-6">
                                                                    <i className="fa fa-phone"></i>
                                                                    <input type="text" readOnly defaultValue={oldPhoneNumber ? oldPhoneNumber : ''} onClick={() => setShow(true, formik.resetForm())} />
                                                                    <span className="phonetxt">Phone</span>
                                                                </div>
                                                                {formik.values.otp_verified ?
                                                                    <div className="col-md-12">
                                                                        <div className="form-row">
                                                                            <button className="full" type="submit"><span>SAVE CHANGES</span></button>
                                                                        </div>
                                                                    </div>
                                                                    : ''}
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
                                                    <div className="col-md-12 col-lg-10">
                                                        <h3 className="flourish text">Reset Password</h3>
                                                        <div className="contact-wrapper">
                                                            <form action="#" className="job-application-form login-page row" onSubmit={formikPaswword.handleSubmit}>
                                                                <div className="form-row col-md-6 col-lg-6">
                                                                    <i className="fa fa-lock"></i>
                                                                    <input type="password" name="password" {...formikPaswword.getFieldProps("password")} />
                                                                    <span>New Password</span>
                                                                    {formikPaswword.touched.password && formikPaswword.errors.password ? <div className="text-danger">{formikPaswword.errors.password}</div> : ''}
                                                                </div>
                                                                <div className="form-row col-md-6 col-lg-6">
                                                                    <i className="fa fa-lock"></i>
                                                                    <input type="password" name="passwordConfirmation" {...formikPaswword.getFieldProps("passwordConfirmation")} />
                                                                    <span>Confirm Password</span>
                                                                    {formikPaswword.touched.passwordConfirmation && formikPaswword.errors.passwordConfirmation ? <div className="text-danger">{formikPaswword.errors.passwordConfirmation}</div> : ''}
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-row">
                                                                        <button className="full" type="submit"><span>SUBMIT</span></button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </section>
                        <div className={show ? "modal display-block" : "modal display-none"} id="otp-model" tabIndex="-1" role="dialog" aria-labelledby="def" aria-hidden="true">
                            <div className="modal-dialog modal-sm" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title" id="myModalLabel2">Enter Your Phone Number</h4>
                                        <button type="button" className="close" onClick={() => setShow(false)} ><span aria-hidden="true" onClick={() => setShow(false)}>&times;</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <form className="login-page" onSubmit={formik.handleSubmit}>
                                            <div className="form-row phonecountry">
                                                <div className="select-input">
                                                    <select className="select-text" name="dial_code" value={formik.values.dial_code} onChange={(e) => formik.setFieldValue('dial_code', e.target.value)} >
                                                        <option className="option-margin"  >Select</option>
                                                        {country && country.map((option, index) => (
                                                            <option className="option-margin" value={option.id} key={index} >{option.text} </option>
                                                        ))}
                                                    </select>
                                                    {formik.touched.dial_code && formik.errors.dial_code ? <div className="text-danger">{formik.errors.dial_code}</div> : ''}
                                                    <label className="select-label">Select Country</label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <i className="fa fa-phone"></i>
                                                <input type="text" onChange={(e) => formik.setFieldValue('phone_num', e.target.value)} value={formik.values.phone_num} onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault() } }} />
                                                {formik.touched.phone_num && formik.errors.phone_num ? <div className="text-danger">{formik.errors.phone_num}</div> : ''}
                                                {formik.values.phone_num != '' && formik.values.valid_phone_num === false ? <div className="text-danger"> Please enter vaild phone number.</div> : ''}

                                            </div>

                                            <p className="mb-0 f-14">We need your phone number so we can give updates on your moves</p>

                                            <div className="form-row">
                                                <button className="full" type="submit"  ><span>Continue</span></button>
                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={showOtpModel ? "modal display-block" : "modal display-none"} id="otp-model" tabIndex="-1" role="dialog" aria-labelledby="def" aria-hidden="true">
                            <div className="modal-dialog modal-sm" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title" id="myModalLabel2">Enter OTP</h4>
                                        <button type="button" className="close" onClick={() => setshowOtpModel(false)} ><span aria-hidden="true" onClick={() => setshowOtpModel(false)}>&times;</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <form className="login-page" onSubmit={handleSubmit}>
                                            <p className="mb-0 f-14">Please enter the OTP sent on your mobile phone number.</p>
                                            <div className="form-row">
                                                <i className="fa fa-phone"></i>
                                                <input type="text" onChange={(e) => setOTP(e.target.value, formik.setFieldValue('vaild_otp', true))} value={OTP} />
                                                {formik.values.vaild_otp === false ? <div className="text-danger"> {OTP === '' ? 'Please enter OTP' : 'Please enter a vaild OTP.'}</div> : ''}
                                            </div>

                                            <div className="form-row">
                                                <button className="full" type="button" onClick={ValidateOtp} ><span>Continue</span></button>
                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    : ''}
            </main>
            <div ref={ref => recaptchaWrapperRef = ref}>

                <div id="recaptcha-container"></div>
            </div>

            <Footer loading={loading} />
        </>
    )
}
export default MyProfile;