
import Header from './layout/HeaderMoveitLicense';
import Footer from './layout/FooterMoveitLicense';
import Service from './../service';
import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import toast, { Toaster } from 'react-hot-toast';
import MetaHelmet from './metaHelmet'; //Include metaHelmet
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const api = new Service();
const MoveLicensingWhyUs = (props) => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [config, setconfig] = useState(props.config);

    useEffect(() => {
        document.title = "Move It Licensing";
        window.gtag('config', config.G_TAG, {
            page_title: document.title,
            page_path: window.location.pathname,
        });
    }, []);


    return (
        <>
            <MetaHelmet title="Move It Licensing" description="If you need help with anything or have questions / concerns please don't hesitate to ask. You can send us a message and also send an email." />
            <Toaster position="top-right" reverseOrder={false} />
            <Header></Header>
            <main>
                <section className="hero clean faq-andimg" style={{ backgroundImage: `url('web/img/05.jpg')` }}>
                    <article>
                        <div className="grid">
                            <div className="unit-2-3 unit-1-1-md">
                                <h1 className="h1 f-46">Why Us Licensing</h1>
                            </div>
                        </div>
                    </article>
                </section>
                <section className="work white mobile-paddtop whyusLicensing">
                    <article className="container">
                        <div className="row align-items-center d-flex align-center">
                            <div className="col-md-6">
                                <h6>
                                    Move It has become one of the fastest-growing technology platforms. Our award-
                                    winning app has changed how people get help.
                                    <br></br>
                                    Being recognized in the few markets we service, Move It is excited to go nationwide.
                                </h6>
                                <h6>
                                    With our innovative proprietary technology, we are revolutionizing how people can get help with small moves, getting items delivered, and just needing extra muscle.
                                </h6>
                                <h6>
                                    Partnering with Kathy Ireland Logistics and her multi-billion dollar brand, Move It is the preferred partner for all of Kathy Ireland’s pickup/delivery orders. Kathy Ireland is the 14 th most successful brand in the world.
                                </h6>
                                <h6>
                                    Move It has tentative agreements with major brands across the U.S. waiting to get into the markets they service.
                                </h6>
                                <h6>
                                    Move It licensing is expanding nationwide and even internationally to meet the
                                    demand that is needed across the United States and around the globe.
                                </h6>
                                <h6>
                                    Current license owners are seeing an amazing 18-30% ROI in the respective markets
                                    in a short time frame.
                                </h6>

                            </div>
                            <div className="col-md-6">
                                <img src="web/img/07.jpeg" alt="moveit" className="login-right-img" />
                            </div>
                            <div className="col-md-12 mt-5">

                                <h6>
                                    Move It will be a household name soon. Synonymous with Uber, Instacart, etc.
                                </h6>
                                <h6>
                                    If someone needs a ride, they think of Uber. If someone needs help getting that new
                                    purchase home or extra muscle lifting around the house, they will think of Move It.
                                </h6>
                                <h6>
                                    Move It and Move It Licensing is a huge opportunity for entrepreneurs and investors.
                                </h6>
                            </div>

                        </div>
                    </article>
                </section>

                <section className="bookconsutancy">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Book A Free Consultation</h2>
                                <p>We will be glad to answer your questions. We are looking forward to work with you.</p>
                                <Link to="/request-more-info" className="moreInfoBtn" style={{ background: "#ff95a2" }}>Request More Information</Link>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            {/* <Modal modal="ContactUsThankYou" show={serviceChargeModal} handleClose={hideServiceChargeModal}></Modal> */}

            <Footer loading={loading}></Footer>
        </>
    );
}

export default MoveLicensingWhyUs;
