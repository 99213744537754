import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import Service from './../../service'; 

const api = new Service(); 
const CustomerSideBar= props =>{
    
    const [countData, setcountData] = useState({});
    const [sideBar, setsideBar] = useState(false);
    useEffect(() => {
     
        if(props.countData>0) {
            getNotificationCount();
        } else if (props.countData==0) {
             
        } else {
           getNotificationCount();
        }
    
    }, [props.countData])  
    
    
    const getNotificationCount = () => {
        api.postApi('get-customer-notification-count',{}).then(response => {
            if(response) {
              setcountData(response);
            }
        }).catch(error => {
        });
    }
    
    const openMobileMeneSideBar = () => {
        setsideBar(true)
    }
    return (
        <>
    
         <div className="col-md-4 col-lg-3">
		    <div id="sticky-anchor"></div>
		    <Link to=""  id="filterfixed" className="filte-category" onClick={() => openMobileMeneSideBar() }>
            <i className="fas fa-bars"></i> Menu</Link>
			<div className="categoerybox-mnone">
             <div className="clearfix"></div>
               <div className="fixed-bar fl-wrap">
                 <div className="user-profile-menu-wrap fl-wrap block_box">
                    <div className="user-profile-menu">
                      <ul className="no-list-style">
                          <li><Link to="/my-moves"><i className="fas fa-truck-moving"></i> My Moves</Link></li>
                           <li><Link to="/my-profile"><i className="fas fa-edit"></i> Edit Profile</Link></li>
                          <li><Link to="/notification"><i className="far fa-bell"></i> Notifications</Link> {countData && countData.move_notification_count? <span className="badge n-badge">{countData.move_notification_count}</span> : '' }</li>
                          <li><Link to="/announcement" ><i className="fas fa-bullhorn fa-fw"></i> Announcement</Link>  {countData && countData.app_notification_count? <span className="badge n-badge">{countData.app_notification_count}</span> : '' }</li>
                          <li><Link to="/message"  ><i className="far fa-comments"></i> Message</Link>  {countData && countData.message_notification_count? <span className="badge n-badge">{countData.message_notification_count}</span> : '' }</li>
						  	<li><Link to="/customer-support"  ><i className="fas fa-headset"></i> Move It Customer Support</Link>   {countData && countData.admin_chat_count? <span className="badge n-badge">{countData.admin_chat_count}</span> : '' }</li>

						  <li><Link to="/report"  ><i className="fas fa-headset"></i> Report a problem</Link></li>
                      </ul>
                    </div>
                 </div>
               </div>
             <div className="clearfix"></div>
           </div>
		 </div>
		
		
          <div className={sideBar ? "modal left display-block" : "modal left display-none"} id="dashbaordmenu" tabIndex="-1" role="dialog" aria-labelledby="dashbaordmenu">
             <div className="modal-dialog" role="document">
                <div className="modal-content" style={{borderRadius: "0px"}}>
                   <div className="modal-header">
                      <h4 className="modal-title" id="myModalLabel">Menu</h4>
                      <button type="button" className="close" onClick={() => setsideBar(false) }>
                      <span aria-hidden="true" onClick={() => setsideBar(false)}>&times;</span></button>
                   </div>
                   <div className="modal-body">
                      <div className="clearfix"></div>
                      <div className="fixed-bar fl-wrap">
                         <div className="user-profile-menu-wrap fl-wrap block_box">
                            <div className="user-profile-menu">
                               <ul className="no-list-style"> 
								  <li><Link to="/my-moves"><i className="fas fa-truck-moving"></i> My Moves</Link></li>
                                   <li><Link to="/my-profile"><i className="fas fa-edit"></i> Edit Profile</Link></li>
                                  <li><Link to="/notification"><i className="far fa-bell"></i> Notifications</Link> {countData && countData.move_notification_count? <span className="badge n-badge">{countData.move_notification_count}</span> : '' }</li>
                                  <li><Link to="/announcement" ><i className="fas fa-bullhorn fa-fw"></i> Announcement</Link>  {countData && countData.app_notification_count? <span className="badge n-badge">{countData.app_notification_count}</span> : '' }</li>
                                  <li><Link to="/message"  ><i className="far fa-comments"></i> Message</Link>  {countData && countData.message_notification_count? <span className="badge n-badge">{countData.message_notification_count}</span> : '' }</li>
        						  	<li><Link to="/customer-support"  ><i className="fas fa-headset"></i> Move It Customer Support</Link>  
        						  	    {countData && countData.admin_chat_count? <span className="badge n-badge">{countData.admin_chat_count}</span> : '' }</li>
        
        						  <li><Link to="/report"  ><i className="fas fa-headset"></i> Report a problem</Link></li>
							  </ul>
                            </div>
                         </div>
                      </div>
                      <div className="clearfix"></div>
                   </div>
                </div>
             </div>
          </div>
        </>
    )
}
export default CustomerSideBar;

