import { Link, useParams } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import Service from './../../service';
import toast, { Toaster } from 'react-hot-toast';
import React, { useState, useEffect, useCallback } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useFormik } from 'formik';
import Modal from './../modal/modal';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MetaHelmet from './../metaHelmet'; //Include metaHelmet
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import SetupForm from './SetupForm';
import AutoComplete from './Autocomplete';
import moment from 'moment-timezone';
import Buttons from 'react-bootstrap-button-loader';
import ImageViewer from 'react-simple-image-viewer';
import alertConfirm, { Button } from "react-alert-confirm";
import { requestForToken } from './../firebaseNotifications/firebase';
import { firebase, auth } from './../firebase.js';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';


const google = window.google;
var markers = [];
function setMarker(e, map, id, setNewAddress, setNewAddressType) {

    // const icon = {
    //     url: id == 1? "https://dev.gomoveit.com/web/img/google-maps.svg"
    //     : "https://dev.gomoveit.com/web/img/map-pin1.svg",// url
    //     scaledSize: new google.maps.Size(100, 100), // scaled size
    //     origin: new google.maps.Point(0,0), // origin
    //     anchor: new google.maps.Point(0, 0) // anchor 
    // };
    var start = new google.maps.Marker({
        position: { lat: e.geometry.location.lat(), lng: e.geometry.location.lng() },
        map: map,
        title: e.formatted_address,
        draggable: true,
        // icon: icon,
        // animation: google.maps.Animation.DROP,
    });
    let infowindow = new google.maps.InfoWindow({
        content: e.formatted_address,
    });
    start.addListener("click", () => {
        infowindow.open({
            anchor: start,
            map: map,
            shouldFocus: false,
        });
    });
    start.id = id;
    for (let i = 0; i < markers.length; i++) {
        if (markers[i].id == id) {
            markers[i].setMap(null);
            markers.splice(i, 1);
        }
    }
    google.maps.event.addListener(start, "dragend", function (e) {
        setNewAddressType(start.id)
        handleEvent(e, setNewAddress)
    })
    markers.push(start)
    var bounds = new google.maps.LatLngBounds();

    for (var i = 0; i < markers.length; i++) {
        bounds.extend(markers[i].getPosition());
    }
    // map.setCenter(bounds.getCenter());
    map.fitBounds(bounds);
    var listener = google.maps.event.addListener(map, "idle", function () {
        if (map.getZoom() > 16) map.setZoom(16);
        google.maps.event.removeListener(listener);
    });


}
var directionsService = new google.maps.DirectionsService();
var directionsRenderer = new google.maps.DirectionsRenderer();
function setDirections(start, end, map) {
    if (start !== '' && end !== '') {

        directionsRenderer.setOptions({
            //  suppressPolylines: true, 
            preserveViewport: true,
            suppressMarkers: true
        });
        directionsRenderer.setMap(null);
        directionsRenderer.setMap(map);
        // directionsRenderer.setDirections(null);
        var request = {
            origin: start,
            destination: end,
            travelMode: 'DRIVING'
        };
        directionsService.route(request, function (response, status) {
            if (status == 'OK') {

                directionsRenderer.setDirections(response);
            }
        });
    } else {
        directionsRenderer.setMap(null);
    }
}

function handleEvent(marker, setNewAddress) {
    var geocoder = new google.maps.Geocoder();
    var longitude = marker.latLng.lng();
    var latitude = marker.latLng.lat();
    var latlng = { lat: parseFloat(latitude), lng: parseFloat(longitude) };

    geocoder.geocode({ 'location': latlng }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
            if (results.length > 0 && results[0] && results[0].place_id) {
                setNewAddress(results[0])
            } else {
                window.alert('No results found');
            }
        } else {
            window.alert('Geocoder failed due to: ' + status);
        }
    });
}

const api = new Service();

const BookMove = (props) => {
    const [config] = useState(props.config);
    const navigate = useNavigate();
    const { request_id } = useParams();
    const { draft_id } = useParams();
    const [loading, setloading] = useState(false);

    const [pickupAddressObject, setpickupAddressObject] = useState({});
    const [dropoffAddressObject, setdropoffAddressObject] = useState({});
    const [MoveTypeDetail, setMoveTypeDetail] = useState('');
    const [HelpingService, setHelpingService] = useState([]);
    const [DropoffService, setDropoffService] = useState([]);
    const [pickupStartTime, setpickupStartTime] = useState([]);
    const [pickupEndTime, setpickupEndTime] = useState([]);

    const [requiredHelper, setrequiredHelper] = useState(true);
    const [finalPrice, setfinalPrice] = useState(100);
    const [promocode, setpromocode] = useState('');
    const [isInsurance, setisInsurance] = useState(false);
    const [map, setmap] = useState('');
    const [charges, setcharges] = useState({ "distance_charges": "0", "pickup_stairs_charges": "0", "drop_off_stairs_charges": "0", "insurance_charge": "0", "helping_service_charges": "0", "total_item_price": "0", "total_service_charges": "0", "service_discount": "0", "total_amount": "0", "promocode_discount": "0", "one_hour_estimate_hour_price": 0, "estimate_hour_price": "0", "extra_estimate_hour_price": "0", "discount_amount": "0", "final_price": "0", "mile_charges": "0", "is_promocode_applied": 0, "remaining_estimate_hour": "0" });
    const [openTab, setopenTab] = useState(1);
    const [draftid, setDraftid] = useState('');

    const [serviceChargeModal, setserviceChargeModal] = useState(false);

    const hideServiceChargeModal = () => {
        setserviceChargeModal(false);
    };

    useEffect(() => {
        let userss = localStorage.getItem("login")
        if (userss == 0) {
            localStorage.setItem("login", 0)
        }
        else {
            let a = 1;
            localStorage.setItem("login", 1)
        }
    }, [])
    useEffect(() => {
        if (request_id !== undefined && request_id > 0) {
            getMoveDetailById()
        } else if (draft_id !== undefined && draft_id > 0) {
            getDraftDetailById()
        } else {
            setCurrentStep(0)
        }

    }, [])

    useEffect(() => {
        setFormSetps();
    }, [MoveTypeDetail, requiredHelper])

    const GetHelpingService = (move_type_id, helping_service_id = 0, is_upcoming = 0) => {
        api.getApi('get-helping-services', { move_type_id: move_type_id, helping_service_id, is_upcoming: is_upcoming }).then(response => {
            setHelpingService(response.data);
            GetDropoffService(move_type_id)
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const [data, setData] = useState({
        request_id: "",
        move_type_id: 0,
        pickup_date: "",
        pickup_start_time: "",
        is_draft: 1,
        pickup_end_time: "",
        pickup_address: "",
        pickup_address_type: 0,
        pickup_gate_code: "",
        pickup_lat: "",
        pickup_long: "",
        dropoff_address: "",
        dropoff_address_type: 0,
        dropoff_gate_code: "",
        dropoff_lat: "",
        dropoff_long: "",
        pickup_apartment: "",
        is_pickup_stairs: 0,
        pickup_stairs: 0,
        use_pickup_elevator: 0,
        dropoff_apartment: "",
        is_drop_off_stairs: 0,
        drop_off_stairs: 0,
        use_dropoff_elevator: 0,
        helping_service_id: 0,
        dropoff_service_id: 0,
        is_changed: 0,
        promocode: "",
        is_promocode_applied: 0,
        insurance_charge: 0,
        payment_method_nonce: "",
        radar_session_id:"",
        multi_use_payment_id: "",
        estimate_hour: 0,
        is_pickup_address_valid: 0,
        is_dropoff_address_valid: 0,
        is_assembly_muscle:0,
        items: []

    });

    const customer_ids = localStorage.getItem("customer_id")

    useEffect(() => {
        if (draftid > 0 && data.move_type_id && localStorage.getItem("is_login") > 0 || draft_id > 0 && data.move_type_id && localStorage.getItem("is_login") > 0) {
            api.postApi('customer-edit-draft', {
                user_id: customer_ids,
                user_type: 'C',
                request_id: draftid || draft_id,
                move_type_id: data.move_type_id,
                pickup_date: data.pickup_date,
                pickup_start_time: data.pickup_start_time,
                pickup_end_time: data.pickup_end_time,
                pickup_address: data.pickup_address,
                pickup_address_type: data.pickup_address_type,
                pickup_gate_code: data.pickup_gate_code,
                pickup_lat: data.pickup_lat,
                pickup_long: data.pickup_long,
                dropoff_address: data.dropoff_address,
                dropoff_address_type: data.dropoff_address_type,
                dropoff_gate_code: data.dropoff_gate_code,
                dropoff_lat: data.dropoff_lat,
                dropoff_long: data.dropoff_long,
                pickup_apartment: data.pickup_apartment,
                is_pickup_stairs: data.pickup_stairs,
                pickup_stairs: data.pickup_stairs,
                use_pickup_elevator: data.use_pickup_elevator,
                dropoff_apartment: data.dropoff_apartment,
                is_drop_off_stairs: data.drop_off_stairs,
                drop_off_stairs: data.drop_off_stairs,
                use_dropoff_elevator: data.use_dropoff_elevator,
                helping_service_id: data.helping_service_id,
                dropoff_service_id: data.dropoff_service_id,
                promocode: data.promocode,
                is_promocode_applied: data.is_promocode_applied,
                insurance_charge: data.insurance_charge,
                payment_method_nonce: data.payment_method_nonce,
                multi_use_payment_id: data.multi_use_payment_id,
                estimate_hour: data.estimate_hour,
                is_pickup_address_valid: data.is_pickup_address_valid,
                is_dropoff_address_valid: data.is_dropoff_address_valid,
                is_assembly_muscle:data.is_assembly_muscle,
                items: data.items
            }).then(response => {
            }).catch(error => {
                // console.log(error)
            })
        }
        else if (draft_id == undefined && request_id == undefined && localStorage.getItem("is_login") > 0) {
            api.postApi('save-move-draft', {
                user_id: customer_ids,
                user_type: 'C',
                request_id: data.request_id,
                move_type_id: data.move_type_id,
                pickup_date: data.pickup_date,
                pickup_start_time: data.pickup_start_time,
                pickup_end_time: data.pickup_end_time,
                pickup_address: data.pickup_address,
                pickup_address_type: data.pickup_address_type,
                pickup_gate_code: data.pickup_gate_code,
                pickup_lat: data.pickup_lat,
                pickup_long: data.pickup_long,
                dropoff_address: data.dropoff_address,
                dropoff_address_type: data.dropoff_address_type,
                dropoff_gate_code: data.dropoff_gate_code,
                dropoff_lat: data.dropoff_lat,
                dropoff_long: data.dropoff_long,
                pickup_apartment: data.pickup_apartment,
                is_pickup_stairs: data.pickup_stairs,
                pickup_stairs: data.pickup_stairs,
                use_pickup_elevator: data.use_pickup_elevator,
                dropoff_apartment: data.dropoff_apartment,
                is_drop_off_stairs: data.drop_off_stairs,
                drop_off_stairs: data.drop_off_stairs,
                use_dropoff_elevator: data.use_dropoff_elevator,
                helping_service_id: data.helping_service_id,
                dropoff_service_id: data.dropoff_service_id,
                promocode: data.promocode,
                is_promocode_applied: data.is_promocode_applied,
                insurance_charge: data.insurance_charge,
                payment_method_nonce: data.payment_method_nonce,
                multi_use_payment_id: data.multi_use_payment_id,
                estimate_hour: data.estimate_hour,
                is_pickup_address_valid: data.is_pickup_address_valid,
                is_dropoff_address_valid: data.is_dropoff_address_valid,
                is_assembly_muscle:data.is_assembly_muscle,
                items: data.items
            }).then(response => {
                // console.log(response + "::>>save-move-draft<<::");
                setDraftid(response.data.request_id);
                localStorage.setItem("draft_id", response.data.request_id);
            }).catch(error => {
                // console.log(error)
            })
        }
    }, [data])
    console.log(data);
    const GetDropoffService = (move_type_id) => {
        api.getApi('get-dropoff-services', { move_type_id: move_type_id }).then(response => {
            setDropoffService(response.data);
        }).catch(error => {
            toast.error(error.message);
        });

    }

    const getMoveDetailById = () => {
        setloading(true)
        api.postApi('get-customer-move-detail-by-id', { request_id: request_id, timezone: moment.tz.guess() }).then(response => {
            data.request_id = request_id;
            data.move_type_id = response.move_type_id;
            data.pickup_date = moment(response.pickup_date).format('yyyy-MM-DD');
            data.pickup_start_time = response.pickup_start_time;
            data.pickup_end_time = response.pickup_end_time;
            data.pickup_address = response.pickup_address;
            data.pickup_address_type = response.pickup_address_type;
            data.pickup_gate_code = response.pickup_gate_code;
            data.pickup_lat = response.pickup_lat;
            data.pickup_long = response.pickup_long;
            data.dropoff_address = response.dropoff_address;
            data.dropoff_address_type = response.dropoff_address_type;
            data.dropoff_gate_code = response.dropoff_gate_code;
            data.dropoff_lat = response.dropoff_lat;
            data.dropoff_long = response.dropoff_long;
            data.pickup_apartment = response.pickup_apartment;
            data.is_pickup_stairs = response.pickup_stairs > 0 ? 1 : 0;
            data.pickup_stairs = response.pickup_stairs > 0 ? response.pickup_stairs : 0;
            data.use_pickup_elevator = response.use_pickup_elevator;
            data.dropoff_apartment = response.dropoff_apartment;
            data.is_drop_off_stairs = response.drop_off_stairs > 0 ? 1 : 0;
            data.drop_off_stairs = response.drop_off_stairs > 0 ? response.drop_off_stairs : 0;
            data.use_dropoff_elevator = response.use_dropoff_elevator;
            data.helping_service_id = response.helping_service_id;
            data.dropoff_service_id = response.dropoff_service_id;
            data.promocode = response.promocode;
            data.is_promocode_applied = response.is_promocode_applied;
            data.insurance_charge = response.insurance_charge;
            data.payment_method_nonce = response.payment_method_nonce;
            data.multi_use_payment_id = response.multi_use_payment_id;
            data.estimate_hour = response.estimate_hour;
            data.is_assembly_muscle = response.is_assembly_muscle? response.is_assembly_muscle:0;
            data.items = response.items;
            setData(data);
            calculateCharges();
            if (response.insurance_charge > 0) {
                setisInsurance(true);
            }

            if (response.required_pros == 0 && response.required_muscle == 0) {
                setrequiredHelper(false)
                api.getApi('get-move-type-by-id', { move_type_id: response.move_type_id }).then(move_response => {
                    GetHelpingService(response.move_type_id, response.helping_service_id, 1)
                    setMoveTypeDetail(move_response.data);
                    setloading(false)
                    setCurrentStep(0)
                }).catch(error => {
                    toast.error(error.message);
                    setloading(false);
                });
            } else {
                setCurrentStep(0)
                setloading(false)
            }
        }).catch(error => {
            setloading(false)
            toast.error(error.message);
        });
    }

    const getDraftDetailById = () => {
        setloading(true)
        api.postApi('get-customer-draft-by-id', { request_id: draft_id, timezone: moment.tz.guess() }).then(response => {
            data.request_id = draft_id;
            data.move_type_id = response.draft_data.move_type_id;
            data.pickup_date = moment(response.draft_data.pickup_date).format('yyyy-MM-DD');
            data.pickup_start_time = response.draft_data.pickup_start_time;
            data.pickup_end_time = response.draft_data.pickup_end_time;
            data.pickup_address = response.draft_data.pickup_address;
            data.pickup_address_type = response.draft_data.pickup_address_type;
            data.pickup_gate_code = response.draft_data.pickup_gate_code;
            data.pickup_lat = response.draft_data.pickup_lat;
            data.pickup_long = response.draft_data.pickup_long;
            data.dropoff_address = response.draft_data.dropoff_address;
            data.dropoff_address_type = response.draft_data.dropoff_address_type;
            data.dropoff_gate_code = response.draft_data.dropoff_gate_code;
            data.dropoff_lat = response.draft_data.dropoff_lat;
            data.dropoff_long = response.draft_data.dropoff_long;
            data.pickup_apartment = response.draft_data.pickup_apartment;
            data.is_pickup_stairs = response.draft_data.pickup_stairs > 0 ? 1 : 0;
            data.is_drop_off_stairs = response.draft_data.drop_off_stairs > 0 ? 1 : 0;
            // data.is_pickup_stairs = response.draft_data.is_pickup_stairs ;
            data.pickup_stairs = response.draft_data.pickup_stairs;
            // data.pickup_stairs = response.draft_data.pickup_stairs > 0 ? response.draft_data.pickup_stairs : 0;
            data.use_pickup_elevator = response.draft_data.use_pickup_elevator;
            data.dropoff_apartment = response.draft_data.dropoff_apartment;
            data.drop_off_stairs = response.draft_data.drop_off_stairs;
            // data.drop_off_stairs = response.draft_data.drop_off_stairs > 0 ? response.draft_data.drop_off_stairs : 0;
            data.use_dropoff_elevator = response.draft_data.use_dropoff_elevator;
            data.helping_service_id = response.draft_data.helping_service_id;
            data.dropoff_service_id = response.draft_data.dropoff_service_id;
            data.promocode = response.draft_data.promocode;
            data.is_promocode_applied = response.draft_data.is_promocode_applied;
            data.insurance_charge = response.draft_data.insurance_charge;
            data.payment_method_nonce = response.payment_method_nonce;
            data.multi_use_payment_id = response.draft_data.multi_use_payment_id;
            data.estimate_hour = response.draft_data.estimate_hour;
            data.items = response.draft_data.items;
            data.is_assembly_muscle =  response.draft_data.is_assembly_muscle;
            // setDemo(response.draft_data.move_type_id)
            // console.log(response, "edit by id")
            setData(data);
            // calculateCharges();
            setCurrentStep(0);
        }).catch(error => {
            setloading(false)
            toast.error(error.message);
        });
    }

    const [currentStep, setCurrentStep] = useState('');

    const makeRequest = (values) => {
        setloading(true)
        if (request_id > 0) {
            api.postApi('customer-edit-move', values).then(response => {
                setloading(false)
                if(response && response.status!=undefined && response.status===false) {
                    toast.error(response.message);
                } else {
                    toast.success(response.message);
                    navigate('/my-moves')
                }
            }).catch(error => {
                setloading(false)
                toast.error(error.message);
            });

        } else {
            api.postApi('book-move', {
                request_id: draftid > 0 ? draftid : draft_id,
                move_type_id: data.move_type_id,
                is_draft: 1,
                pickup_date: data.pickup_date,
                pickup_start_time: data.pickup_start_time,
                pickup_end_time: data.pickup_end_time,
                pickup_address: data.pickup_address,
                pickup_address_type: data.pickup_address_type,
                pickup_gate_code: data.pickup_gate_code,
                pickup_lat: data.pickup_lat,
                pickup_long: data.pickup_long,
                dropoff_address: data.dropoff_address,
                dropoff_address_type: data.dropoff_address_type,
                dropoff_gate_code: data.dropoff_gate_code,
                dropoff_lat: data.dropoff_lat,
                dropoff_long: data.dropoff_long,
                pickup_apartment: data.pickup_apartment,
                is_pickup_stairs: data.pickup_stairs,
                pickup_stairs: data.pickup_stairs,
                use_pickup_elevator: data.use_pickup_elevator,
                dropoff_apartment: data.dropoff_apartment,
                is_drop_off_stairs: data.drop_off_stairs,
                drop_off_stairs: data.drop_off_stairs,
                use_dropoff_elevator: data.use_dropoff_elevator,
                helping_service_id: data.helping_service_id,
                dropoff_service_id: data.dropoff_service_id,
                promocode: data.promocode,
                is_promocode_applied: data.is_promocode_applied,
                insurance_charge: data.insurance_charge,
                payment_method_nonce: data.payment_method_nonce,
                multi_use_payment_id: data.multi_use_payment_id,
                estimate_hour: data.estimate_hour,
                is_pickup_address_valid: data.is_pickup_address_valid,
                is_dropoff_address_valid: data.is_dropoff_address_valid,
                radar_session_id: data.radar_session_id,
                is_assembly_muscle:data.is_assembly_muscle,
                items: data.items
            }).then(response => {
                setloading(false)
                toast.success(response.message);
                navigate('/my-moves', { state: { tab: 'tab1' } })
            }).catch(error => {
                setloading(false)
                toast.error(error.message);
            });
        }
    };

    const handleNextStep = (newData, final = false) => {
        setData((prev) => ({ ...prev, is_draft: 1, ...newData }));
        if (final) {
            makeRequest(newData);
            return;
        }
        setCurrentStep((prev) => prev + 1);
    };

    const handlePrevStep = (newData) => {

        setData((prev) => ({ ...prev, ...newData }));
        setCurrentStep((prev) => prev - 1);
    };

    const updatePromocode = (e) => {
        setpromocode(e.target.value);
        data.promocode = e.target.value;
        setData(data);
    }

    const updateInsuranceValue = (e) => {
        if (e.target.checked) {
            setisInsurance(true)
            data.insurance_charge = MoveTypeDetail.insurance_value;
            setData(data);
            calculateCharges();
        } else {
            setisInsurance(false)
            data.insurance_charge = 0;
            setData(data);
            calculateCharges();
        }
    }

    const updateAssemblyForMuscle = (e) => {
        if (e.target.checked) {
            // setisInsurance(true)
            data.is_assembly_muscle = 1;
            setData(data);
            calculateCharges();
        } else {
            // setisInsurance(false)
            data.is_assembly_muscle = 0;
            setData(data);
            calculateCharges();
        }
    }

    const applyPromoCode = () => {
        if (promocode) {
            api.postApi('validate-promocode', { promocode: data.promocode }).then(response => {
                data.is_promocode_applied = 1;
                setData(data);
                calculateCharges();
                toast.success('Promocode applied successfully.');
            }).catch(error => {
                removePromoCode();
                toast.error(error.message);
            });
        } else {
            toast.error('The promocode field is required.');
        }
    }

    const removePromoCode = () => {
        setpromocode('');
        data.is_promocode_applied = 0;
        data.promocode = "";
        setData(data);
        calculateCharges();
    }

    const calculateCharges = () => {
        setloading(true)
        console.log('calculate-charges',data);
        api.postApi('calculate-charges', data).then(response => {
            setcharges(response.move_charges);
            data.is_promocode_applied = response.move_charges.is_promocode_applied;
            setData(data);
            setfinalPrice(response.move_charges.final_price)
            setloading(false)
        }).catch(error => {
            setloading(false)
            toast.error(error.message);
        });
    }

    const checkValidation = (errorShow = 1) => {
        if (data.items.length < 1) {
            toast.error('Please add at least one item.');
            setopenTab(1)
        } else if (data.helping_service_id == '') {
            toast.error('Please select helping service.');
            setopenTab(2)
        } else if (data.pickup_date == '') {
            toast.error('Please select pickup date.');
            setopenTab(3)
        } else if (data.pickup_start_time == '') {
            toast.error('Please select pickup start time.');
            setopenTab(3)
        } else if (data.pickup_end_time == '') {
            toast.error('Please select pickup end time.');
            setopenTab(3)
        } else if (data.dropoff_service_id == '') {
            toast.error('Please select Drop off Service.');
            setopenTab(4)
        } else {
            handleNextStep(data, false);
        }
        const section = document.querySelector('#rd' + openTab);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    const finalSubmit = () => {
        if (!window.location.pathname.includes("book-moves/draft/")) {
            if (data.request_id > 0) {
                alertConfirm({
                    title: "",
                    content: "Move It job has been edited Please confirm price change",
                    style: { position: "fixed" },
                    className: "setconfirm",
                    footer(dispatch) {
                        return (
                            <>
                                <Button className="yesnobtn" onClick={() => dispatch('ok')}><span>Yes</span></Button>
                                <Button className="yesnobtn-no" onClick={() => dispatch('no')} styleType="primary">
                                    <span>NO</span>
                                </Button>
                            </>
                        );
                    },
                    async closeBefore(action, close) {
                        if (action == 'no') {
                            close();

                        } else {
                            handleNextStep(data, true);
                            close();
                        }
                    }
                });
            } else {
                handleNextStep(data, false);
            }
        }
        else {
            handleNextStep(data)
        }
    }

    const setCurrentLocation = (setNewAddress, setNewAddressType) => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                let new_map = new google.maps.Map(document.getElementById("map_canvas"), {
                    zoom: 7,
                    center: { lat: position.coords.latitude, lng: position.coords.longitude },
                });
                setmap(new_map)
                if (data.pickup_address && pickupAddressObject) {
                    setMarker(pickupAddressObject, new_map, 1, setNewAddress, setNewAddressType)
                }
                if (data.dropoff_address && dropoffAddressObject) {
                    setMarker(dropoffAddressObject, new_map, 2, setNewAddress, setNewAddressType)
                    setDirections({ lat: pickupAddressObject.geometry.location.lat(), lng: pickupAddressObject.geometry.location.lng() }, { lat: dropoffAddressObject.geometry.location.lat(), lng: dropoffAddressObject.geometry.location.lng() }, map)
                }

            });
        }
    }

    let steps = [];
    const setFormSetps = () => {
        steps = [];
        if (requiredHelper) {
            steps.push(<Step next={handleNextStep} data={data} setData={setData} setMoveTypeDetail={setMoveTypeDetail} setloading={setloading} GetHelpingService={GetHelpingService} />)
            steps.push(<StepOne next={handleNextStep} prev={handlePrevStep} setCurrentLocation={setCurrentLocation} map={map} data={data} MoveTypeDetail={MoveTypeDetail} setpickupAddressObject={setpickupAddressObject} pickupAddressObject={pickupAddressObject} setdropoffAddressObject={setdropoffAddressObject} dropoffAddressObject={dropoffAddressObject} />)
        }
        steps.push(<StepTwo next={handleNextStep} prev={handlePrevStep} data={data} MoveTypeDetail={MoveTypeDetail} />)
        if (MoveTypeDetail.can_add_item == 0) {
            if (data.estimate_hour < 1) {
                data.estimate_hour += 1;
                setData(data)
            }
            steps.push(<StepFour next={handleNextStep} prev={handlePrevStep} data={data} MoveTypeDetail={MoveTypeDetail} />)
        }
        steps.push(<StepFive config={config} next={handleNextStep} prev={handlePrevStep} data={data} setData={setData} MoveTypeDetail={MoveTypeDetail}
            HelpingService={HelpingService} DropoffService={DropoffService} pickupStartTime={pickupStartTime} setpickupStartTime={setpickupStartTime} pickupEndTime={pickupEndTime} setpickupEndTime={setpickupEndTime} finalPrice={finalPrice} setfinalPrice={setfinalPrice} setcharges={setcharges} charges={charges} calculateCharges={calculateCharges} setopenTab={setopenTab} openTab={openTab} checkValidation={checkValidation} />)
        steps.push(<StepSix next={handleNextStep} prev={handlePrevStep} data={data} MoveTypeDetail={MoveTypeDetail} setloading={setloading} loading={loading}
            HelpingService={HelpingService} DropoffService={DropoffService} finalPrice={finalPrice} setserviceChargeModal={setserviceChargeModal} charges={charges} updatePromocode={updatePromocode} updateAssemblyForMuscle={updateAssemblyForMuscle} updateInsuranceValue={updateInsuranceValue} applyPromoCode={applyPromoCode} removePromoCode={removePromoCode} calculateCharges={calculateCharges} isInsurance={isInsurance} finalSubmit={finalSubmit} />)
        if (request_id == undefined) {
            steps.push(<StepZero config={config} next={handleNextStep} prev={handlePrevStep} data={data} MoveTypeDetail={MoveTypeDetail} setloading={setloading} loading={loading}
                HelpingService={HelpingService} DropoffService={DropoffService} finalPrice={finalPrice} />)
            steps.push(<StepSeven next={handleNextStep} prev={handlePrevStep} data={data} MoveTypeDetail={MoveTypeDetail} setloading={setloading} loading={loading}
                HelpingService={HelpingService} DropoffService={DropoffService} finalPrice={finalPrice} />)
            steps.push(<StepEight config={config} next={handleNextStep} prev={handlePrevStep} data={data} MoveTypeDetail={MoveTypeDetail} setloading={setloading} loading={loading}
                HelpingService={HelpingService} DropoffService={DropoffService} finalPrice={finalPrice} />)
        }
        // if (draft_id == undefined) {
        //     steps.push(<StepSeven next={handleNextStep} prev={handlePrevStep} data={data} MoveTypeDetail={MoveTypeDetail} setloading={setloading} loading={loading}
        //         HelpingService={HelpingService} DropoffService={DropoffService} finalPrice={finalPrice} />)
        //     steps.push(<StepEight config={config} next={handleNextStep} prev={handlePrevStep} data={data} MoveTypeDetail={MoveTypeDetail} setloading={setloading} loading={loading}
        //         HelpingService={HelpingService} DropoffService={DropoffService} finalPrice={finalPrice} />)
        // }
    }

    if (requiredHelper) {
        steps.push(<Step next={handleNextStep} data={data} setData={setData} setMoveTypeDetail={setMoveTypeDetail} setloading={setloading} GetHelpingService={GetHelpingService} />)
        steps.push(<StepOne next={handleNextStep} prev={handlePrevStep} setCurrentLocation={setCurrentLocation} map={map} data={data} MoveTypeDetail={MoveTypeDetail} setpickupAddressObject={setpickupAddressObject} pickupAddressObject={pickupAddressObject} setdropoffAddressObject={setdropoffAddressObject} dropoffAddressObject={dropoffAddressObject} />)
    }

    steps.push(<StepTwo next={handleNextStep} prev={handlePrevStep} data={data} MoveTypeDetail={MoveTypeDetail} />)
    if (MoveTypeDetail.can_add_item == 0) {
        if (data.estimate_hour < 1) {
            data.estimate_hour += 1;
            setData(data)
        }
        steps.push(<StepFour next={handleNextStep} prev={handlePrevStep} data={data} MoveTypeDetail={MoveTypeDetail} />)
    }

    steps.push(<StepFive config={config} next={handleNextStep} prev={handlePrevStep} data={data} setData={setData} MoveTypeDetail={MoveTypeDetail}
        HelpingService={HelpingService} DropoffService={DropoffService} pickupStartTime={pickupStartTime} setpickupStartTime={setpickupStartTime} pickupEndTime={pickupEndTime} setpickupEndTime={setpickupEndTime} finalPrice={finalPrice} setfinalPrice={setfinalPrice} setcharges={setcharges} charges={charges} calculateCharges={calculateCharges} setopenTab={setopenTab} openTab={openTab} checkValidation={checkValidation} />)
    steps.push(<StepSix next={handleNextStep} prev={handlePrevStep} data={data} MoveTypeDetail={MoveTypeDetail} setloading={setloading} loading={loading}
        HelpingService={HelpingService} DropoffService={DropoffService} finalPrice={finalPrice} setserviceChargeModal={setserviceChargeModal} charges={charges} updatePromocode={updatePromocode} updateInsuranceValue={updateInsuranceValue} updateAssemblyForMuscle={updateAssemblyForMuscle} applyPromoCode={applyPromoCode} removePromoCode={removePromoCode} calculateCharges={calculateCharges} isInsurance={isInsurance} finalSubmit={finalSubmit} />)
    if (request_id == undefined) {
        steps.push(<StepZero config={config} next={handleNextStep} prev={handlePrevStep} data={data} MoveTypeDetail={MoveTypeDetail} setloading={setloading} loading={loading}
            HelpingService={HelpingService} DropoffService={DropoffService} finalPrice={finalPrice} />)
        steps.push(<StepSeven next={handleNextStep} prev={handlePrevStep} data={data} MoveTypeDetail={MoveTypeDetail} setloading={setloading} loading={loading}
            HelpingService={HelpingService} DropoffService={DropoffService} finalPrice={finalPrice} />)
        steps.push(<StepEight config={config} next={handleNextStep} prev={handlePrevStep} data={data} MoveTypeDetail={MoveTypeDetail} setloading={setloading} loading={loading}
            HelpingService={HelpingService} DropoffService={DropoffService} finalPrice={finalPrice} setCurrentStep={setCurrentStep} />)
    }

    // for change header link when guest login done
    const [headers, setHeaders] = useState('My Profile')
    useEffect(() => {
        if (localStorage.getItem("login") == 1) {
            setHeaders('My Profile')
        }
        else {
            setHeaders('Log In')
            // console.log("running.....")
        }
    }, [currentStep])

    // for click on browser back button than back by steps
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
        if (currentStep == 0 && localStorage.getItem("login") == 0) {
            navigate('/login')
        } else if (currentStep == 0 && localStorage.getItem("login") == 1) {
            navigate('/my-moves')
        }
        else {
            // window.history.pushState(null, "on browser back click", setCurrentStep((prev) => prev - 1));
            navigate(setCurrentStep((prev) => prev - 1))
        }
    };

    return (

        <>
            <Toaster position="top-right" reverseOrder={false} />
            <MetaHelmet title="Move It | Book Move" description="" />
            <Header headers={headers}></Header>
            <main>
                <div className="ugf-wrapper">
                    <div className="container ufg-job-application-wrapper" style={{ paddingTop: "53px" }}>
                        <div className="row d-flex justify-content-center">
                            {currentStep > 0 || currentStep < 4 ?
                                <div className="col-md-12">
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                            <span className="step-text">
                                                {/* <span className="current-step">{currentStep + 1 == 7 ? 6 : currentStep + 1 == 8 ? 7 : currentStep + 1}</span> of
                                                <span className="total-step"></span> {steps.length == 7 ? 6 : steps.length == 8 ? 7 : steps.length} Step</span> <<<<::original::>>>> */}

                                                {/* <span className="current-step">{currentStep + 1 == 7 ? 6 : currentStep + 1 == 8 ? 6 : currentStep + 1 == 9 ? 7 : currentStep + 1}</span> of
                                                <span className="total-step"></span> {steps.length == 8 ? 6 : steps.length == 9 ? 7 : steps.length} Step <<<<::i created::>>>> */}
                                                <span className="current-step">{currentStep + 1 == 7 ? 6 : currentStep + 1 == 8 ? 6 : currentStep + 1 == 9 ? 7 : currentStep + 1}</span> of
                                                <span className="total-step"></span> {steps.length == 8 ? 6 : steps.length == 9 ? 7 : steps.length} Step                                                </span>
                                        </div>
                                    </div>
                                </div>
                                :
                                ''
                            }

                            <div className="App">
                                <>
                                    {steps[currentStep]}
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer loading={loading}></Footer>
            <Modal modal="SERVICE-CHARGE" show={serviceChargeModal} handleClose={hideServiceChargeModal} charges={charges} MoveTypeDetail={MoveTypeDetail} ></Modal>
        </>
    );
}

const Step = (props) => {
    const [MoveType, setMoveType] = useState([]);
    const customer_ids = localStorage.getItem('customer_ids');
    const { request_id } = useParams();


    useEffect(() => {
        GetMoveType();
    }, [])

    const GetMoveType = () => {
        props.setloading(true);
        api.getApi('get-all-move-type').then(response => {
            setMoveType(response.move_type);
            props.setloading(false);
        }).catch(error => {
            props.setloading(false);
            toast.error(error.message);
        });
    }

    const confirmChangeMoveType = async (e) => {

        if (props.data.move_type_id > 0 && props.data.move_type_id !== e.target.value) {
            e.preventDefault();
            alertConfirm({
                title: "",
                content: props.data.move_type_id == e.target.value ? "Continue with this move type?" : "Are you sure you want to change the move type?",
                style: { position: "fixed" },
                className: "setconfirm",
                footer(dispatch) {
                    return (
                        <>
                            <Button className="yesnobtn" onClick={() => dispatch('ok')}><span>Yes</span></Button>
                            <Button className="yesnobtn-no" onClick={() => dispatch('no')} styleType="primary">
                                <span>NO</span>
                            </Button>
                        </>
                    );
                },
                async closeBefore(action, close) {
                    if (action == 'no') {
                        close();
                    }
                    else {
                        updateMoveType(e)
                        close();
                    }
                }
            });
        }
        else {
            updateMoveType(e)
        }
    }

    const updateMoveType = e => {
        props.setloading(true);
        let data;
        if (props.data.move_type_id == e.target.value) {
            data = {
                request_id: props.data.request_id,
                move_type_id: e.target.value,
                pickup_date: request_id > 0 ? props.data.pickup_date : "",
                pickup_start_time: request_id > 0 ? props.data.pickup_start_time : "",
                pickup_end_time: request_id > 0 ? props.data.pickup_end_time : "",
                pickup_address: props.data.pickup_address,
                pickup_address_type: props.data.pickup_address_type,
                pickup_gate_code: props.data.pickup_gate_code,
                pickup_lat: props.data.pickup_lat,
                pickup_long: props.data.pickup_long,
                dropoff_address: props.data.dropoff_address,
                dropoff_address_type: props.data.dropoff_address_type,
                dropoff_gate_code: props.data.dropoff_gate_code,
                dropoff_lat: props.data.dropoff_lat,
                dropoff_long: props.data.dropoff_long,
                pickup_apartment: props.data.pickup_apartment,
                is_pickup_stairs: props.data.is_pickup_stairs > 0 ? 1 : 0,
                is_drop_off_stairs: props.data.is_drop_off_stairs > 0 ? 1 : 0,
                // is_pickup_stairs: props.data.pickup_stairs,
                pickup_stairs: props.data.pickup_stairs,
                // pickup_stairs: props.data.pickup_stairs> 0 ? props.data.pickup_stairs : 0,
                use_pickup_elevator: props.data.use_pickup_elevator,
                dropoff_apartment: props.data.dropoff_apartment,
                // is_drop_off_stairs: props.data.drop_off_stairs,
                // drop_off_stairs: props.data.drop_off_stairs> 0 ? props.data.drop_off_stairs : 0,
                drop_off_stairs: props.data.drop_off_stairs,
                use_dropoff_elevator: props.data.use_dropoff_elevator,
                helping_service_id: props.data.helping_service_id,
                dropoff_service_id: props.data.dropoff_service_id,
                promocode: props.data.promocode,
                is_promocode_applied: props.data.is_promocode_applied,
                insurance_charge: props.data.insurance_charge,
                payment_method_nonce: "",
                multi_use_payment_id: "",
                estimate_hour: props.data.estimate_hour,
                is_pickup_address_valid: props.data.is_pickup_address_valid,
                is_dropoff_address_valid: props.data.is_dropoff_address_valid,
                is_assembly_muscle: props.data.is_assembly_muscle,
                items: props.data.items
            }
            props.setData(data);
        } else if (props.data.move_type_id !== e.target.value) {
            data = {
                request_id: props.data.request_id,
                move_type_id: e.target.value,
                pickup_date: "",
                pickup_start_time: "",
                pickup_end_time: "",
                pickup_address: "",
                pickup_address_type: 0,
                pickup_gate_code: "",
                pickup_lat: "",
                pickup_long: "",
                dropoff_address: "",
                dropoff_address_type: 0,
                dropoff_gate_code: "",
                dropoff_lat: "",
                dropoff_long: "",
                pickup_apartment: "",
                is_pickup_stairs: 0,
                is_drop_off_stairs: 0,
                // is_pickup_stairs: props.data.pickup_stairs,
                pickup_stairs: 0,
                // pickup_stairs: props.data.pickup_stairs> 0 ? props.data.pickup_stairs : 0,
                use_pickup_elevator: 0,
                dropoff_apartment: "",
                // is_drop_off_stairs: props.data.drop_off_stairs,
                // drop_off_stairs: props.data.drop_off_stairs> 0 ? props.data.drop_off_stairs : 0,
                drop_off_stairs: 0,
                use_dropoff_elevator: 0,
                helping_service_id: 0,
                dropoff_service_id: 0,
                promocode: "",
                is_promocode_applied: 0,
                insurance_charge: 0,
                payment_method_nonce: "",
                multi_use_payment_id: "",
                estimate_hour: 0,
                is_pickup_address_valid: 0,
                is_dropoff_address_valid: 0,
                is_assembly_muscle:0,
                items: [],

            }
            props.setData(data);
        }
        // else {
        //     props.data.move_type_id = e.target.value;
        //     data = props.data;
        // }
        props.GetHelpingService(e.target.value);
        api.getApi('get-move-type-by-id', { move_type_id: e.target.value }).then(response => {
            props.setMoveTypeDetail(response.data);
            props.next(data);
            props.setloading(false);
        }).catch(error => {
            toast.error(error.message);
            props.setloading(false);
        });
    }

    return (
        <div className="col-lg-12">
            <div className="ptop-133 mobile-boomove">
                <div className="form-steps" style={{ display: "block" }} >
                    <h3 className="border-bottom">Select Move Type</h3>
                    <form action="#" className="job-application-form" >
                        <div className="row stepone-2">
                            {MoveType.length > 0 && MoveType.map((option, index) => (
                                <div className="col-6 col-sm-4 col-md-3 col-lg-3 mb-2" key={index} >
                                    <input className="checkbox-tools" type="radio" name="move_type_id" id={'move_typeS' + option.id} value={option.id} onClick={confirmChangeMoveType} checked={parseInt(option.id) == parseInt(props.data.move_type_id)} readOnly />
                                    <label className="for-checkbox-tools" htmlFor={'move_typeS' + option.id} >
                                        <div className="cardbox">
                                            <img src={option.image_url} alt="" />
                                            <h5>{option.name}</h5>
                                            <p>{option.description}</p>
                                        </div>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </form>

                </div>
            </div>
        </div>

    );
}

const StepOne = (props) => {
    // console.log(props.data)
    const [validPickupAddress, setvalidPickupAddress] = useState(false);
    const [validDropoffAddress, setvalidDropoffAddress] = useState(false);
    const [errorMessage, seterrorMessage] = useState("");
    const [newAddress, setNewAddress] = useState({});
    const [newAddressType, setNewAddressType] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        props.setCurrentLocation(setNewAddress, setNewAddressType);
    }, [props.data])

    useEffect(() => {
        if (newAddressType == 1) {
            setPickupAddress(newAddress);
        } else if (newAddressType == 2) {
            setDropoffAddress(newAddress);
        }
    }, [newAddress])

    const oneStepform = useFormik({
        initialValues: props.data,
        validationSchema: yup.object({
            pickup_address: yup.string().required('Pickup Address is required.'),
            pickup_lat: yup.string(),
            pickup_long: yup.string(),
            dropoff_address: props.MoveTypeDetail.is_dropoff_address == 1 ? yup.string().required('Dropoff  Address is required.') : "",
            dropoff_lat: yup.string(),
            dropoff_long: yup.string()
        }),
        onSubmit: values => {
            if (validPickupAddress == false && validDropoffAddress == false) {
                props.next(values);
            }
        }
    });

    const checkServiceAvailable = (pickup_lat, pickup_long, dropoff_lat, dropoff_long) => {
        api.postApi('check-service-available-by-lat-lng', { pickup_lat: pickup_lat, pickup_long: pickup_long, dropoff_lat: dropoff_lat, dropoff_long: dropoff_long }).then(response => {
            if (response.is_pickup_address_valid == false) {
                setvalidDropoffAddress(false)
                setvalidPickupAddress(true)
                // console.log("location one")
                seterrorMessage(response.message)
            } else if (response.is_dropoff_address_valid == false) {
                setvalidPickupAddress(false)
                setvalidDropoffAddress(true)
                // console.log("location two")

                seterrorMessage(response.message)
            } else {
                setvalidPickupAddress(false)
                setvalidDropoffAddress(false)
                // console.log("location three")

                seterrorMessage("")
            }
        }).catch(error => {

        });
    }

    const setPickupAddress = e => {
        oneStepform.setFieldValue('pickup_address', e.formatted_address);
        oneStepform.setFieldValue('pickup_lat', e.geometry.location.lat());
        oneStepform.setFieldValue('pickup_long', e.geometry.location.lng());

        if (props.MoveTypeDetail.is_dropoff_address == 0) {
            oneStepform.setFieldValue('dropoff_address', e.formatted_address);
            oneStepform.setFieldValue('dropoff_lat', e.geometry.location.lat());
            oneStepform.setFieldValue('dropoff_long', e.geometry.location.lng(), checkServiceAvailable(e.geometry.location.lat(), e.geometry.location.lng(), e.geometry.location.lat(), e.geometry.location.lng()));
        } else {
            checkServiceAvailable(e.geometry.location.lat(), e.geometry.location.lng(), oneStepform.values.dropoff_lat, oneStepform.values.dropoff_long)
            setDirections({ lat: e.geometry.location.lat(), lng: e.geometry.location.lng() },
                { lat: oneStepform.values.dropoff_lat, lng: oneStepform.values.dropoff_long }, props.map)
        }
        props.setpickupAddressObject(e)
        setMarker(e, props.map, 1, setNewAddress, setNewAddressType)
    }

    const setDropoffAddress = e => {
        oneStepform.setFieldValue('dropoff_address', e.formatted_address);
        oneStepform.setFieldValue('dropoff_lat', e.geometry.location.lat());
        oneStepform.setFieldValue('dropoff_long', e.geometry.location.lng(), checkServiceAvailable(oneStepform.values.pickup_lat, oneStepform.values.pickup_long, e.geometry.location.lat(), e.geometry.location.lng()));
        props.setdropoffAddressObject(e)
        setMarker(e, props.map, 2, setNewAddress, setNewAddressType)
        setDirections({ lat: oneStepform.values.pickup_lat, lng: oneStepform.values.pickup_long }, { lat: e.geometry.location.lat(), lng: e.geometry.location.lng() }, props.map)
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <div className="ptop-133">
                        <div className="form-steps" style={{ display: "block" }}>
                            <h3 className="border-bottom">Select Location</h3>
                            <form className="job-application-form login-page row" onSubmit={oneStepform.handleSubmit}>
                                {props.MoveTypeDetail.is_pickup_address == 1 ? (
                                    <div className="form-row col-md-12 col-lg-12">
                                        <i className="fas fa-location-dot"></i>
                                        <AutoComplete addplace={setPickupAddress} value={oneStepform.values.pickup_address} />
                                        <span>Pickup Address</span>
                                        {oneStepform.touched.pickup_address && oneStepform.errors.pickup_address ? <div className="text-danger">{oneStepform.errors.pickup_address}</div> : ''}
                                        {validPickupAddress == true ? <div className="text-danger">{errorMessage}</div> : ''}
                                    </div>
                                ) : ''}
                                {props.MoveTypeDetail.is_dropoff_address == 1 ? (
                                    <div className="form-row col-md-12 col-lg-12">
                                        <i className="fas fa-location-dot"></i>

                                        <AutoComplete addplace={setDropoffAddress} value={oneStepform.values.dropoff_address} />

                                        <span>Dropoff Address</span>
                                        {oneStepform.touched.dropoff_address && oneStepform.errors.dropoff_address ? <div className="text-danger">{oneStepform.errors.dropoff_address}</div> : ''}
                                        {validDropoffAddress == true ? <div className="text-danger">{errorMessage}</div> : ''}
                                    </div>
                                ) : ''}

                                <div className="row text-right space-stepone">
                                    <div className="col-md-12">
                                        <Link to="" className="prev prev-btn-bokmove prev-btn" onClick={() => props.prev(props.data)} style={{ marginRight: '2em', textDecoration: 'none' }}><span><i className="fa fa-arrow-left"></i>Previous</span></Link>
                                        <button type="submit" className="btn step-btn-bokmove step-btn" ><span>Continue</span></button>&nbsp;
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6" >
                    <div className="ptop-133">
                        <div id="map_canvas" style={{ height: '350px' }} ></div>
                    </div>
                </div>
            </div>
        </>
    );
};

const StepTwo = (props) => {
    const [selectPickupType, setselectPickupType] = useState(false);
    const [selectDropoffType, setselectDropoffType] = useState(false);

    const secondStepform = useFormik({
        initialValues: props.data,
        validationSchema: yup.object({
            // pickup_address_type: yup.number().required('Please select from unit or apartment.').min(1, 'Please select from unit or apartment.').max(2, 'Please select from unit or apartment.'),
            // dropoff_address_type: yup.number().required('Please select from unit or apartment.').min(1, 'Please select from unit or apartment.').max(2, 'Please select from unit or apartment.'),
            is_pickup_stairs: yup.number().required('you must specify a number'),
            pickup_stairs: yup.number().required('you must specify a number'),
            is_drop_off_stairs: yup.number().required('you must specify a number'),
            drop_off_stairs: yup.number().required('you must specify a number'),
            use_pickup_elevator: yup.number().required('you must specify a number'),
            use_dropoff_elevator: yup.number().required('you must specify a number'),
            is_pickup_stairs: yup.number().required('you must specify a number'),
            // is_pickup_stairs: yup.bool().oneOf([true], 'Accept Terms & Conditions is required'),
            is_pickup_stairs: yup.number().required('you must specify a number'),
            pickup_address_type: yup.string().required('Please select from unit or apartment.'),
            dropoff_address_type: yup.string().required('Please select from unit or apartment.')

        }),
        onSubmit: values => {
            // const pad = pickup_address_type
            if (values.pickup_address_type == 0 && values.pickup_apartment) {
                secondStepform.setFieldError('pickup_address_type', 'Please select from unit or apartment.');
                setselectPickupType(true)
            }
            else if (values.dropoff_address_type == 0 && values.dropoff_apartment) {
                secondStepform.setFieldError('dropoff_address_type', 'Please select from unit or apartment.');
                setselectDropoffType(true)
            } else {
                setselectPickupType(false)
                setselectDropoffType(false)
                props.next(values);
            }
        }
    });

    const setPickupStairField = e => {
        if (e.target.checked == 0) {
            secondStepform.setFieldValue("pickup_stairs", 0);
        }
        secondStepform.setFieldValue("is_pickup_stairs", e.target.checked == true ? 1 : 0);
    }

    const setDropoffStairField = e => {
        if (e.target.checked == 0) {
            secondStepform.setFieldValue("drop_off_stairs", 0);
        }
        secondStepform.setFieldValue("is_drop_off_stairs", e.target.checked == true ? 1 : 0);
    }

    const setPickupElevatorField = e => {
        secondStepform.setFieldValue("use_pickup_elevator", e.target.checked == true ? 1 : 0);
    }

    const setDropoffElevatorField = e => {
        secondStepform.setFieldValue("use_dropoff_elevator", e.target.checked == true ? 1 : 0);
    }

    const updatePickupStair = e => {
        if (e.target.value == '+') {

            let updatePickupStairValue = secondStepform.values.pickup_stairs > 0 ? parseInt(secondStepform.values.pickup_stairs) + 1 : 1;
            secondStepform.setFieldValue("pickup_stairs", updatePickupStairValue);
        } else if (e.target.value == '-') {
            let updatePickupStairValue = secondStepform.values.pickup_stairs > 1 ? parseInt(secondStepform.values.pickup_stairs) - 1 : 0;
            if (updatePickupStairValue < 1) {
                secondStepform.setFieldValue("pickup_stairs", 0);
            } else {
                secondStepform.setFieldValue("pickup_stairs", updatePickupStairValue);
            }
        }
    }

    const updateDropoffStair = e => {
        if (e.target.value == '+') {
            let updateDropoffStairValue = secondStepform.values.drop_off_stairs > 0 ? parseInt(secondStepform.values.drop_off_stairs) + 1 : 1;
            secondStepform.setFieldValue("drop_off_stairs", updateDropoffStairValue);
        } else if (e.target.value == '-') {
            let updateDropoffStairValue = secondStepform.values.drop_off_stairs > 1 ? parseInt(secondStepform.values.drop_off_stairs) - 1 : 0;
            if (updateDropoffStairValue < 1) {
                secondStepform.setFieldValue("drop_off_stairs", 0);
            } else {
                secondStepform.setFieldValue("drop_off_stairs", updateDropoffStairValue);
            }
        }
    }

    return (
        <div className="form-steps" style={{ display: "block" }}>


            <form className="job-application-form row d-flex align-item justify-content-center" onSubmit={secondStepform.handleSubmit}>
                {props.MoveTypeDetail.is_pickup_address == 1 ? (
                    <>
                        <div className={props.MoveTypeDetail.is_dropoff_address == 0 ? "col-md-8 mtb-0" : "col-md-6 mtb-0"} >
                            <div className="row login-page">
                                <div className="col-md-12"><h3 className="border-bottom">Pickup Address</h3></div>

                                {props.data.pickup_address ? (<h5>{props.data.pickup_address}</h5>) : ''}

                                <div className="form-row col-md-12 col-lg-12">
                                    <div className="radio-btn-w-9form">
                                        <div className="job-application-form unit-apartment-box">
                                            <h6>Select Unit or Apartment</h6>
                                            <div className="form-group radio-group">
                                                <div className="custom-radio">
                                                    <input type="radio" name="pickup_address_type" className="custom-control-input" id="pickup_address_type" onChange={() => secondStepform.setFieldValue("pickup_address_type", 1)} checked={parseInt(secondStepform.getFieldProps("pickup_address_type").value) == 1} />
                                                    <label className="custom-control-label" htmlFor="pickup_address_type">Unit</label>
                                                </div>
                                            </div>
                                            <div className="form-group radio-group">
                                                <div className="custom-radio">
                                                    <input type="radio" name="pickup_address_type" className="custom-control-input" id="pickup_address_type_2" onChange={() => secondStepform.setFieldValue("pickup_address_type", 2)} checked={parseInt(secondStepform.getFieldProps("pickup_address_type").value) == 2} />
                                                    <label className="custom-control-label" htmlFor="pickup_address_type_2">Apartment</label>
                                                </div>
                                            </div>
                                        </div>
                                        {secondStepform.touched.pickup_address_type && secondStepform.errors.pickup_address_type ? <div className="text-danger">{secondStepform.errors.pickup_address_type}</div> : ''}

                                        {/* {selectPickupType ?
                                            <>
                                                <div className="text-danger">Please select from unit or apartment.</div>
                                            </>
                                            : ''} */}
                                    </div>
                                </div>

                                <div className="form-row col-md-12 col-lg-12 mt-3">
                                    <i className="fas fa-map-location-dot"></i>
                                    <input className="form-control" name="pickup_apartment" {...secondStepform.getFieldProps("pickup_apartment")} />
                                    <span>Enter Unit Or Apartment Number</span>
                                    {secondStepform.touched.pickup_apartment && secondStepform.errors.pickup_apartment ? <div className="text-danger">{secondStepform.errors.pickup_apartment}</div> : ''}
                                </div>
                                <div className="form-row col-md-12 col-lg-12 mt-3">
                                    <i className="fas fa-map-location-dot"></i>
                                    <input type="text" name="pickup_gate_code" {...secondStepform.getFieldProps("pickup_gate_code")} className="form-control" />
                                    <span>Enter Gate Code</span>
                                </div>



                                {props.MoveTypeDetail.is_pickup_stair == 1 ? (
                                <>
                                    <div className="form-group check-group col-md-12 col-lg-6 mb-0">
                                        <div className="custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="is_pickup_stairs" id="customControlValidation1" onChange={setPickupStairField} checked={secondStepform.getFieldProps("is_pickup_stairs").value == 1} />
                                            <label className="custom-control-label" htmlFor="customControlValidation1" >Helper(s) Needs To Use Stairs</label>
                                            {secondStepform.touched.is_pickup_stairs && secondStepform.errors.is_pickup_stairs ? <div className="text-danger">{secondStepform.errors.is_pickup_stairs}</div> : ''}

                                        </div>
                                    
                                        {secondStepform.getFieldProps("is_pickup_stairs").value == 1 ? (
                                            <>
                                                <div className="h-show qty" style={{ marginBottom: '10px' }}>
                                                    <div className="input-group">
                                                        <label className="labefifle">Flight(s)</label>
                                                        <input type="button" value="-" className="button-minus" data-input="pickup_stairs" onClick={updatePickupStair} />
                                                        <input type="text" name="pickup_stairs" {...secondStepform.getFieldProps("pickup_stairs")} className="quantity-field" />
                                                        <input type="button" value="+" className="button-plus" data-input="pickup_stairs" onClick={updatePickupStair} />
                                                    </div>
                                                    {secondStepform.touched.pickup_stairs && secondStepform.errors.pickup_stairs ? <div className="text-danger">{secondStepform.errors.pickup_stairs}</div> : ''}
                                                </div>
                                                <p className="pstep-from mb-0">It's important to indicate if your Helper(s) needs to go up or down stairs so they can prepared.</p>
                                            </>
                                        ) : ''}
                                    </div>
                                    <div className="form-group check-group col-md-12 col-lg-6 mb-0">
                                        <div className="custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="use_pickup_elevator" onChange={setPickupElevatorField} id="customControlValidation2" checked={secondStepform.getFieldProps("use_pickup_elevator").value == 1} />
                                            <label className="custom-control-label" htmlFor="customControlValidation2">Helper(s) Can Use Elevator </label>
                                            {secondStepform.touched.use_pickup_elevator && secondStepform.errors.use_pickup_elevator ? <div className="text-danger">{secondStepform.errors.use_pickup_elevator}</div> : ''}
                                        </div>
                                    </div>
                                 </>
                                ) : ''}
                                
                            </div>
                        </div>

                    </>
                ) : ''}
                {props.MoveTypeDetail.is_dropoff_address == 1 ? (
                    <>

                        <div className="col-md-6">
                            <div className="row login-page">
                                <div className="col-md-12"><h3 className="border-bottom">Dropoff Address</h3></div>
                                {props.data.dropoff_address ? (<h5>{props.data.dropoff_address}</h5>) : ''}

                                <div className="form-row col-md-12 col-lg-12">
                                    <div className="radio-btn-w-9form">
                                        <div className="job-application-form unit-apartment-box">
                                            <h6>Select Unit or Apartment</h6>
                                            <div className="form-group radio-group">
                                                <div className="custom-radio">
                                                    <input type="radio" name="dropoff_address_type" className="custom-control-input" id="radio4" onChange={() => secondStepform.setFieldValue("dropoff_address_type", 1)} checked={parseInt(secondStepform.getFieldProps("dropoff_address_type").value) == 1} />
                                                    <label className="custom-control-label" htmlFor="radio4">Unit</label>
                                                </div>
                                            </div>
                                            <div className="form-group radio-group">
                                                <div className="custom-radio">
                                                    <input type="radio" name="dropoff_address_type" className="custom-control-input" id="radio5" onChange={() => secondStepform.setFieldValue("dropoff_address_type", 2)} checked={parseInt(secondStepform.getFieldProps("dropoff_address_type").value) == 2} />
                                                    <label className="custom-control-label" htmlFor="radio5">Apartment</label>
                                                </div>
                                            </div>
                                        </div>
                                        {secondStepform.touched.dropoff_address_type && secondStepform.errors.dropoff_address_type ? <div className="text-danger">{secondStepform.errors.dropoff_address_type}</div> : ''}

                                        {/* {selectDropoffType ?
                                            <>
                                                <div className="text-danger">Please select to unit or apartment.</div>
                                            </>
                                            : ''} */}
                                    </div>
                                </div>
                                <div className="form-row col-md-12 col-lg-12 mt-3">
                                    <i className="fas fa-map-location-dot"></i>
                                    <input type="text" className="form-control" name="dropoff_apartment" {...secondStepform.getFieldProps("dropoff_apartment")} />
                                    <span>Enter Unit Or Apartment Number</span>
                                    {secondStepform.touched.dropoff_apartment && secondStepform.errors.dropoff_apartment ? <div className="text-danger">{secondStepform.errors.dropoff_apartment}</div> : ''}
                                </div>
                                <div className="form-row col-md-12 col-lg-12 mt-3">
                                    <i className="fas fa-map-location-dot"></i>
                                    <input type="text" className="form-control" name="dropoff_gate_code" {...secondStepform.getFieldProps("dropoff_gate_code")} />
                                    <span>Enter Gate Code</span>

                                </div>

                                {props.MoveTypeDetail.is_dropoff_stair == 1 ? (
                                <>
                                    <div className="form-group check-group col-md-12 col-lg-6 mb-0">
                                        <div className="custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="is_drop_off_stairs" id="customControlValidation3" onChange={setDropoffStairField} checked={secondStepform.getFieldProps("is_drop_off_stairs").value == 1} />
                                            <label className="custom-control-label" htmlFor="customControlValidation3" >Helper(s) Needs To Use Stairs</label>
                                        </div>
                                        {secondStepform.getFieldProps("is_drop_off_stairs").value == 1 ? (
                                            <>
                                                <div className="h-show qty" id="foo1" style={{ marginBottom: '10px' }}>
                                                    <div className="input-group">
                                                        <label className="labefifle">Flight(s)</label>
                                                        <input type="button" value="-" className="button-minus" data-input="drop_off_stairs" onClick={updateDropoffStair} />
                                                        <input type="text" name="drop_off_stairs" {...secondStepform.getFieldProps("drop_off_stairs")} className="quantity-field" />
                                                        <input type="button" value="+" className="button-plus" data-input="drop_off_stairs" onClick={updateDropoffStair} />
                                                    </div>
                                                    {secondStepform.touched.drop_off_stairs && secondStepform.errors.drop_off_stairs ? <div className="text-danger">{secondStepform.errors.drop_off_stairs}</div> : ''}
                                                </div>
                                                <p className="pstep-from">It's important to indicate if your Helper(s) needs to go up or down stairs so they can prepared.</p>
                                            </>
                                        ) : ''}
                                    </div>
                                    <div className="form-group check-group col-md-12 col-lg-6">
                                        <div className="custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="use_dropoff_elevator" onChange={setDropoffElevatorField} id="customControlValidation4" checked={secondStepform.getFieldProps("use_dropoff_elevator").value == 1} />
                                            <label className="custom-control-label" htmlFor="customControlValidation4">Helper(s) Can Use Elevator</label>
                                            {secondStepform.touched.use_dropoff_elevator && secondStepform.errors.use_dropoff_elevator ? <div className="text-danger">{secondStepform.errors.use_dropoff_elevator}</div> : ''}
                                        </div>
                                    </div>
                                </>
                                ) : ''}
                            </div>
                        </div>

                    </>
                ) : ''}
                <div className={props.MoveTypeDetail.is_dropoff_address == 0 ? "col-md-8 text-right" : "col-md-12 text-right"}  >
                    <Link to="" className="prev prev-btn-bokmove prev-btn" onClick={() => props.prev(props.data)} style={{ marginRight: '2em', textDecoration: 'none' }}><span><i className="fa fa-arrow-left"></i>Previous</span></Link>
                    <button type="submit" className="btn step-btn-bokmove step-btn" ><span>Continue</span></button>
                </div>
            </form>
        </div>
    );
};

const StepFour = (props) => {
    const [loading, setloading] = useState(false);

    useEffect(() => {
        getItemIndex();
    }, [])

    let spinColor = '#fff';
    let variant = '';

    const fourStepform = useFormik({
        initialValues: {
            item_old: 0,
            item_index: "",
            subcategory_id: "",
            item_name: props.MoveTypeDetail.item_name,
            item_price: props.MoveTypeDetail.item_price,
            can_assamble: 0,
            is_assamble: 0,
            is_custom_item: 0,
            quantity: 1,
            additional_info: "",
            item_width: "",
            item_height: "",
            item_depth: "",
            item_weight: "",
            item_photos: []
        },
        validationSchema: yup.object({
            quantity: yup.number().typeError('you must specify a number').required('Quantity is required.').min(1, 'Minimum 1 quantity is required'),
            additional_info: yup.string().required('Please enter additional Info'),
            item_photos: yup.array().min(1, 'Please upload a picture of item(s).').max(3, 'Maximum 3 picture allowed.')
        }),
        onSubmit: values => {
            if (fourStepform.values.item_old == 1) {
                props.data.items[fourStepform.values.item_index] = values;
            } else {
                props.data.items.push(values);
            }
            props.next(props.data, false);
            localStorage.setItem('formpage', (4))
        }
    });

    const getItemIndex = () => {
        fourStepform.resetForm();
        if (props.data.items[0] !== undefined) {
            let item_detail = props.data.items[0];
            fourStepform.setFieldValue("is_custom_item", item_detail.is_custom_item);
            fourStepform.setFieldValue("subcategory_id", item_detail.subcategory_id);
            fourStepform.setFieldValue("item_name", item_detail.item_name);
            fourStepform.setFieldValue("item_price", item_detail.item_price);
            fourStepform.setFieldValue("quantity", item_detail.quantity);
            fourStepform.setFieldValue("additional_info", item_detail.additional_info);
            fourStepform.setFieldValue("item_width", item_detail.item_width);
            fourStepform.setFieldValue("item_height", item_detail.item_height);
            fourStepform.setFieldValue("item_depth", item_detail.item_depth);
            fourStepform.setFieldValue("item_weight", item_detail.item_weight);
            fourStepform.setFieldValue("item_photos", item_detail.item_photos);
            fourStepform.setFieldValue("can_assamble", item_detail.can_assamble);
            fourStepform.setFieldValue("is_assamble", item_detail.is_assamble);
            fourStepform.setFieldValue("item_index", 0);
            fourStepform.setFieldValue("item_old", 1);
        } else {

        }
    }

    const acceptedFiles = e => {
        let totalFiles = fourStepform.values.item_photos.length + e.target.files.length
        if (totalFiles < 4) {
            setloading(true);
            var formData = new FormData();
            for (let i = 0; i < e.target.files.length; i++) {
                formData.append('image[]', e.target.files[i])
            }
            formData.append("folder_name", 'item_image');
            api.postApi('upload-images', formData, true).then(response => {
                fourStepform.setFieldValue("item_photos", [...fourStepform.values.item_photos, ...response.data]);
                setloading(false);
                e.target.value = ''
            }).catch(error => {
                setloading(false);
                toast.error(error.message);
                e.target.value = ''
            });
        } else {
            toast.error("Maximum 3 picture allowed.");
        }
    }

    const RemoveItemImage = (index) => {
        if (fourStepform.values.item_photos[index] !== undefined) {
            fourStepform.values.item_photos.splice(index, 1);
        }
    }

    const updateQuantity = e => {

        if (e.target.value == '+') {
            let updateQuantityValue = fourStepform.values.quantity > 0 ? parseInt(fourStepform.values.quantity) + 1 : 1;
            fourStepform.setFieldValue("quantity", updateQuantityValue);
        } else if (e.target.value == '-') {
            let updateQuantityValue = fourStepform.values.quantity > 1 ? parseInt(fourStepform.values.quantity) - 1 : 1;
            if (updateQuantityValue < 1) {
                fourStepform.setFieldValue("quantity", 1);
            } else {
                fourStepform.setFieldValue("quantity", updateQuantityValue);
            }
        }
    }

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = (index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (
        <div className="d-flex align-item justify-content-center" >
            <div className="col-lg-8">
                <div className="form-steps" style={{ display: "block" }}>
                    <form action="#" className="job-application-form" onSubmit={fourStepform.handleSubmit}>
                        <div className="junk-removal-content">
                            <h3 className="mb-0">Add Item(s)</h3>
                            <p className="border-bottom">{props.MoveTypeDetail.add_item_label}</p>
                            <div className="row itemstep">
                                <div className="col-12 col-md-12 col-lg-12 mb-2">
                                    <div className="cardbox add-item">
                                        <img src={props.MoveTypeDetail.image_url} alt="" />
                                        <h5>{props.MoveTypeDetail.item_name}</h5>
                                    </div>
                                </div>
                            </div>
                            {props.MoveTypeDetail.is_how_many == 1 ? (
                                <>
                                    <div className="h-show qty" style={{ marginBottom: '30px' }}>
                                        <div className="input-group">
                                            <label className="labefifle">{props.MoveTypeDetail.how_many_label}</label>
                                            <input type="button" value="-" className="button-minus" onClick={updateQuantity} />
                                            <input type="text" name="quantity" min="1" {...fourStepform.getFieldProps("quantity")} className="quantity-field" />
                                            <input type="button" value="+" className="button-plus" onClick={updateQuantity} />
                                        </div>
                                        {fourStepform.touched.quantity && fourStepform.errors.quantity ? <div className="text-danger">{fourStepform.errors.quantity}</div> : ''}
                                    </div>
                                </>
                            ) : ''}
                            <div className="form-group check-group">
                                <p className="pstep-from">{props.MoveTypeDetail.move_info}</p>
                            </div>
                            <label htmlFor="inputText">{props.MoveTypeDetail.describe_label}</label>

                            <div className="form-group check-group">
                                <textarea className="form-control" name="additional_info" id="inputText" {...fourStepform.getFieldProps("additional_info")}></textarea>
                            </div>
                            {fourStepform.touched.additional_info && fourStepform.errors.additional_info ? <div className="text-danger">{fourStepform.errors.additional_info}</div> : ''}
                            <hr />
                            <div className="form-group">
                                <h5>{props.MoveTypeDetail.take_photo_label}</h5>
                                <p className="pstep-from">{props.MoveTypeDetail.sub_take_photo_label}</p>
                                <div className="custom-file">
                                    <input type="file" disabled={loading} className="custom-file-input form-control" multiple id="validatedCustomFile" accept="image/png, image/gif, image/jpeg" onChange={acceptedFiles} />
                                    <label className="custom-file-label" htmlFor="validatedCustomFile">{fourStepform.values.item_photos.length == 0 ? <> <span className="file-return">No file choosen</span> </> : ''}</label>
                                </div>
                                {fourStepform.touched.item_photos && fourStepform.errors.item_photos ? <div className="text-danger">{fourStepform.errors.item_photos}</div> : ''}

                                {fourStepform.values.item_photos.length > 0 && fourStepform.values.item_photos.length < 4 ? (
                                    <>
                                        <div className="row">
                                            {fourStepform.values.item_photos.length > 0 && fourStepform.values.item_photos.length < 4 && fourStepform.values.item_photos.map((file, Index) =>
                                                <div className="col-md-4" key={Index} >
                                                    <img src={file} className="img-thumbnail mt-2" alt="" height={200} width={200} onClick={() => openImageViewer(Index)} />
                                                    <Link to="" onClick={() => RemoveItemImage(Index)} style={{ position: "absolute" }} >X</Link>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : ''}
                            </div>
                        </div>
                        <div className="row text-right">
                            <div className="col-md-12">
                                <Link to="" className="prev prev-btn-bokmove prev-btn" onClick={() => props.prev(props.data)} style={{ marginRight: '2em', textDecoration: 'none' }} ><span><i className="fa fa-arrow-left"></i>Previous</span></Link>
                                <Buttons loading={loading} spinColor={spinColor} variant={variant} type="submit" className="btn step-btn-bokmove step-btn">
                                    <span>Continue</span>
                                </Buttons>
                            </div>
                        </div>
                        {isViewerOpen && (
                            <ImageViewer
                                src={fourStepform.values.item_photos}
                                currentIndex={currentImage}
                                disableScroll={false}
                                closeOnClickOutside={true}
                                onClose={closeImageViewer}
                            />
                        )}
                    </form>

                </div>
            </div>
        </div>
    );
};

const StepFive = (props) => {
    useEffect(() => {
        if (props.data.request_id) {
            calculateCharges();
        }
    }, [props.data.request_id])

    const [estimateHour, setestimateHour] = useState(props.data.estimate_hour);
    const [show, setShow] = useState(false);
    const [subModalshow, setsubModalshow] = useState(false);
    const [itemModalshow, setitemModalshow] = useState(false);
    const [Category, setCategory] = useState([]);
    const [CategoryDetail, setCategoryDetail] = useState([]);
    const [subCategory, setsubCategory] = useState([]);
    const [itemDetail, setitemDetail] = useState([]);
    const [loading, setloading] = useState(false);
    let { draft_id } = useParams();
    let { request_id } = useParams();

    const showModel = () => {
        GetCategoryList();
    };

    const hideModal = () => {
        setShow(false);
    };

    const hideSubModal = () => {
        setsubModalshow(false);
    };

    const hideItemModal = () => {
        setitemModalshow(false);
    };

    const GetCategoryList = () => {
        api.getApi('get-items-category').then(response => {
            setCategory(response.data);
            setShow(true);
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const GetSubCategoryList = e => {
        multiItemform.resetForm();
        api.postApi('get-items-sub-category', { category_id: e.target.value }).then(response => {
            setsubCategory(response.data.subcategory);
            setCategoryDetail(response.data.category);
            setsubModalshow(true);
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const setSubCategoryValue = e => {
        getItmemDetail(e.target.value)
    }

    const getItmemDetail = (subcategory_id) => {
        api.postApi('get-items-sub-category-by-id', { subcategory_id: subcategory_id }).then(response => {
            setitemDetail(response.data)
            setitemModalshow(true);
            multiItemform.setFieldValue("subcategory_id", response.data.subcategory_id);
            multiItemform.setFieldValue("item_name", response.data.name);
            multiItemform.setFieldValue("item_price", response.data.price);
            multiItemform.setFieldValue("can_assamble", response.data.can_assamble);
        }).catch(error => {
            toast.error(error.message);
        });
    }
    const customer_ids = localStorage.getItem("customer_id")
    const draftid = localStorage.getItem("draft_id")
    const multiItemform = useFormik({
        initialValues: {
            item_old: 0,
            item_index: "",
            subcategory_id: "",
            item_name: "",
            item_price: "",
            can_assamble: 0,
            is_assamble: 0,
            is_carry_with_people: 0,
            is_custom_item: 0,
            quantity: 1,
            additional_info: "",
            item_width: "",
            item_height: "",
            item_depth: "",
            item_weight: "",
            item_photos: []
        },
        validationSchema: yup.object({
            quantity: yup.number().typeError('you must specify a number').required('Quantity is required.').min(1, 'Minimum 1 quantity is required'),
            additional_info: yup.string().required('Please enter additional Info'),
            item_width: props.MoveTypeDetail.is_additional_info == 1 ? yup.number().typeError('you must specify a number').required('Please enter estimated width of the item.') : yup.number(),
            item_height: props.MoveTypeDetail.is_additional_info == 1 ? yup.number().typeError('you must specify a number').required('Please enter estimated height of the item.') : yup.number(),
            item_depth: props.MoveTypeDetail.is_additional_info == 1 ? yup.number().typeError('you must specify a number').required('Please enter estimated depth of the item.') : yup.number(),
            item_weight: props.MoveTypeDetail.is_additional_info == 1 ? yup.number().typeError('you must specify a number')
                .max(500, 'Item  weight must be less than 500 ibs.').required('Please enter estimated weight of the item.') : yup.number(),
            item_photos: yup.array().min(1, 'Please upload a picture of item(s).').max(3, 'Maximum 3 picture allowed.')
        }),
        onSubmit: values => {
            // console.log(values)
            if (multiItemform.values.item_old == 1 && request_id == undefined && localStorage.getItem("is_login") > 0) {
                props.data.items[multiItemform.values.item_index] = values;
                api.postApi('customer-edit-draft', {
                    user_id: customer_ids,
                    user_type: 'C',
                    request_id: draftid || draft_id,
                    move_type_id: props.data.move_type_id,
                    pickup_date: props.data.pickup_date,
                    pickup_start_time: props.data.pickup_start_time,
                    pickup_end_time: props.data.pickup_end_time,
                    pickup_address: props.data.pickup_address,
                    pickup_address_type: props.data.pickup_address_type,
                    pickup_gate_code: props.data.pickup_gate_code,
                    pickup_lat: props.data.pickup_lat,
                    pickup_long: props.data.pickup_long,
                    dropoff_address: props.data.dropoff_address,
                    dropoff_address_type: props.data.dropoff_address_type,
                    dropoff_gate_code: props.data.dropoff_gate_code,
                    dropoff_lat: props.data.dropoff_lat,
                    dropoff_long: props.data.dropoff_long,
                    pickup_apartment: props.data.pickup_apartment,
                    is_pickup_stairs: props.data.pickup_stairs,
                    pickup_stairs: props.data.pickup_stairs,
                    use_pickup_elevator: props.data.use_pickup_elevator,
                    dropoff_apartment: props.data.dropoff_apartment,
                    is_drop_off_stairs: props.data.drop_off_stairs,
                    drop_off_stairs: props.data.drop_off_stairs,
                    use_dropoff_elevator: props.data.use_dropoff_elevator,
                    helping_service_id: props.data.helping_service_id,
                    dropoff_service_id: props.data.dropoff_service_id,
                    promocode: props.data.promocode,
                    is_promocode_applied: props.data.is_promocode_applied,
                    insurance_charge: props.data.insurance_charge,
                    payment_method_nonce: props.data.payment_method_nonce,
                    multi_use_payment_id: props.data.multi_use_payment_id,
                    estimate_hour: 0,
                    is_pickup_address_valid: props.data.is_pickup_address_valid,
                    is_dropoff_address_valid: props.data.is_dropoff_address_valid,
                    items: props.data.items
                }).then(response => {
                    // console.log(response);
                }).catch(error => {
                    // console.log(error)
                })
            } else {
                props.data.items.push(values);
                if (props.data.items.length > 0 && request_id == undefined && localStorage.getItem("is_login") > 0) {
                    api.postApi('customer-edit-draft', {
                        user_id: customer_ids,
                        user_type: 'C',
                        request_id: draftid || draft_id,
                        move_type_id: props.data.move_type_id,
                        pickup_date: props.data.pickup_date,
                        pickup_start_time: props.data.pickup_start_time,
                        pickup_end_time: props.data.pickup_end_time,
                        pickup_address: props.data.pickup_address,
                        pickup_address_type: props.data.pickup_address_type,
                        pickup_gate_code: props.data.pickup_gate_code,
                        pickup_lat: props.data.pickup_lat,
                        pickup_long: props.data.pickup_long,
                        dropoff_address: props.data.dropoff_address,
                        dropoff_address_type: props.data.dropoff_address_type,
                        dropoff_gate_code: props.data.dropoff_gate_code,
                        dropoff_lat: props.data.dropoff_lat,
                        dropoff_long: props.data.dropoff_long,
                        pickup_apartment: props.data.pickup_apartment,
                        is_pickup_stairs: props.data.pickup_stairs,
                        pickup_stairs: props.data.pickup_stairs,
                        use_pickup_elevator: props.data.use_pickup_elevator,
                        dropoff_apartment: props.data.dropoff_apartment,
                        is_drop_off_stairs: props.data.drop_off_stairs,
                        drop_off_stairs: props.data.drop_off_stairs,
                        use_dropoff_elevator: props.data.use_dropoff_elevator,
                        helping_service_id: props.data.helping_service_id,
                        dropoff_service_id: props.data.dropoff_service_id,
                        promocode: props.data.promocode,
                        is_promocode_applied: props.data.is_promocode_applied,
                        insurance_charge: props.data.insurance_charge,
                        payment_method_nonce: props.data.payment_method_nonce,
                        multi_use_payment_id: props.data.multi_use_payment_id,
                        estimate_hour: 0,
                        is_pickup_address_valid: props.data.is_pickup_address_valid,
                        is_dropoff_address_valid: props.data.is_dropoff_address_valid,
                        items: props.data.items
                    }).then(response => {
                        // console.log(response);
                    }).catch(error => {
                        // console.log(error)
                    })
                }
            }
            setitemModalshow(false);
            setsubModalshow(false);
            setShow(false);
            multiItemform.resetForm()
            props.calculateCharges();
        }
    });

    const acceptedFiles = e => {
        let totalFiles = multiItemform.values.item_photos.length + e.target.files.length
        if (totalFiles < 4) {
            setloading(true);
            var formData = new FormData();
            for (let i = 0; i < e.target.files.length; i++) {
                formData.append('image[]', e.target.files[i])
            }
            formData.append("folder_name", 'item_image');
            api.postApi('upload-images', formData, true).then(response => {
                multiItemform.setFieldValue("item_photos", [...multiItemform.values.item_photos, ...response.data]);
                setloading(false);
                e.target.value = ''
            }).catch(error => {
                setloading(false);
                toast.error(error.message);
                e.target.value = ''
            });
        } else {
            toast.error("Maximum 3 picture allowed.");
        }
    }

    const updateQuantity = e => {
        if (e.target.value == '+') {
            if (multiItemform.values.quantity >= 19) {
                multiItemform.setFieldValue("quantity", 20);
            } else {
                let updateQuantityValue = multiItemform.values.quantity > 0 ? parseInt(multiItemform.values.quantity) + 1 : 1;
                multiItemform.setFieldValue("quantity", updateQuantityValue);
            }
        } else if (e.target.value == '-') {
            let updateQuantityValue = multiItemform.values.quantity > 1 ? parseInt(multiItemform.values.quantity) - 1 : 1;
            if (updateQuantityValue < 1) {
                multiItemform.setFieldValue("quantity", 1);
            } else {
                multiItemform.setFieldValue("quantity", updateQuantityValue);
            }
        }
    }

    const getItemIndex = (index) => {
        multiItemform.resetForm();
        if (props.data.items[index] !== undefined) {
            let item_detail = props.data.items[index];
            setitemDetail(item_detail);
            if (item_detail.subcategory_id > 0 && props.MoveTypeDetail.can_add_item) {
                getItmemDetail(item_detail.subcategory_id);
            } else if (props.MoveTypeDetail.can_add_item) {
                setitemDetail({
                    name: item_detail.item_name,
                    photo_url: props.config.ITEM_DEFAULT_IMAGE,
                    can_assamble: item_detail.can_assamble,
                    is_custom_item: item_detail.is_custom_item
                })
            }
            multiItemform.setFieldValue("is_custom_item", item_detail.is_custom_item);
            multiItemform.setFieldValue("subcategory_id", item_detail.subcategory_id);
            multiItemform.setFieldValue("item_name", item_detail.item_name);
            multiItemform.setFieldValue("item_price", item_detail.item_price);
            multiItemform.setFieldValue("quantity", item_detail.quantity);
            multiItemform.setFieldValue("additional_info", item_detail.additional_info);
            multiItemform.setFieldValue("item_width", item_detail.item_width);
            multiItemform.setFieldValue("item_height", item_detail.item_height);
            multiItemform.setFieldValue("item_depth", item_detail.item_depth);
            multiItemform.setFieldValue("item_weight", item_detail.item_weight);
            multiItemform.setFieldValue("item_photos", item_detail.item_photos);
            multiItemform.setFieldValue("can_assamble", item_detail.can_assamble);
            multiItemform.setFieldValue("is_assamble", item_detail.is_assamble);
            multiItemform.setFieldValue("is_carry_with_people", item_detail.is_carry_with_people);
            multiItemform.setFieldValue("item_index", index);
            multiItemform.setFieldValue("item_old", 1);
            setitemModalshow(true);
        }
    }

    const deleteItemIndex = (index) => {
        multiItemform.resetForm();
        if (props.data.items[index] !== undefined) {
            props.data.items.splice(index, 1);
        }
    }

    const RemoveItemImage = (index) => {
        if (multiItemform.values.item_photos[index] !== undefined) {
            multiItemform.values.item_photos.splice(index, 1);
        }
    }

    const calculateCharges = () => {
        if (props.data.request_id > 0) {
            var startTime = new Date(props.data.pickup_date);
            var currentDate = new Date();
            if (startTime > currentDate) {
                getStartTimeSlot('06:00', '23:30', 30, 'pickupStartTime')
            } else {
                let hour = moment(props.data.pickup_start_time, "HH");
                let mintue = moment().format('mm');
                if (mintue > 30) {
                    getStartTimeSlot(hour + ':00', '23:30', 30, 'pickupStartTime')
                } else {
                    getStartTimeSlot(hour + ':30', '23:30', 30, 'pickupStartTime')
                }
            }
            startTime = moment(props.data.pickup_start_time, "HH:mm").add(30, 'minutes');
            getStartTimeSlot(startTime, '23:30', 30, 'pickupEndTime')
            getStartTimeSlot(startTime, '23:30', 30, 'pickupEndTime')
        }
    }

    const updateHelpingService = (e) => {
        if (e.target.checked) {
            props.data.helping_service_id = e.target.value;
            props.setData(props.data);
            if (props.MoveTypeDetail.is_estimate_hour) {
                props.setopenTab(10)
            } else {
                props.setopenTab(3)
            }

        }
    }

    const updateDropoffService = (e) => {
        // console.log(e.target.value);
        if (e.target.checked) {
            props.data.dropoff_service_id = e.target.value;
            props.setData(props.data);
            props.setopenTab(5)
            props.setData(props.data);
        }

    }

    const getpickupStartTime = (value) => {
        props.data.pickup_date = moment(value).format("YYYY/MM/DD")
        props.setData(props.data);
        var startTime = new Date(value);
        var currentDate = new Date();
        if (startTime > currentDate) {
            getStartTimeSlot('06:00', '23:30', 30, 'pickupStartTime')
        } else {
            let hour = moment().format('HH');
            let mintue = moment().format('mm');
            if (mintue > 30) {
                let hour = moment().add(30, 'minutes').format('HH');
                getStartTimeSlot(hour + ':00', '23:30', 30, 'pickupStartTime')
            } else {
                getStartTimeSlot(hour + ':30', '23:30', 30, 'pickupStartTime')
            }
        }
    }

    const getpickupEndTime = e => {
        props.data.pickup_start_time = e.target.value;
        props.setData(props.data);
        let startTime = moment(e.target.value, "HH:mm").add(30, 'minutes');
        getStartTimeSlot(startTime, '23:30', 30, 'pickupEndTime')
    }

    const pickupEndTimeSet = e => {
        props.data.pickup_end_time = e.target.value;
        props.setData(props.data);
        if (props.MoveTypeDetail.is_estimate_hour == 0) {
            props.setopenTab(4)
        }
        let startTime = moment(props.data.pickup_start_time, "HH:mm").add(30, 'minutes');
        getStartTimeSlot(startTime, '23:30', 30, 'pickupEndTime')
    }

    const getStartTimeSlot = (openTime = '06:00', closeTime = '23:30', slotInterval = 30, type = '') => {
        //Data
        let x = {
            slotInterval: slotInterval,
            openTime: openTime,
            closeTime: closeTime
        };

        //Format the time
        let startTime = moment(x.openTime, "HH:mm");

        //Format the end time and the next day to it 
        let endTime = moment(x.closeTime, "HH:mm");

        //Times
        let allTimes = [];
        allTimes.push({ value: '', time: 'Select' })

        //Loop over the times - only pushes time with 30 minutes interval
        while (startTime <= endTime) {
            //Push times
            allTimes.push({ value: startTime.format("HH:mm A"), time: startTime.format("hh:mm A") })
            //Add interval of 30 minutes
            startTime.add(x.slotInterval, 'minutes');
        }

        if (type == 'pickupStartTime') {
            props.setpickupStartTime(allTimes);
        } else if (type == 'pickupEndTime') {
            props.setpickupEndTime(allTimes);
        }
    }

    const updateEstimateHour = async (e) => {
        if (e.target.value == '+') {
            if (estimateHour > 19) {
                props.data.estimate_hour = 20;
            } else {
                props.data.estimate_hour = parseInt(props.data.estimate_hour) + 1;
            }
            props.setData(props.data);
            setestimateHour(props.data.estimate_hour)
        } else if (e.target.value == '-') {
            if (props.data.estimate_hour > 1) {
                props.data.estimate_hour -= 1
                props.setData(props.data);
                setestimateHour(props.data.estimate_hour)
            }
        } else {
            if (e.target.value > 0) {
                if (e.target.value > 19) {
                    props.data.estimate_hour = 20;
                } else {
                    props.data.estimate_hour = e.target.value;
                }
                props.setData(props.data);
                setestimateHour(props.data.estimate_hour)
            } else {
                props.data.estimate_hour = 1;
                props.setData(props.data);
                setestimateHour(props.data.estimate_hour)
            }
        }
    }

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = (index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const [items, setitems] = useState([]);

    const handleOnSelect = (item) => {
        setitems(item)
        if (item.value > 0) {
            getItmemDetail(item.value)
        } else {
            setitemDetail({
                name: item.label,
                photo_url: '',
                can_assamble: 0,
                is_custom_item: 1
            })
            multiItemform.resetForm();
            setitemModalshow(true);
            multiItemform.setFieldValue("subcategory_id", item.value);
            multiItemform.setFieldValue("item_name", item.label);
            multiItemform.setFieldValue("item_price", 0);
            multiItemform.setFieldValue("is_assamble", 0);
            multiItemform.setFieldValue("can_assamble", 0);
            multiItemform.setFieldValue("is_carry_with_people", 0);
            multiItemform.setFieldValue("is_custom_item", 1);
        }
    }

    const updateItemAssemble = (e) => {
        if (e.target.checked) {
            if (multiItemform.values.is_custom_item) {
                multiItemform.setFieldValue("can_assamble", 1);
            }
            multiItemform.setFieldValue("is_assamble", 1);
            multiItemform.setFieldValue("item_price", multiItemform.values.item_price + 5);
        } else {
            if (multiItemform.values.is_custom_item) {
                multiItemform.setFieldValue("can_assamble", 0);
            }
            multiItemform.setFieldValue("is_assamble", 0);
            multiItemform.setFieldValue("item_price", multiItemform.values.item_price - 5);
        }
    }

    const updateItemCarryWithPeople = (e) => {
        if (e.target.checked) {
            multiItemform.setFieldValue("is_carry_with_people", 1);
            multiItemform.setFieldValue("item_price", multiItemform.values.is_assamble ? 20 : 15);
        } else {
            multiItemform.setFieldValue("is_carry_with_people", 0);
            if (multiItemform.values.item_width <= 50.0) {
                multiItemform.setFieldValue("item_price", multiItemform.values.is_assamble ? 10 : 5);
            } else if (multiItemform.values.item_width <= 80.0) {
                multiItemform.setFieldValue("item_price", multiItemform.values.is_assamble ? 15 : 10);
            } else {
                multiItemform.setFieldValue("item_price", multiItemform.values.is_assamble ? 20 : 15);
            }
        }
    }

    const updateCustomItemPrice = (e) => {
        multiItemform.setFieldValue("item_width", e.target.value);
        if (multiItemform.values.is_custom_item) {
            if (multiItemform.values.is_carry_with_people) {
                multiItemform.setFieldValue("item_price", multiItemform.values.is_assamble ? 20 : 15);
            } else {
                if (multiItemform.values.item_width <= 50.0) {
                    multiItemform.setFieldValue("item_price", multiItemform.values.is_assamble ? 10 : 5);
                } else if (multiItemform.values.item_width <= 80.0) {
                    multiItemform.setFieldValue("item_price", multiItemform.values.is_assamble ? 15 : 10);
                } else {
                    multiItemform.setFieldValue("item_price", multiItemform.values.is_assamble ? 20 : 15);
                }
            }
        }
    }

    return (
        <>
            <div className="d-flex align-item justify-content-center" >
                <div className="col-lg-8">
                    <div className="form-steps" style={{ display: "block" }}>

                        <div className="tabs movebook-tabs">
                            <div className="tab">
                                <input type="radio" id="rd1" name="rd" checked={'rd' + props.openTab == 'rd1'} onChange={() => props.setopenTab(1)} />
                                <label className="tab-label" htmlFor="rd1" >Item Details</label>
                                <div className="tab-content">
                                    <form action="#" className="job-application-form price-dtailcard">
                                        <div className="row itemdetail">
                                            {props.data.items.length > 0 && props.data.items.map((option, Index) => (
                                                <div className="col-12 col-md-6 col-lg-4 mb-2" key={Index}>
                                                    <div className="cardbox">
                                                        <h5>{option.item_name} <span className="t-item">{option.quantity}</span> <span className="pricedetail">${option.quantity * option.item_price}</span></h5>
                                                        {props.MoveTypeDetail.can_edit_item == 1 ?
                                                            <Link to="" className="editbtn" onClick={() => getItemIndex(Index)} >Edit</Link> : ''}
                                                        {props.MoveTypeDetail.can_delete_item == 1 ?
                                                            <Link to="" className="delete" onClick={() => deleteItemIndex(Index)} >Delete</Link> : ''}
                                                    </div>
                                                </div>
                                            ))}
                                            {props.MoveTypeDetail.can_add_item == 1 ? (
                                                <>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <Link to="" className="add-more-itembtn" onClick={showModel} >Add Items</Link>
                                                    </div>
                                                </>
                                            ) : ''}
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="tab">
                                <input type="radio" id="rd2" name="rd" checked={'rd' + props.openTab == 'rd2'} onChange={() => props.setopenTab(2)} />
                                <label className="tab-label" htmlFor="rd2">Move It Pros and Muscles</label>
                                <div className="tab-content">
                                    <p className="border-bottom">{props.MoveTypeDetail ? props.MoveTypeDetail.helping_service_label : ''}</p>
                                    <form action="#" className="job-application-form">
                                        {props.HelpingService.length > 0 && props.HelpingService.map((option, index) => (
                                            <div className="form-group radio-group" key={index} >
                                                <div className="custom-radio">
                                                    <input type="radio" name="helping_service_id" value={option.helping_service_id} className="custom-control-input" id={'helping_' + index} onChange={updateHelpingService} checked={parseInt(props.data.helping_service_id) == parseInt(option.helping_service_id)} />
                                                    <label className="custom-control-label" htmlFor={'helping_' + index}>{option.name}
                                                        <small className="small-radio">{option.description}</small></label>
                                                </div>
                                            </div>
                                        ))}
                                    </form>
                                </div>
                            </div>

                            {props.MoveTypeDetail.is_estimate_hour ?
                                <>
                                    <div className="tab">
                                        <input type="radio" id="rd10" name="rd" checked={'rd' + props.openTab == 'rd10'} onChange={() => props.setopenTab(10)} />
                                        <label className="tab-label" htmlFor="rd10">Estimate Hour needed for your Muscle(s)</label>
                                        <div className="tab-content">
                                            <form action="#" className="job-application-form">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {/*<label className="form-label mb-0" ><b>Estimate Hour needed for your Muscle(s)</b></label>*/}
                                                        <p>This is approximate You will only be charged for the time your Muscle(s) are needed.</p>
                                                        <div className="h-show qty" style={{ marginBottom: "30px" }} >

                                                            <div className="input-group">
                                                                <input type="button" value="-" className="button-minus" onClick={async (e) => await updateEstimateHour(e)} />
                                                                <input type="text" name="estimate_hour" min="1" className="quantity-field" value={estimateHour} onChange={async (e) => await updateEstimateHour(e)} />
                                                                <input type="button" value="+" className="button-plus" onClick={async (e) => await updateEstimateHour(e)} />
                                                            </div>
                                                        </div>
                                                        <p>Please make sure you have correct estimated hours. Are you sure you need helper(s) for approximately {estimateHour} hours ? Please do not exceed this. Helpers may have other jobs or obligations and will be subject to close out job at estimated time frame. </p>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </>
                                : ''}


                            <div className="tab">
                                <input type="radio" id="rd3" name="rd" checked={'rd' + props.openTab == 'rd3'} onChange={() => props.setopenTab(3)} />
                                <label className="tab-label" htmlFor="rd3">Schedule Your Move</label>
                                <div className="tab-content">
                                    <form action="#" className="job-application-form">
                                        <div className="row">
                                            {/*<div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label" >Pickup Date</label>
                                                     <DatePicker selected={props.data.pickup_date} onChange={(date) => getpickupStartTime(date) }   adjustDateOnChange class="form-control" minDate={startDate} />
                                                     <span>Select Date</span>
                                                    
                                                    <span className="text-danger invalid"></span>
                                                </div> 
                                            </div>*/}

                                            <div className="col-md-6 col-lg-6 datepicks mb-3">
                                                <i className="fas fa-calendar fa-fw z1 bomove-caladr-icon"></i>
                                                <label className="form-label" ><b>Pickup Date</b></label>
                                                {props.data.pickup_date == "1970-01-01"
                                                    ?
                                                    <DatePicker selected={''} onChange={(date) => getpickupStartTime(date)} adjustDateOnChange class="form-control" minDate={new Date()} />
                                                    :
                                                    <DatePicker selected={props.data.pickup_date ? new Date(props.data.pickup_date) : ''} onChange={(date) => getpickupStartTime(date)} adjustDateOnChange class="form-control" minDate={new Date()} />
                                                }
                                                <span className="text-danger invalid"></span>
                                            </div>


                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h6><b>Your Available Time</b></h6>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label" >From</label>

                                                    <select id="pickup_start_time" name="pickup_start_time" value={props.data.pickup_start_time} className="form-control" onChange={(e) => getpickupEndTime(e)}  >
                                                        {props.pickupStartTime.length > 0 && props.pickupStartTime.map((option, index) => (
                                                            <option value={option.value} key={index} >{option.time}</option>
                                                        ))}
                                                    </select>
                                                    <span className="text-danger invalid"></span>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label" >To</label>
                                                    <select id="pickup_end_time" name="pickup_end_time" value={props.data.pickup_end_time} className="form-control" onChange={(e) => pickupEndTimeSet(e)} >
                                                        {props.pickupEndTime.length > 0 && props.pickupEndTime.map((option, index) => (
                                                            <option value={option.value} key={index} >{option.time}</option>
                                                        ))}
                                                    </select>
                                                    <span className="text-danger invalid"></span>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <p>Choose a 30 minute window, or several hours if you're flexible. No need to wait around Your move or delivery will be schedule within 30 minute of your time slot.</p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="tab">
                                <input type="radio" id="rd4" name="rd" checked={'rd' + props.openTab == 'rd4'} onChange={() => props.setopenTab(4)} />
                                <label className="tab-label" htmlFor="rd4">Drop Off Service</label>
                                <div className="tab-content">
                                    <p className="border-bottom">{props.MoveTypeDetail ? props.MoveTypeDetail.dropoff_service_label : ''}</p>
                                    <form action="#" className="job-application-form">
                                        <div className="row drop-service">
                                            {props.DropoffService.length > 0 && props.DropoffService.map((option, index) => (
                                                <div className="col-12 col-md-4 col-lg-4 mb-2" key={index} >
                                                    <input className="checkbox-tools checkbox-tools-dropoff" type="radio" name="dropoff_service_id" value={option.dropoff_service_id} id={'dropoff_' + index} onChange={updateDropoffService} checked={parseInt(props.data.dropoff_service_id) == parseInt(option.dropoff_service_id)} />
                                                    <label className="for-checkbox-tools" htmlFor={'dropoff_' + index}>
                                                        <div className="cardbox">
                                                            <img src={option.photo_url} alt="" />
                                                            <h5>{option.name}</h5>
                                                            <p>{option.description}</p>
                                                        </div>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="row ufg-job-application-wrapper ptb-0 space-stepone-1 text-right">
                            <div className="col-md-12 job-application-form">
                                <Link to="" className="prev prev-btn-bokmove prev-btn" onClick={() => props.prev(props.data)} style={{ marginRight: '2em', textDecoration: 'none' }} ><span><i className="fa fa-arrow-left"></i>Previous</span></Link>
                                <button onClick={() => props.checkValidation()} type="button" className="btn step-btn-bokmove step-btn">
                                    <span>Continue</span>
                                </button>
                            </div>
                        </div>
                        {isViewerOpen && (
                            <ImageViewer
                                src={multiItemform.values.item_photos}
                                currentIndex={currentImage}
                                disableScroll={false}
                                closeOnClickOutside={true}
                                onClose={closeImageViewer}
                            />
                        )}
                    </div>
                    <Modal modal="CATEGORY" show={show} handleClose={hideModal} Category={Category} GetSubCategoryList={GetSubCategoryList} handleOnSelect={handleOnSelect} ></Modal>
                    <Modal modal="SUB-CATEGORY" show={subModalshow} handleClose={hideSubModal} subCategory={subCategory} CategoryDetail={CategoryDetail} setSubCategoryValue={setSubCategoryValue} handleOnSelect={handleOnSelect}   ></Modal>
                    <Modal modal="ADD-ITEM" loading={loading} show={itemModalshow} handleClose={hideItemModal} itemDetail={itemDetail} MoveTypeDetail={props.MoveTypeDetail} multiItemform={multiItemform} acceptedFiles={acceptedFiles} updateQuantity={updateQuantity} RemoveItemImage={RemoveItemImage} openImageViewer={openImageViewer} updateItemAssemble={updateItemAssemble} updateItemCarryWithPeople={updateItemCarryWithPeople} updateCustomItemPrice={updateCustomItemPrice} handleOnSelect={handleOnSelect} ></Modal>
                </div>
            </div>
        </>

    );
};

const StepSix = (props) => {
    const [HelpingName, setHelpingName] = useState('');
    const [DropoffName, setDropoffName] = useState('');
    console.log(props.data.items)

    useEffect(() => {
        HelpingServiceValue();
        DropoffServiceValue();
        props.calculateCharges();
    }, [])

    const HelpingServiceValue = () => {
        props.HelpingService.find(obj => {
            if (parseInt(obj.helping_service_id) == parseInt(props.data.helping_service_id)) {
                setHelpingName(obj.name)
            }
        });
    }

    const DropoffServiceValue = () => {
        props.DropoffService.find(obj => {
            if (parseInt(obj.dropoff_service_id) == parseInt(props.data.dropoff_service_id)) {

                setDropoffName(obj.name)
            }
        });
    }

    const getStartTime = () => {
        let startTime = moment(props.data.pickup_start_time, "HH:mm");
        return startTime.format("hh:mm A");
    }

    const getEndTime = () => {
        let startTime = moment(props.data.pickup_end_time, "HH:mm");
        return startTime.format("hh:mm A");
    }

    const updatePromocode = (e) => {
        e.preventDefault();
        if (props.data.is_promocode_applied) {
        } else {
            props.applyPromoCode()
        }
    }
    const [itemModalshow, setitemModalshow] = useState(false);

    const hideItemModal = (type) => {
        setitemModalshow(false);
        let local = localStorage.getItem("login")
        if (local == 0) {
            props.next(props.data);
        }
        else if (type === true) {
            props.finalSubmit()
        }
    };

    var num2 = parseFloat(props.charges.final_price).toFixed(2)

    return (
        <>
            <div className="form-steps" style={{ display: "block" }} >

                <form action="#" className="job-application-form login-page row d-flex alitms-center justify-content-center">

                    <div className="col-md-12 col-lg-8">
                        <h3 className="border-bottom"> Move Summary </h3>
                        <div className="contact-wrapper">
                            <div className="d-block w-100 float-right mb-3">
                                {props.MoveTypeDetail.is_dropoff_address ?
                                    <>
                                        <div className="row no-gutters bg-light p-10all-side move-dmap movesummary">
                                            <div className="col-xl-12 col-lg-12 col-md-12">
                                                <div className="row">
                                                    {props.MoveTypeDetail.is_pickup_address ?
                                                        <div className="col-md-6 col-lg-6">
                                                            <i className="pr-1 maploc"><img src="web/img/from_icon.png" alt="" /></i>
                                                            <small>From</small>
                                                            <br />
                                                            <span className="maptxt">{props.data.pickup_address}

                                                                {parseInt(props.data.pickup_address_type) == 1 && props.data.pickup_apartment ?
                                                                    <p className="apt-unit">Unit # {props.data.pickup_apartment} </p>
                                                                    : ''}

                                                                {parseInt(props.data.pickup_address_type) == 2 && props.data.pickup_apartment ?
                                                                    <p className="apt-unit">Apt. # {props.data.pickup_apartment} </p>
                                                                    : ''}

                                                                {props.data.pickup_gate_code ?
                                                                    <p className="apt-unit">Gate Code: # {props.data.pickup_gate_code} </p>
                                                                    : ''}
                                                            </span>
                                                        </div>
                                                        : ''}
                                                    {props.MoveTypeDetail.is_dropoff_address ?
                                                        <div className="col-md-6 col-lg-6">
                                                            <i className="pr-1 maploc"><img src="web/img/to_location_icon.png" alt="" /></i>
                                                            <small>To</small>
                                                            <br />
                                                            <span className="maptxt">{props.data.dropoff_address}
                                                                {props.data.dropoff_address_type == 1 && props.data.dropoff_apartment ?
                                                                    <p className="apt-unit">Unit # {props.data.dropoff_apartment} </p>
                                                                    : ''}

                                                                {props.data.dropoff_address_type == 2 && props.data.dropoff_apartment ?
                                                                    <p className="apt-unit">Apt. # {props.data.dropoff_apartment} </p>
                                                                    : ''}

                                                                {props.data.dropoff_gate_code ?
                                                                    <p className="apt-unit">Gate Code: # {props.data.dropoff_gate_code} </p>
                                                                    : ''}
                                                            </span>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12">
                                                <div className="row">
                                                    <div className="col-5 col-md-6 col-lg-6 align-self-center">
                                                        <div className="small movepro-title"><span>{HelpingName} </span> <br />
                                                            {props.MoveTypeDetail.is_dropoff_address ?
                                                                <>
                                                                    {props.charges.total_miles_distance} Miles
                                                                </>
                                                                : ''}
                                                        </div>
                                                    </div>

                                                    <div className="col-7 col-md-6 col-lg-6 align-self-center">
                                                        <i className="far fa-calendar-alt pr-1 maploc-1"></i><small>Scheduled Time/Date</small>
                                                        <br />
                                                        <span className="maptxt">{moment(props.data.pickup_date).format("MMM DD yyyy")},  {getStartTime()} - {getEndTime()}


                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :

                                    <div className="row no-gutters bg-light p-10all-side move-dmap">
                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                            <div className="row">
                                                {props.MoveTypeDetail.is_pickup_address ?
                                                    <div className="col-md-12">
                                                        <i className="pr-1 maploc"><img src="web/img/from_icon.png" alt="" /></i>
                                                        <small>From</small>
                                                        <br />
                                                        <span className="maptxt">{props.data.pickup_address}
                                                            {props.data.pickup_address_type == 1 && props.data.pickup_apartment ?
                                                                <p className="apt-unit">Unit # {props.data.pickup_apartment} </p>
                                                                : ''}

                                                            {props.data.pickup_address_type == 2 && props.data.pickup_apartment ?
                                                                <p className="apt-unit">Apt. # {props.data.pickup_apartment} </p>
                                                                : ''}

                                                            {props.data.pickup_gate_code ?
                                                                <p className="apt-unit">Gate Code: # {props.data.pickup_gate_code} </p>
                                                                : ''}
                                                        </span>
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                            <div className="row no-gutters">
                                                <div className="col-12 col-lg-6 align-self-center">
                                                    <i className="far fa-calendar-alt pr-1 maploc-1"></i><small>Scheduled Time/Date</small>
                                                    <br />
                                                    <span className="maptxt">{moment(props.data.pickup_date).format("MMM DD yyyy")}, {getStartTime()} - {getEndTime()}
                                                    </span>
                                                </div>
                                                <div className="col-12 col-lg-6 align-self-center">
                                                    <div className="small movepro-title"><span>{HelpingName} </span> <br />
                                                        {props.MoveTypeDetail.is_dropoff_address ?
                                                            <>
                                                                {props.charges.total_miles_distance} Miles
                                                            </>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                }



                            </div>
                        </div>

                        <div className="movedetail-boxcustomer">
                            <div className="row itemstep">
                                <div className="col-md-12"><h3 className="border-bottom">Price Details</h3> </div>
                                <div className="col-md-12"><h6>Total Items <span>{props.data.items.length}</span></h6></div>

                                <div className="row itemdetail move-summary mb-2">
                                    {props.data.items.length > 0 && props.data.items.map((option, Index) => (
                                        <div className="col-12 col-md-6 col-lg-6 mb-2" key={Index} >
                                            <div className="cardbox">
                                                <h5>{option.item_name}  <span className="t-item">{option.quantity}</span> <span className="pricedetail">${option.quantity * option.item_price}</span></h5>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="row move-summary-charges mb-3">
                                    {/*<div className="col-md-6">
        							<div className="last-itme-box">
										<h6 className="fontname">ITEM PRICE</h6>
										<span className="edit"> 
											<Link to="">${props.charges.total_item_price}</Link>
										</span>
									</div>
        						</div>*/}
                                    <div className="col-md-6">
                                        <div className="last-itme-box">
                                            <h6 className="fontname">Service Charge <br />

                                                <span><Link to="#" onClick={(e) => { e.preventDefault(); props.setserviceChargeModal(true) }} >Click here for details</Link></span></h6>
                                            <span className="edit">
                                                <Link to="">${props.charges.total_service_charges}</Link>
                                            </span>
                                        </div>
                                    </div>

                                    {props.MoveTypeDetail && parseInt(props.MoveTypeDetail.is_estimate_hour) == 1 ?
                                        <div className="col-md-6">
                                            <div className="last-itme-box">
                                                <h6 className="fontname">Estimated Hour Charge <br />
                                                    <small className="pnk-txt">{props.data.estimate_hour} extra hours needed</small>
                                                </h6>
                                                <span className="edit">
                                                    <Link to="">${props.charges.extra_estimate_hour_price}</Link>
                                                </span>
                                            </div>
                                        </div>
                                        : ''}
                                    {props.MoveTypeDetail && parseInt(props.MoveTypeDetail.is_base_price) == 1 ?
                                        <div className="col-md-6">
                                            <div className="last-itme-box">
                                                <h6 className="fontname">Base Charge<br />
                                                    <span className="pnk-txt">{props.MoveTypeDetail?.name}</span></h6>
                                                <span className="edit">
                                                    <Link to="">${props.charges.base_price}</Link>
                                                </span>
                                            </div>
                                        </div>
                                        : ''}

                                    <div className="col-md-6">
                                        <div className="last-itme-box">
                                            <h6 className="fontname">Service Discount Amount<br />
                                                <span className="pnk-txt">{DropoffName}</span></h6>
                                            <span className="edit">
                                                <Link to="">- ${props.charges.service_discount}</Link>
                                            </span>
                                        </div>
                                    </div>

                                </div>


                                <div className="row promo">
                                    <div className="col-lg-12 login-page">
                                        <div className="form-row">
                                            <input type="text" name="promocode" value={props.data.promocode} onChange={props.updatePromocode} onKeyDown={e => e.key == 'Enter' && updatePromocode(e)} />
                                            <span>Enter Promo Code</span>
                                        </div>
                                        <button type="button" className="promocode" id="apply_text" onClick={props.data.is_promocode_applied ? props.removePromoCode : props.applyPromoCode}  >{props.data.is_promocode_applied ? 'Remove' : 'Apply'}</button>
                                    </div>
                                </div>


                                {props.MoveTypeDetail && props.MoveTypeDetail.is_insurance == 1 ? (
                                    <>
                                        <div className="row">
                                            <div className="col-md-12 col-lg-12 insurancecheck" >
                                                <div className="form-group check-group">
                                                    <div className="custom-checkbox">
                                                        <input type="checkbox" name="insurance_charge" className="custom-control-input" id="customControlValidation140" onChange={props.updateInsuranceValue} checked={props.isInsurance} />
                                                        <label className="custom-control-label" htmlFor="customControlValidation140"> For only $5 you can add $250 more in Move It insurance protection</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : ''}

                                {props.MoveTypeDetail && props.MoveTypeDetail.is_estimate_hour == 1 ? (
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12">
                                            <p>This is approximate You will only be charged for the time your Muscle(s) are needed.</p>
                                        </div>
                                    </div>
                                ) : ''}
                            </div>
                        </div>

                    </div>
                    {props.MoveTypeDetail && props.MoveTypeDetail.is_assembly == 1 ? (
                        <div className="row ufg-job-application-wrapper ptb-0  d-flex align-item justify-content-center ">
                            <div className="col-md-12 col-lg-8 job-application-form last-itme-box">
                                <div className="form-check">
                                    <input className="form-check-input" name="is_assembly_muscle" type="checkbox" id="is_assembly_muscle" value="1"  onChange={props.updateAssemblyForMuscle} checked={props.data.is_assembly_muscle==1} style={{left: "10px",
        "width": "15px",
        "height": "15px", "visibility": "visible" }} />
                                    <label className="form-check-label" htmlFor="is_assembly_muscle">
                                        Assembly requested
                                        <br></br>Assembly is requested. I have items that need assembled.
                                        <br></br>*Please note: Selected helpers do basic assembly. Not all helpers do assembly or are certified.
                                        
                                    </label>
                                </div>
                            </div>
                        </div>
                    ) : ''}

                    <div className="row ufg-job-application-wrapper ptb-0 text-right d-flex align-item justify-content-center">
                        <div className="col-md-12 col-lg-8 job-application-form">
                            <Link to="" className="prev prev-btn-bokmove prev-btn" onClick={() => props.prev(props.data)} style={{ marginRight: '2em', textDecoration: 'none' }} ><span><i className="fa fa-arrow-left"></i>Previous</span></Link>

                            <button type="button" className="btn step-btn-bokmove step-btn" onClick={() => setitemModalshow(true)} >
                                <span>Checkout <b className="f-18"> ${num2}</b> <small className="d-block-price">You have selected {DropoffName}</small></span></button>

                        </div>
                    </div>

                </form>
            </div>
            <Modal modal="CHECKOUT-MODAL" show={itemModalshow} handleClose={hideItemModal} MoveTypeDetail={props.MoveTypeDetail} ></Modal>
        </>
    )
}

const StepZero = (props) => {
    //login
    // console.log(props.config)

    const api = new Service();
    let verify;
    let recaptchaWrapperRef;

    function logout() {
        // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
        window.FB.api('/me/permissions', 'delete', null, () => window.FB.logout());
    }

    useEffect(() => {
        // console.log(Object.keys(props.config) + "config")
        let local = localStorage.getItem("login")
        if (local == 0) {
            // console.log("doneZero00000000")
            setZerotrue(true)
            setLoginPage(true)
        }
        else {
            props.next(props.data)
        }
    }, [])

    const [zerotrue, setZerotrue] = useState(false)
    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const [showModel, setshowModel] = useState(false);
    const [step, setstep] = useState(0);
    const [message, setMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [invalidNumber, setinvalidNumber] = useState('');
    const [invalidOTP, setinvalidOTP] = useState('');
    const [loginPage, setLoginPage] = useState(true)

    useEffect(() => {
        // localStorage.setItem("login", 0)
        if (invalidNumber) {
            setTimeout(function () {
                setinvalidNumber(false)
            }, 5000);
        }
    }, [invalidNumber])

    useEffect(() => {
        if (errorMessage) {
            setTimeout(function () {
                setErrorMessage(false)
            }, 3000);
        }
    }, [errorMessage])

    useEffect(() => {
        getFCMToken();
    }, [])

    const getFCMToken = () => {
        requestForToken().then((currentToken) => {
            formik.setFieldValue('device_token', currentToken);
        });
    }

    const formik = useFormik({
        initialValues: {
            email_id: '',
            password: '',
            device_token: ''
        },
        validationSchema: yup.object({
            password: yup.string().required('Please enter your password'),
            email_id: yup.string().email('Invalid email address').required('Please enter your email address')
        }),
        onSubmit: values => {

            setloading(true)
            api.postApi('customer-login', values).then(response => {
                toast.success('Login successfully');
                formik.resetForm()
                setloading(false)
                localStorage.setItem("user_token", response.web_auth_key);
                localStorage.setItem("user_type", 'customer');
                localStorage.setItem("user_detail", response);
                localStorage.setItem("customer_id", response.customer_id)
                localStorage.setItem("is_login", 1)
                localStorage.setItem("login", 1)
                // navigate('/book-move')
                props.prev(props.data)

            }).catch(error => {
                setloading(false)
                //  seterror(false)
                setErrorMessage(true)
                setMessage(error.message)
                //  toast.error(error.message);
            });
        }
    });

    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType == "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const [webAuthKey, setWebAuthKey] = useState('');
    const responseFacebook = (response) => {

        if (response && response.id != undefined) {
            var postData = { login_type: 'F', provider_key: response.id, name: response.name, email_id: response.email, photo_url: response.picture.data.url }
            setloading(true);
            api.postApi('customer-sign-up', postData).then(response => {
                setstep(1)
                logout()
                if (response.phone_num) {
                    localStorage.setItem("user_token", response.web_auth_key);
                    localStorage.setItem("user_type", 'customer');
                    localStorage.setItem("user_detail", response);
                    localStorage.setItem("customer_id", response.customer_id)
                    localStorage.setItem("is_login", 1)
                    localStorage.setItem("login", 1)
                    // navigate('/book-move')
                    props.prev(props.data)
                } else {
                    setWebAuthKey(response.web_auth_key)
                    setshowModel(true)
                }
                setloading(false);
                setshowModel(true)
            }).catch(error => {
                setloading(false);
                toast.error(error.message);
            });
        }
    }

    const responseGoogle = (response) => {
        // console.log(response);
        if (response && response.googleId != undefined) {
            var postData = { login_type: 'G', provider_key: response.googleId, name: response.profileObj.name, email_id: response.profileObj.email, photo_url: response.profileObj.imageUrl }
            setloading(true);
            api.postApi('customer-sign-up', postData).then(response => {
                setstep(1)
                if (response.phone_num) {
                    localStorage.setItem("user_token", response.web_auth_key);
                    localStorage.setItem("user_type", 'customer');
                    localStorage.setItem("user_detail", response);
                    localStorage.setItem("customer_id", response.customer_id)
                    localStorage.setItem("is_login", 1)
                    localStorage.setItem("login", 1)
                    // navigate('/book-move')
                    props.prev(props.data)
                } else {
                    setWebAuthKey(response.web_auth_key)
                    setshowModel(true)
                }
                setloading(false);
            }).catch(error => {
                setloading(false);
                toast.error(error.message);
            });
        }
    }

    const formik2 = useFormik({
        initialValues: {
            phone_num: '',
            country_code: ''
        },
        validationSchema: yup.object({
            phone_num: yup.number().typeError('you must specify a number').required('Please enter your phone number.'),
            country_code: yup.string().required('Please select your country.')
        }),
        onSubmit: values => {
            sentOTP();
        }

    });
 
    const formik3 = useFormik({
        initialValues: {
            OTP: ''
        },
        validationSchema: yup.object({
            OTP: yup.string().required('Please enter a vaild OTP.'),
        }),
        onSubmit: values => {
            ValidateOtp()
        }

    });

    const formik4 = useFormik({
        initialValues: {
            phone_num: formik2.values.phone_num ? formik2.values.phone_num : "",
            web_auth_key: webAuthKey ? webAuthKey : "",
            about_move_it: ''
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            about_move_it: yup.string().required('Please select one'),
        }),
        onSubmit: values => {
            // console.log(values)
            setloading(true);
            api.postApi('update-customer-phone-number', (values)).then(response => {
                setstep(0)
                setloading(false);
                setshowModel(false)
                if (response && response.data) {
                    localStorage.setItem("user_token", response.data.web_auth_key);
                    localStorage.setItem("user_type", 'customer');
                    localStorage.setItem("user_detail", response);
                    localStorage.setItem("customer_id", response.customer_id)
                    localStorage.setItem("is_login", 1)
                    localStorage.setItem("login", 1)
                    // navigate('/book-move')
                    props.prev(props.data)

                }
            }).catch(error => {
                setloading(false);
            });
        }

    });

    const [final, setfinal] = useState('');

    const sentOTP = () => {
        if (formik2.isValid && formik2.values.phone_num) {
            setloading(true);
            if (verify && recaptchaWrapperRef) {
                verify.clear()
                recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`
            }
            verify = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible'
            });
            auth.signInWithPhoneNumber('+' + formik2.values.country_code + formik2.values.phone_num, verify).then((result) => {
                setfinal(result);
                setstep(2)
                setloading(false);
            }).catch((err) => {
                setloading(false);
                setinvalidNumber("Please enter vaild phone number.")
                // toast.error("Please enter vaild phone number.");
            });
        } else {
            formik2.handleSubmit();
        }
    }

    const ValidateOtp = () => {
        if (formik3.isValid && formik3.values.OTP) {
            setloading(true);
            if (final == null)
                return;
            final.confirm(formik3.values.OTP).then((result) => {
                setstep(3)
                setloading(false);
            }).catch((err) => {
                setloading(false);
                setinvalidOTP("Please enter a vaild OTP.")
            })
        } else {
            formik3.handleSubmit();
        }
    }

    useEffect(() => {
        getCountry()
        getAboutMoveList();
    }, [])

    const [aboutList, setaboutList] = useState([]);

    const getAboutMoveList = () => {
        setloading(true)
        api.getApi('get-about-moveit-list').then(response => {
            if (response && response.data) {
                setaboutList(response.data)
            }
            setloading(false)
        }).catch(error => {
            setloading(false)
            toast.error(error.message);
        });
    }

    const [country, setcountry] = useState([]);

    const getCountry = () => {
        api.getApi('get-country').then(response => {
            setcountry(response)
        }).catch(error => {
        });
    }

    // sign up

    // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    // const [loading, setloading] = useState(false);
    const [show, setShow] = useState(false);
    // const [defaultOTP, setdefaultOTP] = useState(1111);
    // const [OtpVerified, setOtpVerified] = useState(false);
    const [OTP, setOTP] = useState('');

    const [signupfinal, setsignupfinal] = useState('');
    // const [aboutList, setaboutList] = useState([]);
    const [signupaboutList, setsignupaboutList] = useState([]);

    const hideModal = () => {
        setShow(false);
    };
    // const verifYOTP = () => {
    //     if(OTP==defaultOTP) {
    //         toast.success("OTP verified successfully!");
    //         setShow(false,formik.handleSubmit())
    //     } else { 
    //         toast.error("Wrong OTP");
    //     }
    // }
    const setOTPValue = (event) => {
        setOTP(event.target.value);
    };

    const formik5 = useFormik({
        initialValues: {
            name: '',
            first_name: '',
            last_name: '',
            email_id: '',
            phone_num: '',
            password: '',
            term_and_condition: false,
            dial_code: "+1",
            photo_url: "",
            about_move_it: "",
        },

        validationSchema: yup.object({
            first_name: yup.string()
                .max(25, 'User can enter max 25 characters').required('Please enter your first name.'),
            last_name: yup.string().max(25, 'User can enter max 25 characters').required('Please enter your last name.'),
            phone_num: yup.number().typeError('you must specify a number').required('Please enter your phone number.'),
            password: yup.string().required('Please enter password.'),
            term_and_condition: yup.boolean().oneOf([true], 'Please select terms and conditions !'),
            checkEmail: yup.boolean(),
            email_id: yup.string().email('Invalid email address').required('Please enter your email address.'),
            dial_code: yup.string().required('Please select your country.'),
            about_move_it: yup.string().required('Please select one'),

        }),

        onSubmit: values => {
            setloading(true);
            values.phone_num = values.dial_code + ' ' + values.phone_num;
            let postData = { ...values, login_type: 'E', name: values.first_name + ' ' + values.last_name };
            api.postApi('customer-sign-up', postData).then(response => {
                setloading(false);
                toast.success('Registration successfully');
                formik5.resetForm()
                localStorage.setItem("user_token", response.web_auth_key);
                localStorage.setItem("user_type", 'customer');
                localStorage.setItem("customer_id", response.customer_id)
                localStorage.setItem("user_detail", response);
                localStorage.setItem("is_login", 1)
                localStorage.setItem("login", 1)
                // navigate('/book-move')   
                props.prev(props.data)
            }).catch(error => {
                setloading(false);
                toast.error(error.message);
            });
        }

    });

    // const [passwordType, setPasswordType] = useState("password");
    const [signuppasswordType, setsignupPasswordType] = useState("password");
    // const [passwordInput, setPasswordInput] = useState("");
    const signuptogglePassword = () => {
        if (signuppasswordType == "password") {
            setsignupPasswordType("text")
            return;
        }
        setsignupPasswordType("password")
    }

    const checkEmailExist = (email_id) => {
        formik5.setFieldValue('email_id', email_id)
        api.postApi('check-email-address', { email_id: email_id }).then(response => {
            if (response) {
                formik5.setFieldValue('checkEmail', response)
            } else {
                formik5.setFieldValue('checkEmail', false)
            }
            return response;
        }).catch(error => {
            formik5.setFieldValue('checkEmail', false)
        });
    }

    const finalSubmit = (event) => {
        event.preventDefault();
        setloading(true);
        api.postApi('check-email-address', { email_id: formik5.values.email_id }).then(response => {
            if (response) {
                formik5.setFieldValue('checkEmail', response)
                setloading(false);
            } else {

                if (formik5.isValid && formik5.values.phone_num) {
                    signupsentOTP();
                } else {
                    formik5.handleSubmit();
                    setloading(false);
                }
            }
        }).catch(error => {
            if (formik5.isValid && formik5.values.phone_num) {
                signupsentOTP();

            } else {
                formik5.handleSubmit();
                setloading(false);
            }
        });
    }

    const signupsentOTP = () => {
        if (verify && recaptchaWrapperRef) {
            verify.clear()
            recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`
        }
        verify = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible'
        });
        auth.signInWithPhoneNumber('+' + formik5.values.dial_code + formik5.values.phone_num, verify).then((result) => {
            setsignupfinal(result);
            setShow(true);
            setloading(false);
        }).catch((err) => {
            setloading(false);
            toast.error("Please enter vaild phone number.");
        });
    }

    const singupValidateOtp = () => {
        setloading(true);
        if (OTP == null || signupfinal == null)
            return;
        signupfinal.confirm(OTP).then((result) => {
            formik5.handleSubmit();
        }).catch((err) => {
            setloading(false);
            toast.error("Please enter a vaild OTP.");
        })
    }

    const acceptedFiles = e => {
        if (e.target.files.length) {
            setloading(true);
            var formData = new FormData();
            for (let i = 0; i < e.target.files.length; i++) {
                formData.append('image[]', e.target.files[i])
            }
            formData.append("folder_name", 'customer_profile');
            api.postApi('upload-images', formData, true).then(response => {
                formik5.setFieldValue("photo_url", response.data[0]);
                setloading(false);
                e.target.value = ''
            }).catch(error => {
                setloading(false);
                toast.error(error.message);
                e.target.value = ''
            });
        }
    }

    useEffect(() => {
        signupgetCountry()
        signupgetAboutMoveList();
    }, [])

    const signupgetAboutMoveList = () => {
        setloading(true)
        api.getApi('get-about-moveit-list').then(response => {
            if (response && response.data) {
                setsignupaboutList(response.data)
            }
            setloading(false)
        }).catch(error => {
            setloading(false)
            toast.error(error.message);
        });
    }

    // const [country, setcountry] = useState([]);
    const [signupcountry, setsignupcountry] = useState([]);
    const signupgetCountry = () => {
        api.getApi('get-country').then(response => {
            setsignupcountry(response)
        }).catch(error => {
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        singupValidateOtp();
    }

    return (
        <>
            {zerotrue && loginPage == true &&
                <>
                    <main>
                        <section className="work white mobile-paddtop">
                            <article className="container">
                                <div className="row align-items-center d-flex align-center" data-aos="fade-left" data-aos-delay="100">
                                    <div className="col-md-6">
                                        <div className="contact-wrapper">
                                            <div className="title">
                                                <h3 className="text-center">Log In</h3>
                                                <div ref={ref => recaptchaWrapperRef = ref}>
                                                    <div id="recaptcha-container"></div>
                                                </div>
                                            </div>
                                            {errorMessage && message ?
                                                <p className="text-danger text-center" style={{ textAlign: "initial", color: "red" }}>{message}</p>
                                                : ''}
                                            <form className="login-page " onSubmit={formik.handleSubmit} autoComplete="off">
                                                <div className="form-row">
                                                    <i className="fa fa-envelope" style={{ left: '20px' }}></i>
                                                    <input type="text" name="email_id" autoComplete="off" {...formik.getFieldProps("email_id")} />
                                                    <span>Email</span>
                                                </div>
                                                {formik.touched.email_id && formik.errors.email_id ? <span className="text-danger">{formik.errors.email_id}</span> : null}
                                                <div className="form-row">
                                                    <i className="fa fa-lock" style={{ left: '20px' }}></i>
                                                    <input id="password-field" name="password" type={passwordType} autoComplete="new-password"  {...formik.getFieldProps("password")} />
                                                    <span>Password</span>
                                                    <div toggle="#password-field" className={(passwordType == "password" ? 'fa fa-fw fa-eye-slash field-icon toggle-password ' : 'fa fa-fw field-icon toggle-password fa-eye')} onClick={togglePassword}></div>
                                                </div>
                                                {formik.touched.password && formik.errors.password ? <span className="text-danger">{formik.errors.password}</span> : null}

                                                <div className="form-row"></div>
                                                <div className="forgot-password" ><Link to="/forgot-password" > Forgot Password?</Link></div>
                                                <div className="form-row">
                                                    <button className="full" type="submit"  ><span>LOG IN</span></button>
                                                </div>
                                            </form>
                                            <div className="socials-wrapper">
                                                <div className="s-account">
                                                    <h2>Login with your Social Account</h2>
                                                </div>
                                                
                                                <div className="soculbtn">
                                                    <div className="facebook">
                                                        <FacebookLogin
                                                            appId={props.config.FACEBOOK_APP_ID} //APP ID NOT CREATED YET
                                                            // fields="name,email,picture"
                                                            fields="name,email,picture"
                                                            scope="public_profile"
                                                            autoLoad={false}
                                                            callback={responseFacebook}
                                                            cssClass="facebook-button-class"
                                                        />
                                                    </div>

                                                    <div className="twitter">
                                                        <GoogleLogin
                                                            clientId={props.config.GOOGLE_CLIENT_ID} //CLIENTID NOT CREATED YET
                                                            buttonText="LOGIN WITH GOOGLE"
                                                            autoLoad={false}
                                                            onSuccess={responseGoogle}
                                                            onFailure={responseGoogle}

                                                            className="google-button-class"
                                                        />

                                                    </div>
                                                </div>


                                                {/*	<ul>
                                <GoogleLogout
                      clientId={config.GOOGLE_CLIENT_ID}
                      buttonText="Logout"
                      onLogoutSuccess={logout}
                    >
                    </GoogleLogout>
                                  <li><a href="#" className="facebook"><i className="fab fa-facebook-square"></i></a></li>
                                  <li><a href="#" className="twitter"><i className="fab fa-google"></i></a></li>
                                </ul>*/}
                                                <h6 className="text-center dont-acct">Don't have an account yet? <Link to="" onClick={() => setLoginPage(false)}>Signup</Link></h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <img src="web/img/05.jpg" alt="moveit" className="login-right-img" />
                                    </div>
                                </div>
                            </article>
                        </section>
                    </main>

                    <div className={showModel ? "modal display-block" : "modal display-none"} id="otp-model" tabIndex="-1" role="dialog" aria-labelledby="def" aria-hidden="true">
                        <div className="modal-dialog modal-sm" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title" id="myModalLabel2">
                                        {step == 1 ?
                                            <>
                                                Enter Your Phone Number
                                            </>
                                            : ''}

                                        {step == 2 ?
                                            <>
                                                Enter Your Code
                                            </>
                                            : ''}
                                        {step == 3 ?
                                            <>
                                                How did you hear about Move It?
                                            </>
                                            : ''}


                                    </h4>
                                    <button type="button" className="close" onClick={() => setshowModel(false)}><span aria-hidden="true" onClick={() => setshowModel(false)}>&times;</span></button>
                                </div>
                                <div className="modal-body">
                                    <form className="login-page" onSubmit={step == 1 ? formik2.handleSubmit : step == 2 ? formik3.handleSubmit : formik4.handleSubmit}>

                                        {step == 1 ?
                                            <>


                                                <div className="form-row">
                                                    <select className="select-text" name="country_code"  {...formik2.getFieldProps("country_code")} >
                                                        <option className="option-margin" value="">Select</option>
                                                        {country && country.map((option, index) => (
                                                            <option className="option-margin" value={option.id} key={index} >{option.text} </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {formik2.touched.country_code && formik2.errors.country_code ? <div className="text-danger">{formik2.errors.country_code}</div> : null}
                                                <div className="form-row">
                                                    <i className="fa fa-phone"></i>
                                                    <input type="text" autoComplete="off" name="phone_num" {...formik2.getFieldProps("phone_num")} />

                                                </div>
                                                {formik2.touched.phone_num && formik2.errors.phone_num ? <div className="text-danger">{formik2.errors.phone_num}</div> : null}
                                                {invalidNumber ?
                                                    <>
                                                        <div className="text-danger">{invalidNumber}</div>
                                                    </>
                                                    : ''}
                                                <p className="mb-0 f-14">We need your phone number so we can give updates on your moves</p>

                                                <div className="form-row">
                                                    <button className="full" type="button" onClick={sentOTP} ><span>Send Code</span></button>
                                                </div>
                                            </>

                                            : ''}
                                        {step == 2 ?
                                            <>
                                                <p className="mb-0 f-14">Enter verification code sent to {formik2.values.phone_num} </p>
                                                <div className="form-row">
                                                    <i className="fa fa-phone"></i>
                                                    <input type="text" name="OTP" {...formik3.getFieldProps("OTP")} />

                                                </div>
                                                {formik3.touched.OTP && formik3.errors.OTP ? <div className="text-danger">{formik3.errors.OTP}</div> : null}
                                                {invalidOTP && formik3.errors.OTP == '' ?
                                                    <>
                                                        <div className="text-danger">{invalidOTP}</div>
                                                    </>
                                                    : ''}
                                                <div className="form-row">
                                                    <button className="full" type="button" onClick={ValidateOtp} ><span>Continue</span></button>
                                                </div>
                                            </>
                                            : ''}

                                        {step == 3 ?
                                            <>
                                                <p className="mb-0 f-14">Take a mintue to complete survey</p>
                                                <div className="form-row">
                                                    <select className="select-text" name="about_move_it" {...formik4.getFieldProps("about_move_it")}>
                                                        <option className="option-margin" value="">Select</option>
                                                        {aboutList.length > 0 && aboutList.map((option, index) => (
                                                            <option className="option-margin" value={option.value} key={index} >{option.key}</option>
                                                        ))}
                                                    </select>
                                                    {formik4.touched.about_move_it && formik4.errors.about_move_it ? <div className="text-danger">{formik4.errors.about_move_it}</div> : null}
                                                </div>
                                                <div className="form-row">
                                                    <button type="submit" className="full"  ><span>Continue</span></button>
                                                </div>
                                            </>
                                            : ''}


                                        {/*<div className="form-row"></div>
                    <div className="forgot-password f-14">We need your phone number so we can give updates on your moves</div>
                     
                     <div className="forgot-password f-16">
                     <br></br> Not Recevied OTP <Link  to="" >Resend OTP</Link> </div>*/}



                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {zerotrue && loginPage == false &&
                <>
                    <main>
                        <section className="work white mobile-paddtop">
                            <article className="container">
                                <div className="row d-flex align-center" data-aos="fade-left" data-aos-delay="100">

                                    <div className="col-md-6">
                                        <div className="contact-wrapper">
                                            <div className="col-md-12">
                                                <div className="title">
                                                    <h3 className="text-center">Sign Up</h3>

                                                    <div ref={ref => recaptchaWrapperRef = ref}>

                                                        <div id="recaptcha-container"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <form className="login-page" autoComplete="off" >
                                                <div className="row headshot d-flex justify-content-center customer-loginhead">
                                                    <div className="avatar-upload mt-0 mb-0">
                                                        <div className="avatar-edit">
                                                            <input type="file" id="imageUpload_profile" accept="image/png, image/gif, image/jpeg" onChange={acceptedFiles} />
                                                            <label htmlFor="imageUpload_profile"></label>

                                                        </div>
                                                        <div className="avatar-preview">
                                                            <div id="imagePreview" style={{ backgroundImage: formik5.values.photo_url ? "url(" + formik5.values.photo_url + ")" : "url(web/img/default-profile.png)" }}></div>
                                                        </div>
                                                    </div>
                                                    {formik5.touched.photo_url && formik5.errors.photo_url ? <div className="col-12 col-md-12 col-lg-10 text-danger text-center">{formik5.errors.photo_url}</div> : ''}
                                                </div>

                                                <div className="row" >
                                                    <div className="col-md-6 login-page fnt">
                                                        <div className="form-row">
                                                            <i className="fa fa-user" style={{ left: '20px' }}></i>
                                                            <input type="text" name="first_name" autoComplete="off"  {...formik5.getFieldProps("first_name")} />
                                                            <span>First Name</span>
                                                        </div>
                                                        {formik5.touched.first_name && formik5.errors.first_name ? <span className="text-danger">{formik5.errors.first_name}</span> : null}
                                                    </div>
                                                    <div className="col-md-6 login-page fnt">
                                                        <div className="form-row">
                                                            <i className="fa fa-user" style={{ left: '20px' }}></i>

                                                            <input type="text" name="last_name" autoComplete="off"  {...formik5.getFieldProps("last_name")} />

                                                            <span>Last Name</span>

                                                        </div>

                                                        {formik5.touched.last_name && formik5.errors.last_name ? <span className="text-danger">{formik5.errors.last_name}</span> : null}
                                                    </div>
                                                </div>
                                                <div className="form-row mb-0">
                                                    <i className="fa fa-envelope" style={{ left: '20px' }}></i>

                                                    <input type="email_id" name="email_id" autoComplete="off" onBlur={(e) => checkEmailExist(e.target.value)}
                                                    />
                                                    <span>Email</span>

                                                </div>
                                                {formik5.touched.email_id && formik5.errors.email_id ? <span className="text-danger">{formik5.errors.email_id}</span> : null}
                                                {formik5.values.checkEmail ? <span className="text-danger">Email already exists</span> : null}
                                                <div className="form-row mb-0">
                                                    <i className="fa fa-lock" style={{ left: '20px' }}></i>
                                                    <input id="password-field" autoComplete="new-password" name="password" type={signuppasswordType}  {...formik5.getFieldProps("password")} />
                                                    <span>Password</span>
                                                    <div toggle="#password-field" className={(signuppasswordType == "password" ? 'fa fa-fw fa-eye-slash field-icon toggle-password ' : 'fa fa-fw field-icon toggle-password fa-eye')} onClick={signuptogglePassword}></div>

                                                </div>
                                                {formik5.touched.password && formik5.errors.password ? <span className="text-danger">{formik5.errors.password}</span> : null}
                                                <div className="row" >
                                                    <div className="col-md-5 login-page fnt">

                                                        <div className="form-row phonecountry">
                                                            <div className="select-input">
                                                                <select className="select-text" name="dial_code"  {...formik5.getFieldProps("dial_code")} >
                                                                    <option className="option-margin" value="">Select</option>
                                                                    {signupcountry && signupcountry.map((option, index) => (
                                                                        <option className="option-margin" value={option.id} key={index} >{option.text} </option>
                                                                    ))}
                                                                </select>
                                                                <label className="select-label">Select Country</label>
                                                            </div>
                                                        </div>
                                                        {formik5.touched.dial_code && formik5.errors.dial_code ? <span className="text-danger">{formik5.errors.dial_code}</span> : null}
                                                    </div>
                                                    <div className="col-md-7 login-page fnt">
                                                        <div className="form-row">
                                                            <i className="fa fa-phone" style={{ left: '20px' }}></i>
                                                            <input type="text" autoComplete="off" name="phone_num" {...formik5.getFieldProps("phone_num")} />
                                                            <span>Phone No.</span>
                                                        </div>
                                                        {formik5.touched.phone_num && formik5.errors.phone_num ? <span className="text-danger">{formik5.errors.phone_num}</span> : null}
                                                    </div>


                                                    <div className="col-md-12 login-page fnt mt10px">
                                                        <div className="select-input">
                                                            <select className="select-text" value={formik5.values.about_move_it} onChange={(e) => formik5.setFieldValue("about_move_it", e.target.value)}>
                                                                <option className="option-margin" value=""></option>
                                                                {signupaboutList.length > 0 && signupaboutList.map((option, index) => (
                                                                    <option className="option-margin" value={option.value} key={index} >{option.key}</option>
                                                                ))}
                                                            </select>
                                                            <label className="select-label">How did you hear about Move It?</label>
                                                        </div>
                                                        {formik5.touched.about_move_it && formik5.errors.about_move_it ? <span className="text-danger">{formik5.errors.about_move_it}</span> : null}
                                                    </div>

                                                </div>

                                                <label className="checkbox">
                                                    <input type="checkbox" name="term_and_condition"   {...formik5.getFieldProps("term_and_condition")} className="checkbox__system-checkbox" />
                                                    <span className="checkbox__custom-checkbox"></span>
                                                    <span className="checkbox__label">By clicking on sign up button you agree to our
                                                        <Link to="/terms-condition" target="_blank" style={{ fontSize: "12px" }}> Terms & Conditions</Link> and <Link to="/privacy-policy" target="_blank" style={{ fontSize: "12px" }}>Privacy Policy</Link></span>
                                                </label>
                                                {formik5.touched.term_and_condition && formik5.errors.term_and_condition ? <span className="text-danger">{formik5.errors.term_and_condition}</span> : null}
                                                <div className="form-row">
                                                    <button className="full" disabled={loading} type="button" onClick={(e) => finalSubmit(e)}   ><span>Sign Up</span></button>
                                                </div>

                                            </form>

                                            <div className="socials-wrapper">
                                                {/*<div className="s-account">
        							  <h2>Sign Up with your Social Account</h2>
        							</div>
        							<ul>
        							  <li><Link to="#" className="facebook"><i className="fab fa-facebook-square"></i></Link></li>
        							  <li><Link to="#" className="twitter"><i className="fab fa-google"></i></Link></li>
        							</ul>*/}

                                                <h6 className="text-center dont-acct">Already have an account? <Link to="" onClick={() => setLoginPage(true)}>Login</Link></h6>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <img src="web/img/06.jpg" alt="moveit" className="login-right-img" />
                                    </div>

                                </div>
                            </article>
                        </section>
                    </main>
                    {/* <Footer loading={loading} ></Footer> */}
                    <div className={show ? "modal display-block" : "modal display-none"} id="otp-model" tabIndex="-1" role="dialog" aria-labelledby="def" aria-hidden="true">
                        <div className="modal-dialog modal-sm" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title" id="myModalLabel2">Enter OTP</h4>
                                    <button type="button" className="close" onClick={hideModal} ><span aria-hidden="true" onClick={hideModal}>&times;</span></button>
                                </div>
                                <div className="modal-body">
                                    <form className="login-page" onSubmit={handleSubmit}>
                                        <p className="mb-0 f-14">Please enter the OTP sent on your mobile phone number.</p>
                                        <div className="form-row">
                                            <i className="fa fa-phone"></i>
                                            <input type="text" onChange={setOTPValue} />

                                        </div>

                                        {/*<div className="form-row"></div>
                <div className="forgot-password f-14">We need your phone number so we can give updates on your moves</div>
                    
                    <div className="forgot-password f-16">
                    <br></br> Not Recevied OTP <Link  to="" >Resend OTP</Link> </div>*/}

                                        <div className="form-row">
                                            <button className="full" type="button" onClick={singupValidateOtp} ><span>Sign Up</span></button>
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

const StepSeven = (props) => {
    useEffect(() => {
        props.setloading(true)
        getCustomerStripeClientID();
    }, [])

    const getCustomerStripeClientID = () => {
        props.setloading(true)
        api.postApi('get-customer-stripe-client-secret-id').then(response => {
            props.data.payment_method_nonce = response.setupIntent
            // console.log(response)
            props.next(props.data, false);
        }).catch(error => {
            props.setloading(false)
            // console.log(error)
            toast.error(error.message);
        });
    }

    return (
        <>

        </>
    )
}

const StepEight = (props) => {
    const stripePromise = loadStripe(props.config.STRIPE_PUBLIC_KEY);

    const options = {
        // passing the SetupIntent's client secret
        clientSecret: props.data.payment_method_nonce,
        // Fully customizable with appearance API.
        // appearance: {/*...*/},
    };
    const getPaymentResponse = (response,radarSession_id) => {
        if (response.error) {
            toast.error(response.error.message);
        } else {
            props.data.multi_use_payment_id = response.setupIntent.payment_method;
            props.data.radar_session_id = radarSession_id;
            props.next(props.data, true);
            localStorage.setItem('final', [13])
        }
    }
    // for click on browser back button than back by steps
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "on browser back click", props.setCurrentStep((prev) => prev - 3));
    };
    return (
        <>

            <Elements stripe={stripePromise}  options={options}>
                <SetupForm getPaymentResponse={getPaymentResponse} properties={props} toast={toast} />
            </Elements>
        </>
    )
}

export default BookMove;


