import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import { useNavigate } from 'react-router-dom';
import Service from './../../service';
import toast, { Toaster } from 'react-hot-toast';
import HelperSideBar from './../layout/HelperSideBar';
import alertConfirm, { Button } from "react-alert-confirm";
import MetaHelmet from './../metaHelmet'; //Include metaHelmet


const api = new Service();
const PendingScreen = (props) => {
    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const [helperProfile, sethelperProfile] = useState({});
    const [verifiedstatus, setVerifiedstatus] = useState(true)

    useEffect(() => {
        GethelperProfile();
    }, [])
    const GethelperProfile = () => {
        setloading(true)
        api.getApiHelper('get-helper-info').then(response => {
            console.log(response)
            if (response && response.is_verified == 0) {
                setloading(false);
                sethelperProfile(response);
                setVerifiedstatus(true);
            } else if (response.is_verified == 2) {
                setloading(false);
                console.log(response)
                sethelperProfile(response);
                setVerifiedstatus(false);
            }
            else {
                navigate('/helper-profile')
                setloading(false);
            }
        }).catch(error => {
            setloading(false);
            toast.error(error.message);
        });
    }

    const logOut = (e) => {
        alertConfirm({
            title: "",
            content: "Are you sure you want to log-out?",
            style: { position: "fixed" },
            className: "setconfirm",
            footer(dispatch) {
                return (
                    <>
                        <Button className="yesnobtn" onClick={() => dispatch('ok')}><span>Yes</span></Button>
                        <Button className="yesnobtn-no" onClick={() => dispatch('no')} styleType="primary">
                            <span>NO</span>
                        </Button>
                    </>
                );
            },
            async closeBefore(action, close) {
                if (action == 'no') {
                    close();

                } else {
                    setloading(true);
                    api.postApiHelper('helper-sign-out').then(response => {
                        localStorage.clear();
                        setloading(false);
                        toast.success("Logout successfully");
                        close();
                        navigate('/helper-login')
                    }).catch(error => {
                        setloading(false);
                        toast.error(error.message);
                    });
                }
            }
        });
    }


    const [config, setconfig] = useState(props.config);


    useEffect(() => {
        document.title = "Move It | Helper Pending";
        window.gtag('config', config.G_TAG, {
            page_title: document.title,
            page_path: window.location.pathname,
        });
    }, []);

    return (
        <>
            <MetaHelmet title="Move It | Helper Pending" description="" />
            <Header />

            <main>
                <section className="work white p40">
                    <article className="container">
                        <div className="row">
                            <HelperSideBar helperProfile={helperProfile} />
                            {verifiedstatus == true &&
                                <div className="col-lg-8">
                                    <div className="ufg-job-application-wrapper pt30">
                                        <div className="form-steps" style={{ display: "block" }}>
                                            <div className="form-card-verifycation">
                                                <div className="col-12 col-sm-12 mb-5">

                                                    <img alt="" src="web/img/pending_verification_icon.png" className="imgpnd" />
                                                    <h2></h2>
                                                    <h2 className="fs-title text-center mb-0">Pending Verification</h2>
                                                    <h4 className="text-center">Your registration process is under review by admin it will take 3-7 days   to verify your account.</h4>
                                                    <p className="text-center">You will be notified by the Move it admin via email and/or app notification. </p>
                                                    <div className="col-12 col-sm-12 text-center">

                                                        <Link to="" className="logutbutton" onClick={(e) => logOut(e)} style={{ color: "#fff", padding: "10px 20px", display: "inline-block", marginTop: "10px", background: "#ff95a2" }} ><span>Logout <i className="fas fa-sign-out-alt"></i></span></Link>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {verifiedstatus == false &&
                                <div className="col-lg-8">
                                    <div className="ufg-job-application-wrapper pt30">
                                        <div className="form-steps" style={{ display: "block" }}>
                                            <div className="form-card-verifycation">
                                                <div className="col-12 col-sm-12 mb-5">

                                                    <img alt="" src="web/img/pending_verification_icon.png" className="imgpnd" />
                                                    <h2></h2>
                                                    <h2 className="fs-title text-center mb-0">Your registration process is Rejected by Admin, please signup again !</h2>
                                                    {/* <h4 className="text-center">Your registration process is under review by admin it will take 3-7 days   to verify your account.</h4>
                                                     <p className="text-center">You will be notified by the Move it admin via email and/or app notification. </p> */}
                                                    <div className="col-12 col-sm-12 text-center">

                                                        <Link to="" className="logutbutton" onClick={(e) => logOut(e)} style={{ color: "#fff", padding: "10px 20px", display: "inline-block", marginTop: "10px", background: "#ff95a2" }} ><span>Logout <i className="fas fa-sign-out-alt"></i></span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </article>
                </section>
            </main>
            <Footer loading={loading} />
        </>
    )
}

export default PendingScreen;