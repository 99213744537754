
import React from 'react';
import Header from './layout/Header'; 
import Footer from './layout/Footer';

const PageNotFound= () =>{

    
    return (
        <>
        <main>

            <section  >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                                <img src="web/img/404.png" alt="Page Not Found" />
                        </div>
                    </div> 
                  </div>
            </section>
        </main>
         </>
    )
}
  

export default PageNotFound;