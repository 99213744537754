import React from 'react'
import { Link } from 'react-router-dom';
import Footer from "../components/layout/Footer";
import Header from '../components/layout/Header';

const SmallBusinessMove = () => {
    return (
        <>
            <Header />
            <section className="heroBanner SmallBusinessMove">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-5">
                            <br /><br />
                            <h1>Small Business Move</h1>
                            <h4>ON DEMAND DELIVERY &amp; MOVING</h4>
                            <h5>Truck &amp; Muscle Anytime You Need It ™</h5>
                            <br /><br /><br />
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mv_aboutus2">
                                <img src="web/img/seo/business-move.png" alt="small-business-move" className="img-responsive" /></div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-item-content">
                                <span>Relocating your business has never been easier Business Move?</span>
                                <h3> Move It has your back.</h3>
                                <p>Find help moving desks, chairs, shelves, and more. More convenient, faster, and cheaper than traditional moving companies.
                                    <br /><br />
                                    Truck &amp; Muscle Anytime You Need It ™
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service-area lightBgColor">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <div className="position-relative">
                                <img className="w-100" src="web/img/seo/whychoosemoveit.jpeg" alt="about" />
                                <div className="position-absolute bottom left w-100">
                                    <img className="svg-injector w-100 ie-h-34" src="web/img/seo/shape-4.png" alt="svg" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            {/* Heading Start */}
                            <div className="mb-md-6 mb-4">
                                <h2 className="font-weight-bold mb-0">Why Choose Move It</h2>
                                <p className="mb-0">On Demand Delivery and Move Service</p>
                            </div>
                            {/* Heading End */}
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="list-unstyled mb-3 mb-md-0 pt-0">
                                        <li className="mb-3">
                                            <span className="badge badge-rounded-circle badge-primary-soft mr-2">
                                                <i className="fa fa-check" />
                                            </span>
                                            On Demand Scheduling
                                        </li>
                                        <li className="mb-3">
                                            <span className="badge badge-rounded-circle badge-primary-soft mr-2">
                                                <i className="fa fa-check" />
                                            </span>
                                            Guaranteed Pricing
                                        </li>
                                        <li>
                                            <span className="badge badge-rounded-circle badge-primary-soft mr-2">
                                                <i className="fa fa-check" />
                                            </span>
                                            Quick and Easy
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    {/* List Start */}
                                    <ul className="list-unstyled mb-0 pt-0">
                                        <li className="mb-3">
                                            <span className="badge badge-rounded-circle badge-primary-soft mr-2">
                                                <i className="fa fa-check" />
                                            </span>
                                            Customer Reviewed
                                        </li>
                                        <li className="mb-3">
                                            <span className="badge badge-rounded-circle badge-primary-soft mr-2">
                                                <i className="fa fa-check" />
                                            </span>
                                            You are Protected
                                        </li>
                                        <li>
                                            <span className="badge badge-rounded-circle badge-primary-soft mr-2">
                                                <i className="fa fa-check" />
                                            </span>
                                            Superior Service
                                        </li>
                                    </ul>
                                    {/* List End */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service-area bgWhite">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <span>Move It Job</span>
                            <h2>How to book</h2>
                        </div>
                    </div>
                    <div className="row align-item-center justify-content-center hidetimeline-section">
                        <div className="col-md-12 col-lg-12">
                            <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events timeline-slide swiper-backface-hidden">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide swiper-slide-active"><h4 className="title">01</h4><h5 className="sub-title">Enter Your Details</h5><p className="content">Tell us when, where, and what you need delivered or moved</p></div>
                                    <div className="swiper-slide swiper-slide-next"><h4 className="title">02</h4><h5 className="sub-title">Get Your Guaranteed Price</h5><p className="content">Select how many helpers you need and get an instant price quote.</p></div>
                                    <div className="swiper-slide"><h4 className="title">03</h4><h5 className="sub-title">Book Your Move It Pros and/or Muscles</h5><p className="content">Get connected to and scheduled with Move It Pros and Muscles right away..</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-area lightBgColor">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-item-content">
                                <span>Move It</span>
                                <h2>Other Services Provided</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <ul className="otherService">
                                <li><Link to="/donate-furniture">Donate Furniture</Link></li>
                                <li><Link to="/uber-for-moving">Uber For Moving</Link></li>
                                <li><Link to="/same-day-junk-removal">Same Day Junk Removal</Link></li>
                                <li><Link to="/apartment-move">Apartment Move</Link></li>
                                <li><Link to="/move-without-car">Move Without Car</Link></li>
                                <li><Link to="/rent-pickup-truck-for-moving">Rent Pickup Truck For Moving</Link></li>
                                <li><Link to="/small-business-move">Small Business Move</Link></li>
                                <li><Link to="/costco-delivery">Costco Delivery</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3">
                            <ul className="otherService">
                                <li><Link to="/delivery-service-app">Delivery Service App</Link></li>
                                {/* <li><Link to="/couch-moving">Couch Moving</Link></li> */}
                                <li><Link to="/moving-help">Moving Help</Link></li>
                                <li><Link to="/couch-moving">Couch Moving Service</Link></li>
                                {/* <li><Link to="/refrigerator-mover">Refrigerator Mover</Link></li> */}
                                {/* <li><Link to="/fast-delivery">Fast Delivery</Link></li> */}
                                <li><Link to="/furniture-delivery">Furniture Delivery</Link></li>
                                <li><Link to="/direct-store-delivery">Direct Store Delivery</Link></li>
                                <li><Link to="/on-demand-delivery">On Demand Delivery</Link></li>
                                <li><Link to="/sams-club-delivery">Sam's Club Delivery</Link></li>
                                <li><Link to="/truck-loading-and-unloading">Truck Loading/Unloading</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3">
                            <ul className="otherService">
                                {/* <li><Link to="/hardware-delivery">Hardware Delivery</Link></li> */}
                                <li><Link to="/move-washer-and-dryer">Move Washer &amp; Dryer</Link></li>
                                {/* <li><Link to="/moving-alone">Moving Alone</Link></li> */}
                                <li><Link to="/lumber-hauling-and-delivery">Lumber Hauling &amp; Delivery</Link></li>
                                <li><Link to="/last-mile-delivery">Last Mile Delivery</Link></li>
                                <li><Link to="/appliance-delivery">Appliance Delivery</Link></li>
                                <li><Link to="/assisted-living-movers-senior-moving">Assisted Senior Moving</Link></li>
                                <li><Link to="/local-delivery">Local Delivery</Link></li>
                                <li><Link to="/college-dorm-moving">College Dorm Moving</Link></li>
                                <li><Link to="/last-minute-movers">Last Minute Movers</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3">
                            <ul className="otherService">
                                <li><Link to="/christmas-tree-delivery">Christmas Tree Delivery</Link></li>
                                <li><Link to="/christmas-tree-recycle">Christmas Tree Recycle</Link></li>
                                <li><Link to="/affordable-moving">Affordable Moving</Link></li>
                                <li><Link to="/used-item-delivery">Used Item Delivery</Link></li>
                                <li><Link to="/estate-sales">Estate Sales</Link></li>
                                <li><Link to="/garage-sales">Garage Sales</Link></li>
                                <li><Link to="/moving-pods">Moving PODS &amp; Portable Storage Loading Help</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cityAvaible">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 ">
                            <div className="live__map_wrap">
                                <Link className="loc_map-mark" data-id title="Denver, CO" to="/city" style={{ top: '40%', left: '32.5%' }}>
                                    <img src="web/img/seo/google-maps.svg" data-src="web/img/seo/google-maps.svg" alt="location" />
                                    <div className="pulse" />
                                </Link>
                                <Link className="loc_map-mark" data-id title="Las Vegas, NV" to="/city" style={{ top: '49%', left: '13.5%' }}>
                                    <img src="web/img/seo/google-maps.svg" data-src="web/img/seo/google-maps.svg" alt="location" />
                                    <div className="pulse" />
                                </Link>
                                <Link className="loc_map-mark sandngo" data-id title="San Diego, CA" to="/city" style={{ top: '57%', left: '7.9%' }}>
                                    <img src="web/img/seo/google-maps.svg" data-src="web/img/seo/google-maps.svg" alt="location" />
                                    <div className="pulse" />
                                </Link>
                                <Link className="loc_map-mark" data-id title="Tucson, AZ" to="/city" style={{ top: '60%', left: '20%' }}>
                                    <img src="web/img/seo/google-maps.svg" data-src="web/img/seo/google-maps.svg" alt="location" />
                                    <div className="pulse" />
                                </Link>
                                <Link className="loc_map-mark" data-id title="Phoenix, AZ" to="/city" style={{ top: '58%', left: '19.5%' }}>
                                    <img src="web/img/seo/google-maps.svg" data-src="web/img/seo/google-maps.svg" alt="location" />
                                    <div className="pulse" />
                                </Link>
                                <Link className="loc_map-mark" data-id title="Albuquerque" to="/city" style={{ top: '47%', left: '25.5%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Salt Lake City, UT" to="/city" style={{ top: '26%', left: '23.5%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Austin, TX" to="/city" style={{ top: '70%', left: '47%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Dallas, TX" to="/city" style={{ top: '66%', left: '47%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="San Jose, CA" to="/city" style={{ top: '38%', left: '1%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Los Angeles, CA" to="/city" style={{ top: '51%', left: '4.5%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark m-bile" data-id title="Seattle, WA" to="/city" style={{ top: '8%', left: '7%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Chicago, IL" to="/city" style={{ top: '33%', left: '64.5%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Houston, TX" to="/city" style={{ top: '71.5%', left: '52.4%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Atlanta, GA" to="/city" style={{ top: '57%', left: '73.5%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark mia-bile" data-id title="Miami, FL" to="/city" style={{ top: '81%', left: '84%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="San Francisco, CA" to="/city" style={{ top: '36%', left: '0%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Boston, MA" to="/city" style={{ top: '23.5%', left: '93.5%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="New York, NY" to="/city" style={{ top: '28%', left: '89%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Nashville, TN" to="/city" style={{ top: '51.25%', left: '68%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Dallas, TX" to="/city" style={{ top: '66%', left: '47%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Charlotte, NC" to="/city" style={{ top: '52.5%', left: '80%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Indianapolis, IN" to="/city" style={{ top: '39.65%', left: '68%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="St. Louis, MO" to="/city" style={{ top: '41.65%', left: '58%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Orange County, CA" to="/city" style={{ top: '52.5%', left: '6.5%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Cleveland, OH" to="/city" style={{ top: '33.5%', left: '75%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Minneapolis, MN" to="/city" style={{ top: '23.5%', left: '54.5%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Philadelphia, PA" to="/city" style={{ top: '34%', left: '87.2%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Kansas City, MO" to="/city" style={{ top: '40.65%', left: '50%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Boise, ID" to="/city" style={{ top: '19%', left: '13%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link><Link className="loc_map-mark" data-id title="Little Rock, AR" to="/city" style={{ top: '51.65%', left: '53%' }}>
                                    <img src="web/img/seo/map-pin1.svg" data-src="web/img/seo/map-pin1.svg" alt="location" style={{ width: 15 }} /></Link>
                                <img className="map_img" src="web/img/seo/old-moveit-map.svg" data-src="web/img/seo/old-moveit-map.svg" alt="location" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="servingbox newtop">
                                <img src="web/img/seo/google-maps.svg" alt="location" /> <span className="f-26"> Available Now </span>
                                <ul className="citylist">
                                    <li><Link to="#">Las Vegas, NV</Link></li>
                                    <li><Link to="#">Tucson, AZ</Link></li>
                                    <li><Link to="#">Denver, CO</Link></li>
                                    <li><Link to="#">Phoenix, AZ</Link></li>
                                    <li><Link to="#">San Diego, CA</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Link className="section red padd-50" to>
                <article className="text-center">
                    <h6 className="h2">Still have  <strong> questions? </strong></h6>
                    <span className="button outline inverse">Contact Us <i className="fa fa-chevron-right" /></span>
                </article>
            </Link>
            <Footer />
        </>
    )
}
export default SmallBusinessMove


