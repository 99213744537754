import {Link } from 'react-router-dom';
import Service from './../../service'; 
import toast, { } from 'react-hot-toast';
import React, {useState, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from './../loading';

import { useNavigate } from 'react-router-dom';
import alertConfirm, { Button } from "react-alert-confirm";

const api = new Service();
const ProfileSection=(props)=>{
    const [loading, setloading] = useState(false);
    const [data, setdata] = useState({});
    const navigate = useNavigate();
    useEffect(() => {
       GetCustomerProfile();
    }, [])
    const GetCustomerProfile = () => {
        setloading(true); 
        api.getApi('get-customer-profile').then(response => {
            setdata(response.data);
            setloading(false); 
        }).catch(error => {
            setloading(false);
             toast.error(error.message);
        });
    }
    
    const UploadProfile = e => {
        setloading(true); 
        var formData = new FormData();
        formData.append("photo_url",e.target.files[0]);
        api.postApi('update-customer-profile-image',formData, true).then(response => {
            toast.success(response.message);
            GetCustomerProfile();
            
        }).catch(error => {
            setloading(false);
             toast.error(error.message);
        }); 
    }
 
    const logOut = (e) => {
        alertConfirm({ 
            title: "",
            content: "Are you sure you want to log-out?",
            style: {position:"fixed"},
            className:"setconfirm",
            footer(dispatch) {
                return (
                  <>
                    <Button className="yesnobtn" onClick={() => dispatch('ok')}><span>Yes</span></Button>
                    <Button className="yesnobtn-no" onClick={() => dispatch('no')} styleType="primary">
                      <span>NO</span>
                    </Button>
                  </>
                );
            },
            async closeBefore(action, close) {
                if (action == 'no') {
                    close();
                    
                } else {
                    setloading(true); 
                    api.postApi('customer-sign-out').then(response => {
                        localStorage.clear();
                        setloading(false);
                        toast.success("Logout successfully");
                        close();
                        navigate('/login')
                    }).catch(error => {  
                        setloading(false);
                        close();
                        toast.error(error.message);
                    });
                    
                }
            }
        });
    }
    return (
        <>
        <section className="pto-profile">
		   <div className="container"> 
		      <div className="row">
				    <div className="avatar-upload">
						<div className="avatar-edit">
							<input type='file' id="imageUpload"  accept="image/png, image/jpeg , image/jpg"  onChange={UploadProfile}/>
							<label htmlFor="imageUpload"></label>
						</div>
						<div className="avatar-preview">
							<div id="imagePreview" style={{backgroundImage: data.photo_url ? "url("+data.photo_url+")" :  "url(web/img/default-profile.png)"}} >
							</div>
						</div>
					</div>	
				 <div className="col-sm-12 col-lg-9">
				    <h3 className="customename">{data?data.name:''}</h3>
					<p className="cusotmemail">{data?data.email_id:''}</p>
					
					<Link to="" className="lgout"  onClick={(e) => logOut(e) } ><span>Logout <i className="fas fa-sign-out-alt"></i></span></Link>
				 </div>
			  </div>
		   </div>
		</section>
		 {/* <Loading loading={loading == true? true : false } /> */}
	   </> 
    );  
}
export default ProfileSection;