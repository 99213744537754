import { Link } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import MetaHelmet from './../metaHelmet'; //Include metaHelmet
import { useEffect, useState } from 'react';

const HowItWork = (props) => {

	const [config, setconfig] = useState(props.config);


	useEffect(() => {
		document.title = "Move It | How It Work";
		window.gtag('config', config.G_TAG, {
			page_title: document.title,
			page_path: window.location.pathname,
		});
	}, []);


	return (
		<>
			<MetaHelmet title="Move It | How It Work" description="" />
			{/* <Toaster position="top-right" reverseOrder={false} /> */}
			<Header></Header>
			<main>
				<section className="hero clean faq-andimg" style={{ backgroundImage: `url('web/img/05.jpg')` }}>
					<article>
						<div className="grid">
							<div className="unit-2-3 unit-1-1-md">
								<h1 className="h2 f-46">How Move It Works </h1>
							</div>
						</div>
					</article>
				</section>


				<section className="slate cspt-steps-counter">
					<article>
						<h3 className="margin-bottom-lg text-center howitworkpage"><span className="flourish text"><span className="flourish-square f-22"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(255,149,162)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span> How to book your Move It job</span></h3>



						<div className="vc_row wpb_row vc_inner row cspt-steps-counter cspt-row-inner cspt-bg-color-yes cspt-bg-color-transparent cspt-zindex-zero">

							<div className="vc_column_container col-sm-6 col-md-4 col-lg-4 cspt-column-inner cspt-bg-color-yes cspt-bg-color-transparent cspt-zindex-zero">
								<div className="vc_column-inner "><div className="wpb_wrapper">
									<div className="cspt-ihbox cspt-ihbox-style-5">
										<div className="cspt-ihbox-icon">
											<div className="cspt-ihbox-icon-wrapper">
												<span className="numberstep">01</span>
												<small>Step</small>
											</div>
										</div>
										<div className="cspt-ihbox-contents">
											<div className="cspt-ihbox-heading"><h2 className="vc_custom_heading">Enter Your Details</h2></div>
											<div className="cspt-ihbox-content">Tell us when, where, and what you need delivered or moved</div>
										</div>
									</div>
								</div>
								</div>
							</div>


							<div className="vc_column_container col-sm-6 col-md-4 col-lg-4 cspt-column-inner cspt-bg-color-yes cspt-bg-color-transparent cspt-zindex-zero">
								<div className="vc_column-inner ">
									<div className="wpb_wrapper">
										<div className="cspt-ihbox cspt-ihbox-style-5 ">
											<div className="cspt-ihbox-icon">
												<div className="cspt-ihbox-icon-wrapper">
													<span className="numberstep">02</span>
													<small>Step</small>
												</div>
											</div>

											<div className="cspt-ihbox-contents">
												<div className="cspt-ihbox-heading"><h2 className="vc_custom_heading">Get Your Guaranteed Price</h2></div>
												<div className="cspt-ihbox-content">Select how many helpers you need and get an instant price quote.</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="vc_column_container col-sm-6 col-md-4 col-lg-4 cspt-column-inner cspt-bg-color-yes cspt-bg-color-transparent cspt-zindex-zero">
								<div className="vc_column-inner ">
									<div className="wpb_wrapper">
										<div className="cspt-ihbox cspt-ihbox-style-5 ">
											<div className="cspt-ihbox-icon">
												<div className="cspt-ihbox-icon-wrapper">
													<span className="numberstep">03</span>
													<small>Step</small>
												</div>
											</div>

											<div className="cspt-ihbox-contents">
												<div className="cspt-ihbox-heading"><h2 className="vc_custom_heading">Book Your Move It Pros and/or Muscles</h2></div>
												<div className="cspt-ihbox-content">Get connected to and scheduled with Move It Pros and Muscles right away.</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>





					</article>
				</section>

				<section className="">
					<article>
						<div className="grid grid-center margin-bottom-lg">
							<div className="unit-2-3 unit-1-1-md">
								<h3 className="flourish text"><span className="flourish-square"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(232,154,167)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span>Move It’s revolutionary new app provides the convenience and peace of mind of on demand delivery, moving, junk removal, or just extra muscle service</h3>
							</div>
						</div>
						<div className="highlights">
							<div className="highlight">
								<div className="image">
									<img src="web/img/easy-use.png" alt="" data-aos="fade-left" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out" />
								</div>
								<div className="content">
									<div className="content-container">
										<h4>About Us</h4>
										<p className="mb-0">Find out why Move It has become the most talked about on demand app for small moves and deliveries</p>
										<Link to="/about" className="plink">Learn More</Link>
									</div>
								</div>
							</div>
							<div className="highlight">
								<div className="image">
									<img src="web/img/06.jpg" alt="" data-aos="fade-right" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out" />
								</div>
								<div className="content">
									<div className="content-container">
										<h4>See Our Services</h4>
										<p class="mb-0">Move It Pros & Muscles will</p>
										<p class="mb-0">Carefully load and deliver your items</p>
										<p class="mb-0">Where and when you need</p>
										<Link to="/move-it-services" className="plink">Learn More</Link>
									</div>
								</div>
							</div>
							<div className="highlight">
								<div className="image">
									<img src="web/img/let-go.png" alt="" data-aos="fade-right" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out" />
								</div>
								<div className="content">
									<div className="content-container">
										<h4>Move It For Business</h4>
										<p class="mb-0">Track real-time progress</p>
										<p class="mb-0">In app call & text</p>
										<p class="mb-0">Pay, tip and review in app or online</p>
										<Link to="/moveit-business" className="plink">Learn More</Link>
									</div>
								</div>
							</div>
						</div>
					</article>
				</section>




				<Link className="section red padd-50" to="/contact-us">
					<article className="text-center">
						<h6 className="h2">Still have  <strong> questions? </strong></h6>
						<span className="button outline inverse">Contact Us <i className="fa fa-chevron-right"></i></span>
					</article>
				</Link>


			</main>

			<Footer></Footer>
		</>
	);
}
export default HowItWork;

