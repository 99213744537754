
// import { initializeApp,getApps } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { auth, firestore, messaging, firebase } from './../firebaseConfig'; // Import the initialized Firebase app

// // const firebaseConfig = {
// //   apiKey: "AIzaSyD2PJQNxIODBfpRzEGAYZJijsWhKWmyHcc",
// //   authDomain: "move-it-helper.firebaseapp.com",
// //   databaseURL: "https://move-it-helper.firebaseio.com",
// //   projectId: "move-it-helper",
// //   storageBucket: "move-it-helper.appspot.com",
// //   messagingSenderId: "43017353273",
// //   appId: "1:43017353273:web:dab2085456a1f1b737f5e8",
// //   measurementId: "G-QW7MCKMJTF"
// // };

// const firebaseConfig = {
//   apiKey: "AIzaSyAUxQrPnfK-uSFOqbFfbSFqQQq5iB7F6Qo",
//   authDomain: "move-it-customer-android.firebaseapp.com",
//   projectId: "move-it-customer-android",
//   storageBucket: "move-it-customer-android.appspot.com",
//   messagingSenderId: "749185849516",
//   appId: "1:749185849516:web:049d3ac2b1d035d31e6709",
//   measurementId: "G-SYD8CJWZ60"
// };


// // Initialize Firebase only if it hasn't been initialized yet
// alert(getApps().length)
// if (!getApps().length) {
//    initializeApp(firebaseConfig);
//  }
// const messaging = getMessaging(app);

const requestForToken = () => {
   return getToken(messaging, { vapidKey: `BFsOSzPwQRx1VJ6Vf09-VFWzoEIfB9uL0Y844_IWR3wAd7_M5QPm7UrNGSePsoVb7l7Qu3DyxMcYyODZastaLqQ` });
};

const onMessageListener = () =>
  new Promise((resolve) => {    
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export {onMessageListener, requestForToken};

  