import { useNavigate } from 'react-router-dom';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet
import { useEffect, useState } from 'react';

const RegisterA = (props) => {


    const navigate = useNavigate();

    const SelectServiceType = (e) => {
        navigate('/helper-register/' + e)
    }
    // back button for helper step one
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "on browser back click", navigate('/helper-login'));
    };


    const [config, setconfig] = useState(props.config);


    useEffect(() => {
        document.title = "Move It | Helper Service Type";
        window.gtag('config', config.G_TAG, {
            page_title: document.title,
            page_path: window.location.pathname,
        });
    }, []);

    return (
        <>
            <MetaHelmet title="Move It | Helper Service Type" description="" />
            <main>
                <div className="ugf-wrapper stepone">
                    <div className="ugf-content-block helper-leftbg">
                        <div className="content-block">
                            <h1>How Much Money Can I Make?</h1>
                            <p>Helpers have made Move It their full time jobs</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 offset-lg-6 steppd-0">
                                <div className="ufg-job-application-wrapper pt60 pt30import">
                                    <div className="form-steps d-block">
                                        <h3 className="border-bottom">Select how you want to work with us</h3>

                                        <form action="#" className="job-application-form helper-register">
                                            <div className="row no-gutters d-flex align-items justify-content-center">
                                                <div className="col-6 col-sm-6 col-lg-6 mb-2" onClick={() => SelectServiceType(1)}>
                                                    <input className="checkbox-tools" type="radio" name="tools" id="tool-1" value="" />
                                                    <label className="for-checkbox-tools" htmlFor="tool-1">
                                                        <div className="cardbox">
                                                            <img alt="" src="web/img/move-it-pros.jpg" />
                                                            <h5 className="text-center">Move It Pro</h5>
                                                            <h6 className="text-center">Earn $80+/Hour</h6>
                                                            <p className="text-center">I have a pickup truck, cargo, box truck, or a vehicle with a trailer and can lift over 80 lbs</p>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="col-6 col-sm-6 col-lg-6 mb-2" onClick={() => SelectServiceType(2)}>
                                                    <input className="checkbox-tools" type="radio" name="tools" id="tool-2" value="" />
                                                    <label className="for-checkbox-tools" htmlFor="tool-2">
                                                        <div className="cardbox">
                                                            <img alt="" src="web/img/heper-bgmoveit.jpg" />
                                                            <h5 className="text-center">Move It Muscle</h5>
                                                            <h6 className="text-center">Earn $25+/Hour</h6>
                                                            <p className="text-center">I don't have a pickup truck but can lift over 80 lbs to assist Move It Pros or jobs that need only muscle</p>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="col-12 col-sm-9 col-lg-9 mb-2" onClick={() => SelectServiceType(3)}>
                                                    <input className="checkbox-tools" type="radio" name="tools" id="tool-3" value="" />
                                                    <label className="for-checkbox-tools" htmlFor="tool-3">
                                                        <div className="cardbox">
                                                            <img alt="" src="web/img/moveit-muscle-pro-both.jpg" className="prp-misclimg" />
                                                            <h5 className="text-center">Move It Pro & Muscle</h5>
                                                            <h6 className="text-center"></h6>
                                                            <p className="text-center">I have a pickup truck, cargo, box truck, or a vehicle with but can lift over 80 lbs to assist Move It Pros or jobs</p>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            {/*<div className="row">
                                                <div className="col-md-12">
                                                    <Link to="" className="helperbtn step-btn mt-3"><span>Continue</span></a>
                                                </div>
                                            </div>*/}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default RegisterA;