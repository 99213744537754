
import {Link } from 'react-router-dom';
import Button from 'react-bootstrap-button-loader';
import Service from './../../service'; 
import AsyncSelect from 'react-select/async';

const api = new Service();







// clearValue = () => {
// this.selectRef.select.clearValue();
// };

const loadOptions = async (inputText,callback) => {
	await api.postApi('search_items',{"serach_keyword":inputText}).then(response => { 
		if(response && response.search_items) {
			callback(response.search_items.map(i=>({label:i.name, value:i.subcategory_id}))) 
		}
	}).catch(error => {
		// toast.error(error.message);
	});  
}


const Modal =  ({modal, handleClose, show, verifYOTP, setOTPValue ,  Category, GetSubCategoryList, subCategory,CategoryDetail, itemDetail , setSubCategoryValue, MoveTypeDetail, multiItemform, acceptedFiles, updateQuantity, RemoveItemImage , charges, loading, deleteMove, openImageViewer,handleOnSelect,updateItemAssemble,updateItemCarryWithPeople, updateCustomItemPrice}) => {
    if(modal=='OTP') {
          const showHideClassName = show ? "modal display-block" : "modal display-none";
          return (
            <> 
              <div className={showHideClassName} id="otp-model" tabIndex="-1" role="dialog" aria-labelledby="def" aria-hidden="true">
        		  <div className="modal-dialog modal-sm" role="document">
        			<div className="modal-content">
        			  <div className="modal-header">
        					<h4 className="modal-title" id="myModalLabel2">Enter OTP</h4>
        					<button type="button" className="close" onClick={handleClose} ><span aria-hidden="true"  onClick={handleClose}>&times;</span></button>
        				</div> 
        			  <div className="modal-body">
                        <form className="login-page">
        				<p className="mb-0 f-14">Please enter the OTP sent on your mobile phone number.</p>				
        				<div className="form-row">
        					<i className="fa fa-phone"></i>
        					<input type="text" onChange={setOTPValue} />
        				
        				</div>
        				 
        				{/*<div className="form-row"></div>
        				<div className="forgot-password f-14">We need your phone number so we can give updates on your moves</div>
        				 
        				 <div className="forgot-password f-16">
        				 <br></br> Not Recevied OTP <Link  to="" >Resend OTP</Link> </div>*/}
        				 
        				<div className="form-row">
        					<button className="full" type="button" onClick={verifYOTP} ><span>Sign Up</span></button>
        				</div>
        				 
        				</form>			
        					
        			  </div>
        			</div>
        		  </div>
        		</div>
            </>
          );
    }else if(modal=='CATEGORY') {
        const showHideClassName = show ? "modal right display-block" : "modal display-none";
        return (
            <> 
            <div className={showHideClassName} id="category-item-model" tabIndex="-1" role="dialog" aria-labelledby="def" aria-hidden="true">
    		  <div className="modal-dialog modal-right-side" role="document">
    			<div className="modal-content ufg-job-application-wrapper">
    
    				<div className="modal-header">
    					<h4 className="modal-title" id="myModalLabel2"></h4>
        					<button type="button" className="close" onClick={handleClose} ><span aria-hidden="true"  onClick={handleClose}>&times;</span></button>
    				</div>
    
    				<div className="modal-body job-application-form">
    					 <div className="add-normal-itam-content"> 
    						<div className="row itemstep login-page mtb-0">  
    							 <div className="col-md-12">
    								<p className="border-bottom">What can we move or deliver for you?</p>
    							 </div>	
    							 <div className="col-md-12">
    								<div className="row login-page mtb-0"> 
                                      <div className="col-md-12 mb-3">
										<AsyncSelect className="AsyncSelect" 
										 value={''} 
										 onChange={handleOnSelect} 
										 loadOptions={loadOptions} placeholder="Type your item here..." />

                    				  </div>
                    				</div>
    							 </div>
    							 
    							<div className="col-md-12"> 
    							 <div className="row itemstep">  
    							   {Category.length>0 && Category.map((option, index) => (
        								<div className="col-6 col-md-6 col-lg-6 mb-2"  key={index}>
        									<input className="checkbox-tools checkbox-tools-remove-bg" type="checkbox" name="category_id" id={'category_'+option.category_id} value={option.category_id} onClick={GetSubCategoryList} />
        										<label className="for-checkbox-tools" htmlFor={'category_'+option.category_id}>
        										<div className="cardbox">
        											<img src={option.photo_url} alt=""/>
        											<h5>{option.name}</h5>
        										</div>
        									</label>
        								</div>
        							))}
    							 </div>
    						   </div>
    						</div>
        				    </div>
                         </div>
        			</div>
        		</div>
        	</div>
    	</>
          );
    } else if(modal=='SUB-CATEGORY') {
        const showHideClassName = show ? "modal right display-block" : "modal display-none";
        return (
        <> 
            <div className={showHideClassName} id="subcategory-item-model" tabIndex="-1" role="dialog" aria-labelledby="subcategory-item-model" aria-hidden="true">
        		  <div className="modal-dialog  modal-right-side" role="document">
        			<div className="modal-content ufg-job-application-wrapper">
        
        				<div className="modal-header">
        				    
        					<h4 className="modal-title" id="myModalLabel2"> {CategoryDetail? (
        					<>
        					{CategoryDetail.name}
        					</>
        					):''}</h4>
        					<button type="button" className="close" onClick={handleClose} ><span aria-hidden="true"  onClick={handleClose}>&times;</span></button>
        				</div>
        
        				<div className="modal-body job-application-form">
        					 <div className="add-normal-itam-content"> 
        						<div className="row itemstep login-page mtb-0">  
        							 <div className="col-md-12">
    								<div className="row login-page mtb-0"> 
									<div className="col-md-12 mb-3">
                    					<AsyncSelect className="AsyncSelect" 
										  value={''} 
										 onChange={handleOnSelect} 
										 loadOptions={loadOptions} placeholder="Type your item here..." />
                    				  </div>
                    				</div>
    								
    								
    							 </div>
        							 
        							 <div className="col-md-12">
        								<div className="select-itembox">
        								    {CategoryDetail? (
        									<>
        									<img src={CategoryDetail.photo_url} alt=""/>
        									<h5>{CategoryDetail.name}</h5>
        									</>
        									) : '' }
        								</div> 	
        							 </div>
        							 
        							<div className="col-md-12"> 
        							 <div className="row itemstep">  
        							    {subCategory.length>0 && subCategory.map((option, index) => (
            								<div className="col-6 col-md-6 col-lg-6 mb-2"  key={index}>
            									<input className="checkbox-tools checkbox-tools-remove-bg" type="checkbox" name="subcategory_id" id={'subcategory_'+option.subcategory_id} value={option.subcategory_id}  onClick={setSubCategoryValue} />
            										<label className="for-checkbox-tools"  htmlFor={'subcategory_'+option.subcategory_id} data-toggle="modal" data-target="#add-item-model">
            										<div className="cardbox">
            											<img src={option.photo_url} alt="" />
            											<h5>{option.name}</h5>
            										</div>
            									</label>
            								</div>
        								))}
        							 </div>
        						   </div>
        							 
        						</div>
        				    </div>
                         </div>
        			</div>
        		</div>
        	</div>
        </>
        );
    } else if(modal=='ADD-ITEM') {
        // var myArray = Array.from(multiItemform.values.item_photos, (value, index) => (value));
        const showHideClassName = show ? "modal right display-block" : "modal display-none";
        let spinColor = '#fff';
        let variant = '';
        return (
        <> 
            <div className={showHideClassName}   tabIndex="-1" role="dialog" aria-labelledby="add-item-model" aria-hidden="true">
        		  <div className="modal-dialog  modal-right-side" role="document">
        			<div className="modal-content ufg-job-application-wrapper">
        
        				<div className="modal-header">
        					<h4 className="modal-title" id="myModalLabel2">Add  item's</h4>
        					<button type="button" className="close" onClick={handleClose} ><span aria-hidden="true"  onClick={handleClose}>&times;</span></button>
        				</div>
        
        				<div className="modal-body job-application-form">
        				     <form method="post" encType="multipart/form-data" onSubmit={multiItemform.handleSubmit}>
        					 <div className="add-normal-itam-content">
        					    {MoveTypeDetail && MoveTypeDetail.can_add_item == 1? (
        						<p className="border-bottom">What can we move or deliver for you?</p>
        						) : (
        						<p className="border-bottom">{MoveTypeDetail.add_item_label}</p>
        						)}
        						<div className="row itemstep">  
        							<div className="col-12 col-md-12 col-lg-12 mb-2">
        								<div className="cardbox add-item">
        								     {MoveTypeDetail && MoveTypeDetail.can_add_item == 1 && itemDetail.photo_url != '' ? <img src={itemDetail.photo_url} alt="" /> : <img src={MoveTypeDetail.image_url} alt=""/> }
        								     {MoveTypeDetail && MoveTypeDetail.can_add_item == 1? <h5>{itemDetail.name}</h5> : <h5>{MoveTypeDetail.name}</h5> }
        								</div>
        							</div> 
        						</div> 
        						{MoveTypeDetail && MoveTypeDetail.is_how_many? (
            						<>
            						<div className="h-show qty" style={{marginBottom: "30px"}}>
            							<div className="input-group">
            								<label className="labefifle">{MoveTypeDetail.how_many_label}</label>
            								<input type="button" value="-" className="button-minus" data-field="quantity" onClick={updateQuantity} />
            								<input type="text" name="quantity" min="1" {...multiItemform.getFieldProps("quantity")}  className="quantity-field"  disabled />
            								<input type="button" value="+" className="button-plus" data-field="quantity" onClick={updateQuantity} />
            							</div>
            							{multiItemform.touched.quantity && multiItemform.errors.quantity ? <div  className="text-danger">{multiItemform.errors.quantity}</div> : ''}
            						</div>
            						</>
        						) : '' }
        						
        				
                                
        						{MoveTypeDetail && itemDetail &&  (itemDetail.can_assamble ||itemDetail.is_custom_item)? (
        						<>
            						<div className="form-group check-group">
            							<div className="custom-checkbox">
            							  <input type="checkbox" className="custom-control-input" id="customControlValidation14" onChange={(e) => updateItemAssemble(e)} checked={multiItemform.values.is_assamble} /> 
            							  <label className="custom-control-label" htmlFor="customControlValidation14">Assemble and Disassemble</label>
            							</div>
            							<p className="pstep-from">This is something that needs to be broken down and then reassembled offer moving. If there are any special tools required, please add in the optional details below.</p>
            						</div>
            						
            						{MoveTypeDetail && itemDetail && itemDetail.is_custom_item? (
            						<>
            						   <div className="form-group check-group">
            							<div className="custom-checkbox">
            							  <input type="checkbox" className="custom-control-input" id="customControlValidation154"  onChange={(e) => updateItemCarryWithPeople(e)} checked={multiItemform.values.is_carry_with_people}  /> 
            							  <label className="custom-control-label" htmlFor="customControlValidation154">Carry with 2 people</label>
            							</div>
            							<p className="pstep-from">Does this item need to be carried by two people?</p>
            					    	</div>
            						</>
            						) : '' }
            						
            					</>
        						) : '' }
        						
        						
        					
        						
        						
        						
        						{MoveTypeDetail && MoveTypeDetail.is_additional_info? (
        						<> 
        						<hr />
            						<div className="form-group check-group">
                						<h5>Add Additional Details</h5>
    						         	<p className="pstep-from">Please tell us more about item. Approximate measurements.</p>
            						</div>
            						<div className="h-show qty" id="foo2">
            							<div className="row step-add-item">
            								<div className="col-lg-6 login-page"> 
            									<div className="form-row">
            										<input type="text" name="item_width" onChange={(e) => updateCustomItemPrice(e)} value={multiItemform.values.item_width} />
            										<span>Width (IN)</span>
            									</div>
            									{multiItemform.touched.item_width && multiItemform.errors.item_width ? <div  className="text-danger">{multiItemform.errors.item_width}</div> : ''}
            								</div>
            								<div className="col-lg-6 login-page">
            									<div className="form-row">
            										<input type="text"  name="item_height" {...multiItemform.getFieldProps("item_height")}  />
            										<span>Height (IN)</span>
            									</div>
            									{multiItemform.touched.item_height && multiItemform.errors.item_height ? <div  className="text-danger">{multiItemform.errors.item_height}</div> : ''}
            								</div>
            								<div className="col-lg-6 login-page">
            									<div className="form-row"> 
            										<input type="text"  name="item_depth" {...multiItemform.getFieldProps("item_depth")}  />
            										<span>Depth (IN)</span>
            									</div>
            									{multiItemform.touched.item_depth && multiItemform.errors.item_depth ? <div  className="text-danger">{multiItemform.errors.item_depth}</div> : ''}
            								</div>
            								<div className="col-lg-6 login-page">
            									<div className="form-row">
            										<input type="text"  name="item_weight" {...multiItemform.getFieldProps("item_weight")}  />
            										<span>weigh(lbs)?</span>
            									</div>
            									{multiItemform.touched.item_weight && multiItemform.errors.item_weight ? <div  className="text-danger">{multiItemform.errors.item_weight}</div> : ''}
            								</div>
            								<div className="col-lg-12 login-page">
            									<div className="form-row">
            										<input type="text"  name="additional_info" {...multiItemform.getFieldProps("additional_info")}  />
            										<span>Anything else your helper should know.</span>
            									</div>
            									{multiItemform.touched.additional_info && multiItemform.errors.additional_info ? <div  className="text-danger">{multiItemform.errors.additional_info}</div> : ''}
            								</div>
            							</div>
            						</div>
            					</>
        						) : (
        						     <>
        						          <div className="form-group check-group">
                        					<p className="pstep-from">{MoveTypeDetail.move_info}</p>
                                        </div>
                                        <div className="form-group check-group"> 
                                            <span htmlFor="inputText">{MoveTypeDetail.describe_label}</span>
                                            <textarea className="form-control" name="additional_info" id="inputText" {...multiItemform.getFieldProps("additional_info")}></textarea>
                                            
                                        </div>
                                        {multiItemform.touched.additional_info && multiItemform.errors.additional_info ? <div  className="text-danger">{multiItemform.errors.additional_info}</div> : ''}
                                        </>
        						) }
        						<div className="form-group">
        						    {MoveTypeDetail && MoveTypeDetail.take_photo_label? (
        							<h5>{MoveTypeDetail.take_photo_label}</h5>
        							) : '' }
        							{MoveTypeDetail && MoveTypeDetail.take_photo_label? (
        							<p className="pstep-from">{MoveTypeDetail.sub_take_photo_label}</p>
        							) : '' }
        							<div className="custom-file">
        							<input type="file" disabled={loading} accept="image/png, image/gif, image/jpeg" className="custom-file-input form-control" id="validatedCustomFile" onChange={acceptedFiles} multiple />
                                        
        							  <label className="custom-file-label" htmlFor="validatedCustomFile">{multiItemform.values.item_photos.length ==0? <> <span className="file-return">No file selected</span> </> : '' }</label>
        							  {multiItemform.touched.item_photos && multiItemform.errors.item_photos ? <div  className="text-danger">{multiItemform.errors.item_photos}</div> : ''}
        							</div>
        							{multiItemform.values.item_photos.length>0 && multiItemform.values.item_photos.length<4? (
        						        <>
        							    <div className="row">
            						    {multiItemform.values.item_photos.length>0 && multiItemform.values.item_photos.length<4 && multiItemform.values.item_photos.map((file,Index) => 
            						      <div className="col-md-6" key={Index} >
            						          <img  src={file} className="img-thumbnail mt-2" height={200} width={200}  onClick={ () => openImageViewer(Index) } alt="" />
            						          <Link to="" className="imageCloseButton" onClick={() => RemoveItemImage(Index)}  >X</Link>
            						      </div>
            						    )}
        						        </div>
        						       </>
        						    ) : '' }
        						 </div> 
        						 
        						 <Button loading={loading} spinColor={spinColor} variant={variant} type="submit" className="btn step-btn-bokmove step-btn mt-3 w-100p"><span>Save</span></Button>
        						 
        				    </div>
        				    </form>
                         </div>
        			</div>
        		</div>
        	</div>
        </>
        );
    } else if(modal=='SERVICE-CHARGE') {
        const showHideClassName = show ? "modal right display-block" : "modal display-none";
        return (
        <> 
              <div className={showHideClassName}  id="checkdetail-service" tabIndex="-1" role="dialog" aria-labelledby="checkdetail-service">
        		  <div className="modal-dialog modal-right-side" role="document">
        			<div className="modal-content ufg-job-application-wrapper">
        
        				<div className="modal-header">
        					<h4 className="modal-title" id="myModalLabel2">Service Charges Details</h4>
        					<button type="button" className="close" onClick={handleClose} ><span aria-hidden="true"  onClick={handleClose}>&times;</span></button>
        				</div>
        
        				<div className="modal-body job-application-form">
        					 <div className="add-normal-itam-content">
        						<div className="row itemstep">  
        							<div className="col-6 col-lg-6 mb-2 ttt-price">
        								<b>TOTAL</b>
        							</div>
        							<div className="col-6 col-lg-6 mb-2 tprice">
        								<b>${charges? charges.total_service_charges : '0.00' }</b>
        							</div>
        						</div> 
        						<div className="row mb-3">		
        							<div className="col-md-12">
        								<div className="last-itme-box">
        									<h6 className="fontname">Distance Charge</h6>
        									<span className="edit"> 
        											<Link to="" style={{color:"#f2b6be"}}>${charges? charges.distance_charges : '0.00' }</Link>
        									</span>
        								</div>
        							</div>  
									{MoveTypeDetail &&  MoveTypeDetail.is_pickup_stair == 1 ?
										<div className="col-md-12">
											<div className="last-itme-box">
												<h6 className="fontname">Pickup Stairs</h6>
												<span className="edit"> 
														<Link to="" style={{color:"#f2b6be"}}>${charges? charges.pickup_stairs_charges : '0.00' }</Link>
												</span>
											</div>
										</div>
									: '' }
									{MoveTypeDetail &&  MoveTypeDetail.is_dropoff_stair == 1 ?
										<div className="col-md-12">
											<div className="last-itme-box">
												<h6 className="fontname">Dropoff Stairs</h6>
												<span className="edit"> 
														<Link to="" style={{color:"#f2b6be"}}>${charges? charges.drop_off_stairs_charges : '0.00' }</Link>
												</span>
											</div>
										</div>
									: '' }
        							<div className="col-md-12">
        								<div className="last-itme-box">
        									<h6 className="fontname">Helper Service Charges</h6>
        									<span className="edit"> 
        											<Link to="" style={{color:"#f2b6be"}}>${charges? charges.helping_service_charges : '0.00' }</Link>
        									</span>
        								</div>
        							</div>

									{charges && charges.assembly_muscle_charges && MoveTypeDetail &&  MoveTypeDetail.is_assembly == 1 ?
										<div className="col-md-12">
											<div className="last-itme-box">
												<h6 className="fontname">Assembly Charge</h6>
												<span className="edit"> 
														<Link to="" style={{color:"#f2b6be"}}>${charges? charges.assembly_muscle_charges : '0.00' }</Link>
												</span>
											</div>
										</div>
									: ' '}

									{MoveTypeDetail &&  MoveTypeDetail.is_insurance == 1 ?
										<div className="col-md-12">
											<div className="last-itme-box">
												<h6 className="fontname">Insurance Charges</h6>
												<span className="edit"> 
														<Link to="" style={{color:"#f2b6be"}}>${charges? charges.insurance_charge : '0.00' }</Link>
												</span>
											</div>
										</div>
									: ' '}
									
										
        						</div>
        				    </div>
                         </div>
        			</div>
        		</div>
        	</div>
        </>
        )
    } else if(modal=='SELECT-REASON') {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
          return (
            <> 
              <div className={showHideClassName} id="otp-model" tabIndex="-1" role="dialog" aria-labelledby="def" aria-hidden="true">
        		  <div className="modal-dialog modal-sm" role="document">
        			<div className="modal-content">
        			  <div className="modal-header">
        					<h4 className="modal-title" id="myModalLabel2">Select Reason</h4>
        					<button type="button" className="close" onClick={handleClose} ><span aria-hidden="true"  onClick={handleClose}>&times;</span></button>
        				</div> 
        			  <div className="modal-body">
                        <form className="login-page">
            						
            				<div className="form-row">
            					<i className="fa fa-phone"></i>subcategory
            					<input type="text" onKeyUp={setOTPValue} />
            					<span>OTP</span>
            				</div>
            				
            				 
            				<div className="form-row">
            					<button className="full" type="button" onClick={deleteMove} ><span>Sign Up</span></button>
            				</div>
        				 
        				</form>			
        					
        			  </div>
        			</div>
        		  </div>
        		</div>
            </>
          );
		} else if(modal=='CHECKOUT-MODAL') {
			const showHideClassName = show ? "modal display-block" : "modal display-none";
			return (
			<> 
				  <div className={showHideClassName}   tabIndex="-1" role="dialog" aria-labelledby="checkdetail-service">
					  <div className="modal-dialog " role="document" style={{height: "auto",border: "0.2rem solid"}}>
						<div className="modal-content ufg-job-application-wrapper">
			
							{/* <div className="modal-header">
								<h4 className="modal-title" id="myModalLabel2">Service Charges Details</h4>
								<button type="button" className="close" onClick={handleClose} ><span aria-hidden="true"  onClick={handleClose}>&times;</span></button>
							</div> */}
			
							<div className="modal-body job-application-form">
								 <div className="add-normal-itam-content">
									<div className="row ">		
										<div className="col-md-12">
												<p>Booking Move It job on website will restrict you from getting real-time updates of your job, notifications and easy communication with your helpers and/or Move It Support.
												</p>

												<p>For the best and most user friendly Move It experience, we encourage you to download the Move It app.</p>

												<p>If you have any questions, please do not hesitate to reach out to Move It Support.

												</p>
												{MoveTypeDetail.is_estimate_hour && MoveTypeDetail.msg_for_labor_move?
												<p>
												  {MoveTypeDetail.msg_for_labor_move}
												</p>
												:''}
												
										</div> 
										<div className="col-md-6">
										<button type="button" className="btn" onClick={() => handleClose(false) } >
											<span>Cancel</span></button>
										</div>
										<div className="col-md-6">
											<button type="button" className="btn step-btn-bokmove step-btn" onClick={() => handleClose(true) } >
											<span>Continue Checkout</span></button>
					
										</div> 
									</div>
								</div>
							 </div>
							 
						</div>
					</div>
				</div>
			</>
			)
		} else if(modal=='ContactUsThankYou') {
			const showHideClassName = show ? "modal display-block" : "modal display-none";
				  return (
					<> 
					  <div className={showHideClassName} id="otp-model" tabIndex="-1" role="dialog" aria-labelledby="def" aria-hidden="true">
						  <div className="modal-dialog modal-sm" role="document">
							<div className="modal-content">
							  {/*<div className="modal-header">
									<button type="button" className="close" onClick={handleClose} ><span aria-hidden="true"  onClick={handleClose}>&times;</span></button>
				  </div> */}
							  <div className="modal-body">
									<img src="/web/img/smileemail.svg" alt="" style={{width:"150px", display:"block", margin:"0px auto",}}/>
									<h2 style={{textAlign:"center", marginTop:"30px", fontWeight:"800",}}>Thank you!</h2>
									<p style={{textAlign:"center", marginTop:"0px", fontWeight:"500",}}>for contacting us, we will reply promptly once your message is received.</p>
									<Link to="/"  class="backtoHome"> <span><i class="fa-solid fa-angles-left"></i> Back to home </span></Link>
							  </div>
							</div>
						  </div>
						</div>
					</>
				  );
				} 
    
    
};

export default Modal;