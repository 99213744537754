import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
// 1 user typ2 customer 
// 2 user type helper
import Service from './../../service';
import { useNavigate } from 'react-router-dom';
const config = require('./../../config.json');

const api = new Service();

const Header = (props) => {
    // console.log(config.PRODUCTION_MODE)
    // const [config, setconfig] = useState(props.config);
    const location = useLocation();
    const { request_id } = useParams();
    const { draft_id } = useParams();
    const navigate = useNavigate();
    const [isLoggedIn] = useState(localStorage.getItem('user_token') ? true : false);
    const [UserType] = useState(localStorage.getItem('user_type'));
    const [Verfied] = useState(1);
    const [mobileMenu, setmobileMenu] = useState(false);
    const [sideBookMove, setsideBookMove] = useState(false);
    const [MoveType, setMoveType] = useState([]);
    const GetMoveType = () => {
        api.getApi('get-all-move-type').then(response => {
            setMoveType(response.move_type);
        }).catch(error => {

        });
    }

    useEffect(() => {

        if (props.helperProfile) {
            if (props.helperProfile.is_verified == 0) {
                navigate('/helper-pending')
            } else if ((props.helperProfile.w9_form_verified == 0 && props.helperProfile.w9_form_status == 0) || props.helperProfile.w9_form_verified == 2) {
                navigate('/helper-w9-form')
            } else if (location.pathname == '/helper-moves' && (props.helperProfile.w9_form_verified == 0 && props.helperProfile.w9_form_status == 1)) {
                navigate('/helper-pending-w9-form')
            }
        }

    }, [props.helperProfile])

    useEffect(() => {
        if (location.pathname) {
            setmobileMenu(false)

        }
        if (location.pathname == '/book-move' || location.pathname == '/book-move/' + request_id || location.pathname == '/book-moves/draft/' + draft_id) {
            setsideBookMove(true)
        } else {
            setsideBookMove(false)
        }
        GetMoveType();
    }, [location.pathname, request_id])


    const handleClick = () => {
        localStorage.removeItem('user_token');
        localStorage.removeItem('user_type');
        localStorage.removeItem('user_detail');
    }

    const openCloseMobileMenu = () => {
        if (mobileMenu == true) {
            setmobileMenu(false)
        } else {
            setmobileMenu(true)
        }
        console.log(mobileMenu)

    }


    return (
        <>

            <div className={mobileMenu ? "home menumega open" : "home menumega"}>
                <header>
                    <span className="hamburger" onClick={() => openCloseMobileMenu()} ><span></span></span>
                    <Link className="logo" to="/"><img src="/web/img/logo.png" alt="Move It Logo" /></Link>
                    <nav className="primary">
                        <ul>

                            {(() => {
                                if (props.headers == 'My Profile' || isLoggedIn && UserType == 'customer' && config.PRODUCTION_MODE) {
                                    return (
                                        <>
                                            <li>
                                                <Link to="/my-moves" className={location.pathname == "/my-profile" ? 'activeTAb' : ''}>My Profile</Link>
                                            </li>
                                            <li>
                                                <Link to="/my-moves" className={location.pathname == "/my-moves" ? 'activeTAb' : ''}>My Moves</Link>
                                            </li>
                                        </>
                                    )
                                }

                                if (isLoggedIn && UserType == 'helper' && Verfied > 0 && config.PRODUCTION_MODE) {
                                    return (
                                        <>
                                            <li>
                                                <Link to="/helper-profile" className={location.pathname == "/helper-profile" ? 'activeTAb' : ''} >My Profile</Link>
                                            </li>
                                            <li>
                                                <Link to="/helper-moves" className={location.pathname == "/helper-moves" ? 'activeTAb' : ''}>My Moves</Link>
                                            </li>
                                        </>
                                    )
                                } else if (isLoggedIn && UserType == 'helper' && Verfied < 1 && config.PRODUCTION_MODE) {
                                    return (
                                        <>
                                            <li>
                                                <Link to="/helper-login" className={location.pathname == "/helper-login" ? 'activeTAb' : ''} onClick={handleClick} >Logout</Link>
                                            </li>
                                            <li>
                                                <Link to="/helper-profile" className={location.pathname == "/helper-profile" ? 'activeTAb' : ''} >My Profile</Link>
                                            </li>
                                        </>
                                    )
                                }

                                if (isLoggedIn == false && config.PRODUCTION_MODE == 1) {
                                    return (
                                        <>
                                            <li>
                                                <Link to="/login" className={location.pathname == "/login" || location.pathname == "/signup" ? 'activeTAb' : ''}>LOG IN</Link>
                                            </li>
                                            <li>
                                                <Link to="/helper-login" className={location.pathname == "/helper-login" || location.pathname == "/helper-register/1" || location.pathname == "/helper-register/2" || location.pathname == "/helper-register/3" ? 'activeTAb' : ''}>PRO & MUSCLE SIGN IN</Link>
                                            </li>
                                        </>
                                    )
                                }
                            })()}
                            <li>
                                <Link to="/city" className={location.pathname == "/city" || location.pathname.includes('cities-') ? 'activeTAb' : ''}>MOVE IT CITIES</Link>
                            </li>
                            {config.PRODUCTION_MODE == 0 ?
                                <>
                                    <li>
                                        <Link to="/move-it-news" className={location.pathname == "/move-it-news" ? 'activeTAb' : ''}>MOVE IT NEWS</Link>
                                    </li>
                                </>
                                : ''}
                            <li>
                                <Link to="/about" className={location.pathname == "/about" ? 'activeTAb' : ''}>ABOUT US</Link>
                            </li>


                            <li className="dekshow">
                                <Link className={location.pathname.includes('service') ? 'activeTAb' : ''} to="">Move It Services<span className="toggle"><i className="far fa-plus"></i><i className="far fa-minus"></i></span></Link>
                                <div className="dropdown">
                                    <ul>
                                        {MoveType.length > 0 && MoveType.map((option, index) => (

                                            <li key={index}>
                                                {option.alias ?
                                                    <Link className={location.pathname == option.alias ? 'activeTAb' : ''} to={option.alias}>
                                                        {option.name}
                                                    </Link>
                                                    :
                                                    <Link to="">
                                                        {option.name}
                                                    </Link>
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </li>

                            <li className='mblshow'>
                                {/*<Link to="#">Move It Services mobile 
                                <span className="plubtn btnmobile"><i className="far fa-plus"></i></span></Link>*/}
                                <label htmlFor="profile2" className="profile-dropdown">
                                    <input type="checkbox" id="profile2" />
                                    <span>Move It Services <i className="far fa-plus plubtn"></i></span>
                                    <ul>
                                        {MoveType.length > 0 && MoveType.map((option, index) => (

                                            <li key={index}>
                                                {option.alias ?
                                                    <Link className={location.pathname == option.alias ? 'activeTAb' : ''} to={option.alias}>
                                                        {option.name}
                                                    </Link>
                                                    :
                                                    <Link to="">
                                                        {option.name}
                                                    </Link>
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                </label>
                            </li>


                            {config.PRODUCTION_MODE == 0 ?
                                <>
                                    <li>
                                        <Link to="/contact-us" className={location.pathname == "/contact-us" ? 'activeTAb' : ''}>CONTACT US</Link>
                                    </li>
                                </>
                                : ''}


                            {UserType != 'helper' && config.PRODUCTION_MODE && sideBookMove == false ?
                                <li><Link to="/book-move" ><span>Book Your Move It<i className="fas fa-chevron-right"></i></span></Link></li>
                                : <li></li>}

                        </ul>
                    </nav>
                </header>
            </div>





        </>
    )
}
export default Header;




