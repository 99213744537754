import ReactDOM from 'react-dom';
import { BrowserRouter as Router,Routes,Route,Link,NavLink,generatePath,useHistory, useParams} from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import HelperSideBar from './../layout/HelperSideBar';
import Service from './../../service'; 
import toast, { Toaster } from 'react-hot-toast';
import React, {useState, useCallback, Component, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useFormik} from 'formik';
// const config = require('./../../../../config.json');
import Tooltip from "react-simple-tooltip"
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import ProfileSection from './profileSection';
import moment from 'moment-timezone';
import {css} from "styled-components"
import ImageViewer from 'react-simple-image-viewer';
import alertConfirm, { Button, alert } from "react-alert-confirm";
import "react-alert-confirm/dist/index.css";

var api = new Service();
const MoveDetail = (props) => {
	const [config, setconfig] = useState(props.config);
     const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [timezone, settimezone] = useState(moment.tz.guess());
    const [moveDetail, setmoveDetail] = useState({});
    const [helperDetail, sethelperDetail] = useState([]);
    const [viewotemdetail, setviewotemdetail] = useState(false);
    const [chargesdetail, setchargesdetail] = useState(false);
    const [servicechargesdetail, setservicechargesdetail] = useState(false);
    const [itemDetail, setitemDetail] = useState({});
    const [helperInfo, sethelperInfo] = useState({});
    const [step, setstep] = useState(0);
    const [helperRequired, sethelperRequired] = useState(true);
    const [photos, setphotos] = useState([]);
    const [helperProfile,sethelperProfile] = useState({});
      
    const [iframeUrl, setiframeUrl] = useState("");
    useEffect(() => {
      GethelperProfile();
       
    }, [])
    
    const GethelperProfile = () => {
        setLoading(true)
        api.getApiHelper('get-helper-info').then(response => {
            if(response && response.is_verified==0) {
                setLoading(false);
                navigate('/helper-pending')
            } else {
                sethelperProfile(response);
                getMoveHelperDetail();
            }
        }).catch(error => { 
            setLoading(false);
            toast.error(error.message);
        });
    }
    
    const getMoveHelperDetail = () => {
        api.postApiHelper('get-allotment-helper-info',{request_id:id}).then(response => {
            console.log(response)
            if(response && response.helper) {
              sethelperDetail(response.helper);
            }
            getMoveDetail()
        }).catch(error => {
            toast.error(error.message); 
            setLoading(false)
        });
    }
    
    const getMoveDetail = () => {
        setLoading(true)
        api.postApiHelper('get-move-detail-by-id',{request_id:id, timezone:timezone}).then(response => {
            setmoveDetail(response);
            setLoading(false)
        }).catch(error => {
             setLoading(false)
             toast.error(error.message);
        });
    }
    
    const getItemDteail = (option) => {
        setitemDetail(option);
        setviewotemdetail(true);
    }
    
    const getStartTime =() => {
          let startTime = moment(moveDetail.pickup_start_time, "HH:mm");
         return startTime.format("hh:mm A");
    }
    
    const getEndTime =() => {
        let startTime = moment(moveDetail.pickup_end_time, "HH:mm");
        return startTime.format("hh:mm A");
    }
    
    
    
    const setStepAnDHelper = (option) => {
          sethelperInfo(option)
          setstep(3)
    }
    
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    
    const openImageViewer = (index,photos_data) => {
        setphotos(photos_data)
        setCurrentImage(index);
        setIsViewerOpen(true);
    }
    
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
	const [cancelReason, setcancelReason] = useState('');
	const [cancelReasonErrorMessage, setcancelReasonErrorMessage] = useState('');
	const [confirmStatus, setconfirmStatus] = useState(0);
	const [showModel, setshowModel] = useState(false);
	
	const confirmCancelMove = (id) => {
		setconfirmStatus(id)
		setcancelReasonErrorMessage('')
		setcancelReason('')
		if(id==0) {
		 setshowModel(true)
		} else if(id==1) {
		   handleSubmit(id)
		}
	}  
 
	const handleSubmit = (id=0) => {
        console.log(cancelReason.length)
		if(cancelReason.length>=15 || confirmStatus==1 || id==1){
			setLoading(true);
			setcancelReasonErrorMessage('')
			setshowModel(false)
			api.postApiHelper('helper-save-cancel-move',{confirm_status:confirmStatus,helper_reason:cancelReason,request_id:moveDetail.request_id}).then(response => {
				setLoading(false);
				navigate('/helper-moves')
			}).catch(error => {
				setLoading(false);
				toast.error(error.message);
			});
		} else {
			if(cancelReason.length>0){
				setcancelReasonErrorMessage('Minimum 15 characters are required.')
			} else {
				setcancelReasonErrorMessage('Please enter reason.')
			}
		}
	}
    
    return (
        <>
        <Toaster position="top-right" reverseOrder={false} />
         <Header helperProfile={helperProfile} />
        <main>
        {helperProfile.is_verified?
            <>
        <section className="coverphoto p-0"></section>
			<ProfileSection helperProfile={helperProfile} />

			    {moveDetail && moveDetail.request_id?
				<>
                <section className="work white p40">
                    <article className="container">
                        <div className="row">
                            <HelperSideBar helperProfile={helperProfile} />
                            
				<div className="col-md-8 col-lg-9">
					<div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
						
						<div className="col-12 col-md-12 col-lg-10 text-center mb-3">
						  <h5>Move ID #{moveDetail  && moveDetail.request_id}</h5>
						
						{moveDetail && moveDetail.is_reconfirm==1? 
							<>
							<div className="d-block w-100 mb-3  text-center mt-3"> 
							  <div className="p-3 align-items-center bg-white border-1 osahan-post-header">
							    This job has been edited by the customer. Please review the changes and accept it again.
							   
							    <button type="button" className="mt-3 removeBackGroundEffect" onClick={() => confirmCancelMove(1)}>Accept</button>
								<button type="button" className="mt-3 removeBackGroundEffect"  onClick={() => confirmCancelMove(0)}>Reject</button>
							   </div>
							</div>
							
							</> 
						:''}
				</div>
						
						
						<div className="col-md-12 col-lg-10">  
						   <div className="contact-wrapper"> 
						   
						   
						        {moveDetail.helper_status!=6?
						        <>
									
									{helperDetail && helperDetail.length>0? 
									    <>
										<div className="col-md-12 text-center"><h5>Accepted Helper Info</h5></div>
										<div className="d-block w-100 mb-3"> 
										
										{helperDetail && helperDetail.map((option, index) => (
												<div className="p-3 d-flex align-items-center bg-white border-1 osahan-post-header" key={index}>
													<div className="dropdown-list-image mr-3">
														<img className="rounded-circle" src={option.photo_url} alt="" />
													</div>
													<div className="font-weight-bold mr-3 notification-hrf">
														<div className="">{option.first_name} {option.last_name}{option.phone_num}</div>
															<div className="small">
															{option && option.service_type==1?'Pro': option && option.service_type==2?'Muscle': option &&  option.service_type==3?'Pro & Muscle':''}
														   
													 
																{moveDetail && moveDetail.is_reconfirm==0 && (moveDetail.helper_status==1 || moveDetail.helper_status==2 || moveDetail.helper_status==3)?
																<>
																	<Tooltip content={option.phone_num} customCss={css`
																			white-space: nowrap; 
																			
																			`}>
																		<span className="callmsg-icon"><i className="fas fa-phone fa-fw"></i></span>
																	</Tooltip>
																	<Link to={'/helper-to-helper-chat/'+option.helper_id+'/'+moveDetail.request_id} ><span className="callmsg-icon" ><i className="fas fa-envelope fa-fw" ></i></span></Link>	
																</>
																: '' }
															</div>
														
														</div>
												</div> 
										))}

										</div>
										</>
									:   
									   
									    <>
										{moveDetail.required_pros>0 || moveDetail.required_muscle>0?
											<>
											<div className="col-md-12 text-center"><h5>Accepted Helper Info</h5></div>
											<div className="d-block w-100 mb-3  text-center"> 
												<div className="p-3 align-items-center bg-white border-1 osahan-post-header">
													No Helper Alloted to this move yet
												</div>
											</div>
											</>
										:''}	
										</>
									}
							    </>
							    : '' }
							  
							  	<div className="col-md-12 text-center"><h5>Move Info</h5></div>
							  <div className="d-block w-100  mb-3"> 
								<div className="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header">
									<div className="dropdown-list-image mr-3">
										<img className="rounded-circle" src={moveDetail  && moveDetail.customer_photo_url} alt="" />
									</div>
									<div className="font-weight-bold mr-3 notification-hrf">
										<div className="">{moveDetail  && moveDetail.customer_name}</div>
											<div className="small">
												<p>{moveDetail  && moveDetail.dropoff_service_name}</p>
												{moveDetail && moveDetail.is_reconfirm==0 && (moveDetail.helper_status==1 || moveDetail.helper_status==2 || moveDetail.helper_status==3)?
												<>
													<Tooltip content={moveDetail.customer_phone} customCss={css`
															white-space: nowrap; 
															
															`}>
														<span className="callmsg-icon"><i className="fas fa-phone fa-fw"></i></span>
													</Tooltip>
													<Link to={'/helper-chat/'+moveDetail.customer_id} ><span className="callmsg-icon" ><i className="fas fa-envelope fa-fw" ></i></span></Link>	
												</>
												: '' }
											</div>
										
									</div>
									<span className="ml-auto mb-auto">
									   <div className="small text-right price-moev">
									   
									   {moveDetail && moveDetail.helper_accept_service_type==1?'Pro': moveDetail && moveDetail.helper_accept_service_type==2?'Muscle': moveDetail &&  moveDetail.helper_accept_service_type==3?'Pro & Muscle':''}
									   
									    {moveDetail.helper_status!=6?
									    <>
									   <br/>
									   <span> ${moveDetail  && moveDetail.total_amount}</span> 
									   </>
									   : '' }
									   </div>
									</span>
								</div>
								<div className="row no-gutters bg-light p-10all-side move-dmap">
							        	{moveDetail && moveDetail.move_type && moveDetail.move_type.is_pickup_address? 
        									<div className="col-xl-6 col-lg-6 col-md-6">
        									   <div className="row">
											     <div className="col-md-12">
            									    <i className="pr-1 maploc"><img src="/web/img/from_icon.png" alt=""/></i><small>From</small>
            										<br/>
            										<span className="maptxt">{moveDetail.pickup_address}
            										 {moveDetail.pickup_address_type==1 && moveDetail.pickup_apartment?
        											   <p className="apt-unit">Unit # {moveDetail.pickup_apartment} </p>   
        											   :''}
        											   
        											   {moveDetail.pickup_address_type==2 && moveDetail.pickup_apartment?
        											   <p className="apt-unit">Apt. # {moveDetail.pickup_apartment} </p>   
        											   :''}
        											   
        											   {moveDetail.pickup_gate_code?
        											   <p className="apt-unit">Gate Code: # {moveDetail.pickup_gate_code} </p>   
        											   :''}
            										</span>
        										  </div>
        										</div>
        									</div>
        									: '' } 
        									{moveDetail && moveDetail.move_type && moveDetail.move_type.is_dropoff_address? 
        									<div className="col-xl-7 col-lg-7 col-md-7">
        									   <div className="row">
											      <div className="col-md-12">
                									    <i className="pr-1 maploc"><img src="/web/img/to_location_icon.png" alt="" /></i><small>To</small>
                									    <br/>
                										<span className="maptxt">{moveDetail.dropoff_address}
                										
                										{moveDetail.dropoff_address_type==1 && moveDetail.dropoff_apartment?
            											   <p className="apt-unit">Unit # {moveDetail.dropoff_apartment} </p>   
            											   :''}
            											   
            											   {moveDetail.dropoff_address_type==2 && moveDetail.dropoff_apartment?
            											   <p className="apt-unit">Apt. # {moveDetail.dropoff_apartment} </p>   
            											   :''}
            											   
            											   {moveDetail.dropoff_gate_code?
            											   <p className="apt-unit">Gate Code: # {moveDetail.dropoff_gate_code} </p>   
            											   :''}
                										</span>
                									</div>
        										</div>
        									</div>
        									: '' }
        								<div className="row no-gutters bg-light p-10all-side pb-2">
        								   {moveDetail && moveDetail.meeting_slot? 
        									<div className="col-6 col-lg-6 align-self-center">
        									  <i className="far fa-calendar-alt pr-1 maploc-1"></i><small>Scheduled Time/Date</small>
        									  <br/>
        									  <span className="maptxt">{moveDetail.meeting_slot}</span>
        								    </div>
        								    : '' }
        								    
        								  
        								    <div className="col-6 col-lg-6 align-self-center">
        									  <i className="far fa-calendar-alt pr-1 maploc-1"></i><small>Pickup Time/Date</small>
        									  <br/>
        									  <span className="maptxt">{moment(moveDetail.pickup_date).format('ddd, MMM DD')}, {getStartTime()} - {getEndTime()}</span>
											  

        								    </div>
        								</div>
								</div>
							  </div>
						   </div>
							
							
							<div className="movedetail-boxcustomer">
							<div className="col-md-12 text-center"><h5>Move Items</h5></div>
							   <div className="row itemstep">  
							     	{moveDetail && moveDetail.items  && moveDetail.items.map((option, index) => (
        								  <div className="col-12 col-md-6 col-lg-6 mb-1"  key={index} > 
        									 <div className="cardbox add-item">
        										<h5>{option.item_name} <span className="t-item">{option.quantity}</span></h5>
        										<p><Link to="#" onClick={() => getItemDteail(option) } >View item detail</Link></p>
        									 </div> 
        								 </div>
        							))}
							   </div>
							</div>
							<hr/> 
							
						  {moveDetail && moveDetail.move_type && moveDetail.move_type.is_pickup_address?
        						  <div className="row">	
        							<div className="col-12 col-md-12 col-lg-12 mb-1 mt-2">
        							  <h6>Pickup Location Details</h6>
        							</div>
        							<div className="col-6 col-md-6 col-lg-6">
        							   <div className="pick-l-detail">
        							      <h5>Need to use Stairs</h5>
        								  <h6>{moveDetail.pickup_stairs} Flight(s)</h6>
        							   </div>
        							</div>
        							<div className="col-6 col-md-6 col-lg-6">
        							   <div className="pick-l-detail">
        							      <h5>Need to use Elevator</h5>
        								  <h6>{moveDetail.use_pickup_elevator>0? 'Yes' : 'No' }</h6>
        							   </div>
        							</div>
        						  </div>
                                   : '' }
                                   
                                  {moveDetail && moveDetail.move_type && moveDetail.move_type.is_dropoff_address?
        						  <div className="row">	
        							<div className="col-12 col-md-12 col-lg-12 mb-1 mt-2">
        							  <h6>DropOff Location Details</h6>
        							</div>
        							<div className="col-6 col-md-6 col-lg-6">
        							   <div className="pick-l-detail">
        							      <h5>Need to use Stairs</h5>
        								  <h6>{moveDetail.drop_off_stairs} Flight(s)</h6>
        							   </div>
        							</div>
        							<div className="col-6 col-md-6 col-lg-6">
        							   <div className="pick-l-detail">
        							      <h5>Need to use Elevator</h5>
        								  <h6>{moveDetail && moveDetail.use_dropoff_elevator>0? 'Yes' : 'No' }</h6>
        							   </div>
        							</div>
        						  </div> 
                                  : '' }
                                  
                                  
                                  {moveDetail && moveDetail.helper_status!=6 && moveDetail.pickup_photo_url && moveDetail.pickup_photo_url.length>0?
                                  <>
                                  <div className="row itemstep item-photomovedetal">  
        							<div className="col-md-12"><h5>Pickup Location Photos</h5></div>
        							 {moveDetail.pickup_photo_url.map((image,ind) => (
            							<div className="col-6 col-md-3 col-lg-3 mb-2" key={ind}>
            								<div className="cardbox add-item">
            									<img src={image} onClick={ () => openImageViewer(ind,moveDetail.pickup_photo_url) } />
            								</div>
            							</div>
            						 ))}
            							
        						  </div>
        						  </>
        						  : '' }
        						  
        						  {moveDetail && moveDetail.helper_status!=6 && moveDetail.dropoff_photo_url && moveDetail.dropoff_photo_url.length>0?
        						  <>
        						  <div className="row itemstep item-photomovedetal">  
        							<div className="col-md-12"><h5>Dropoff Location Photos</h5></div>
        						    {moveDetail.dropoff_photo_url.map((image,ind) => (
            							<div className="col-6 col-md-3 col-lg-3 mb-2" key={ind}>
            								<div className="cardbox add-item">
            									<img src={image}  onClick={ () => openImageViewer(ind,moveDetail.dropoff_photo_url) }  />
            								</div>
            							</div>
            						 ))}
        						  </div>
        						  </>
        						  : '' }
							
						 </div>
						 
					  </div>
				</div>
			   </div>
			 </article>
         </section>
		 </>
		 :''}
         <div className={viewotemdetail ? "modal right display-block" : "modal  right  display-none"}  id="viewotemdetail" tabIndex="-1" role="dialog" aria-labelledby="viewotemdetail">
    		     <div className="modal-dialog modal-right-side" role="document">
        			<div className="modal-content ufg-job-application-wrapper">
        				<div className="modal-header">
        					<h4 className="modal-title" id="myModalLabel2">Item Details</h4>
        					<button type="button" className="close" onClick={() => setviewotemdetail(false) } aria-label="Close"><span aria-hidden="true" onClick={() => setviewotemdetail(false) } >&times;</span></button>
        				</div>
        				<div className="modal-body job-application-form">
        					 <div className="add-normal-itam-content">
        						{itemDetail? 
        						<>
        						<div className="movedetail-boxcustomer">
        							<div className="row itemstep">  
        								<div className="col-12 col-md-12 col-lg-12 mb-1">
        									<div className="cardbox add-item">
        								    <h5>{itemDetail.item_name}<span className="t-item">{itemDetail.quantity}</span></h5>
        								</div>
        							</div>
        						   </div>
        						</div>
        						<div className="row">	
        							<div className="col-12 col-md-12 col-lg-12 mb-1 mt-2">
        							  <h6>Additional Details</h6>
        							</div>
        							<div className="col-6 col-md-4 col-lg-4">
        							   <div className="pick-l-detail">
        							      <h5>Width</h5>
        								  <h6>{itemDetail.item_width}</h6>
        							   </div>
        							</div>
        							<div className="col-6 col-md-4 col-lg-4">
        							   <div className="pick-l-detail">
        							      <h5>Height</h5>
        								  <h6>{itemDetail.item_height}</h6>
        							   </div>
        							</div>
        							<div className="col-6 col-md-4 col-lg-4">
        							   <div className="pick-l-detail">
        							      <h5>Depth</h5>
        								  <h6>{itemDetail.item_depth}</h6>
        							   </div>
        							</div>
        							<div className="col-6 col-md-6 col-lg-6">
        							   <div className="pick-l-detail">
        							      <h5>Weight</h5>
        								  <h6>{itemDetail.item_weight}</h6>
        							   </div>
        							</div>
        							<div className="col-6 col-md-6 col-lg-6">
        							   <div className="pick-l-detail">
        							      <h5>Assemble/Disassemble</h5>
        								  <h6>{itemDetail.can_assamble? 'Yes' : 'No'}</h6>
        							   </div>
        							</div>
        							
        						<div className="col-12 col-md-12 col-lg-12">	
        							<h5>About Item</h5>
        							<p className="pstep-from">{itemDetail.additional_info}</p>
        						</div>	
        							
        						</div>
        						
        						<div className="row itemstep item-photomovedetal">  
        							<div className="col-md-12"><h5>Item Photos</h5></div>
        							
        							 {itemDetail && itemDetail.item_photo && itemDetail.item_photo.length > 0 && itemDetail.item_photo.map((option, index) => (
                							<div className="col-6 col-md-4 col-lg-4 mb-2" key={index}>
                								<div className="cardbox add-item">
                									<img src={option}  onClick={ () => openImageViewer(index,itemDetail.item_photo) }  />
                								</div>
                							</div>
                					 ))}
        						</div> 
        						</>
        					    : '' }
            				</div>
                        </div>
            		</div>
            	</div>
            </div>	
            
            {photos && photos.length> 0 &&  isViewerOpen && (
                        <ImageViewer
                          src={photos}
                          currentIndex={ currentImage }
                          disableScroll={ false }
                          closeOnClickOutside={ true }
                          onClose={ closeImageViewer } 
                        />
                      )}
          </>
            : '' }
      </main>
      
      
        <Footer loading={loading}></Footer>     
		<div className={showModel ? "modal display-block" : "modal display-none"} id="otp-model" tabIndex="-1" role="dialog" aria-labelledby="def" aria-hidden="true">
            <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title" id="myModalLabel2">Cancellation Reason</h4>
                    <button type="button" className="close" onClick={() => setshowModel(false)} ><span aria-hidden="true" onClick={() => setshowModel(false)}>&times;</span></button>
                </div> 
                <div className="modal-body">
                <form className="login-page" onSubmit={handleSubmit}>
                <div className="form-row">
                    <textarea type="text" placeholder="Enter Reason" onChange={(e) => setcancelReason(e.target.value)}  style={{ borderColor:"#e89aa7"}}  ></textarea>
					{cancelReasonErrorMessage?
					 <div style={{ color:"red"}}>{cancelReasonErrorMessage}</div>
					:''}
                </div>
                    
                <div className="form-row">
                    <button className="full" type="button" onClick={handleSubmit} ><span>Submit</span></button>
                </div>
                    
                </form>			
                    
                </div>
            </div>
            </div>
        </div> 
        </>
    )
}
export default MoveDetail;

