import io  from "socket.io-client";
const config = require('./config.json');
const socket = io.connect(config.SOCKET_URL);
console.log('check 1', socket.connected);
socket.on('connect', function() {
  console.log('check 2', socket.connected);
})
socket.on("connect_error", (err) => {
    console.log(`connect_error due to ${err.message}`);
});

export default socket;