
import Header from './layout/HeaderMoveitLicense';
import Footer from './layout/FooterMoveitLicense';
import Service from './../service';
import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import toast, { Toaster } from 'react-hot-toast';
import MetaHelmet from './metaHelmet'; //Include metaHelmet
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const api = new Service();
const MoveLicensing = (props) => {


    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const [config, setconfig] = useState(props.config);


    useEffect(() => {
        document.title = "Move It Licensing";
        window.gtag('config', config.G_TAG, {
            page_title: document.title,
            page_path: window.location.pathname,
        });
    }, []);


    return (
        <>
            <MetaHelmet title="Move It Licensing" description="If you need help with anything or have questions / concerns please don't hesitate to ask. You can send us a message and also send an email." />
            <Toaster position="top-right" reverseOrder={false} />
            <Header></Header>
            <main>

                <section className="hero clean" style={{ backgroundImage: `url("web/img/about-top.jpg")` }}>
                    <article>
                        <div className="grid">
                            <div className="unit-2-3 unit-1-1-md">
                                <h1 className="h2 f-46">Move It Licensing</h1>
                                {/* <h2 className="h5 mb-2">Find out why Move It has become the most talked about on demand app for small moves and deliveries</h2> */}
                            </div>
                        </div>
                    </article>
                </section>


                <section className="movelicensingFirst">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mv_aboutus2"><img className="rellax" src="web/img/citydetail/moveitlicanse.png" alt="Move It - On Demand Delivery & Move" data-rellax-speed="1" /></div>
                            </div>

                            <div className="col-md-6">
                                <h1>Welcome to Move It™ Licensing</h1>
                                {/*<p>Truck and Muscle, Anytime You Need It ™</p>*/}
                                <h4 className="moveitliceningHead">A Wholly-Owned Subsidiary of Move It, LLC</h4>
                                <img src="./web/img/license.png" className="ShildImg" alt="Move It - On Demand Delivery & Move" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="whatwedoLicense">
                    <div className="container">
                        <div class="row">
                            <div class="col-md-4">
                                <img src="web/img/07.jpeg" alt="" />
                            </div>
                            <div class="col-md-4">
                                <h2>What is Move It™ Licensing</h2>
                                <p>Move It ™ has become one of the fastest-growing tech companies in the U.S. We are considered the UBER for moving, pickup/delivery, and junk removal. Move It™ is quickly growing and expects to be nationwide by the end of 2026.</p>
                                <p>Move It ™ Licensing allows investors to own specific territories and see a very valuable return on their investment. Can you imagine getting a piece of every Uber ride in your area? Well, here you go!</p>
                                <Link to='/request-more-info' className="moreInfoBtn" style={{ background: "#ff95a2" }}>Request More Information</Link>
                            </div>
                            <div class="col-md-4">
                                <img src="web/img/06.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>


                <section className="licensingBoxThird">
                    <div className="container">
                        <div className="row align-items-center justify-content-center mb-5">
                            <div className="col-md-8">
                                <h2 className="text-center">Own Your Territory</h2>
                                <p className="text-center">City, County, State, Region, and Country is available for licensing</p>
                            </div>
                        </div>

                        <div className="row align-items-center justify-content-center mb-5">
                            <div className="col-md-4">
                                <div className="flexBox">
                                    <i class="fa-solid fa-arrow-up-right-dots"></i>
                                    <h2>Growth</h2>
                                    <p>Move It is servicing 6 areas now and expanding fast. Inquire about a specific
                                        territory today.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="flexBox pinkBoxCenter">
                                    <i class="fa-solid fa-gauge-high"></i>
                                    <h2>Speed</h2>
                                    <p>Move Its award-winning app has been operational since 2020 and ready to
                                        take new territories live within 45 days.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="flexBox">
                                    <i class="fa-solid fa-hand-holding-dollar"></i>
                                    <h2>ROI</h2>
                                    <p>Our current territory owners are seeing amazing returns of 18-30%</p>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-4">
                                <img src="web/img/Michale.png" className="mb-3" alt="" />
                            </div>
                            <div className="col-md-8">
                                <h5 className="founderTxt">We are excited to provide enormous opportunities to entrepreneurs and investors worldwide. Move It is going to be a household name like Uber. If you need a ride, “I’ll get an Uber.” If you need something moved or delivered, “I’ll get Move It.” The ROI is amazing for investors. I am excited to take this journey with our territory owners.</h5>
                                <h1 className="nameF">Michael Eshragh - Founder / CEO of Move It</h1>
                            </div>
                        </div>
                    </div>
                </section>




                {/*<section className="whatwedoLicense">
              <div className="container">
                 <div className="row align-items-center justify-content-center mb-5">
                    <div className="col-md-8">
                       <h2 className="text-center">Our Services</h2>
                    </div>
                </div>
                <div class="row align-items-start justify-content-start">
                     <div className="col-md-4 mb-3">
                         <span className="circleImgLicense"><img src="web/img/citydetail/licensing.png" alt=""/></span>
                         <h3 className="headingServicesLic">Licensing Opportunities</h3>
                         <p className="subHeadingServicesLic"><b>Licensors:</b> We can assist you in making money off of your intellectual property (IP) through licensing. 
                         <br></br><br></br>
                         <b>License holders:</b> Seeking a chance to start your own business? Examine your choices for licensing with well-known companies.
                         </p>
                     </div>
                     <div className="col-md-4 mb-3">
                         <span className="circleImgLicense"><img src="web/img/citydetail/promotion.png" alt=""/></span>
                         <h3 className="headingServicesLic">Marketing Strategies</h3>
                         <p className="subHeadingServicesLic"><b>Licensors:</b> To advertise your brand and draw in possible licensees, our marketing staff will develop focused campaigns.
                         <br></br><br></br>
                         <b>Licensees:</b> Take use of our marketing know-how to successfully connect with your target market.
                         </p>
                     </div>
                     <div className="col-md-4 mb-3">
                         <span className="circleImgLicense"><img src="web/img/citydetail/discussion.png" alt=""/></span>
                         <h3 className="headingServicesLic">Consulting Services</h3>
                         <p className="subHeadingServicesLic"><b>Licensors:</b> Receive individualized guidance on revenue maximization, negotiations, and licensing agreements.
                         <br></br><br></br>
                         <b>Licensees:</b> We'll guide you through license conditions and guarantee a fruitful collaboration.
                         </p>
                     </div>
                     <div className="col-md-4 mb-3">
                         <span className="circleImgLicense"><img src="web/img/citydetail/budget.png" alt=""/></span>
                         <h3 className="headingServicesLic">Scale Fast</h3>
                         <p className="subHeadingServicesLic"><b>Licensors:</b> Use a tested business plan to scale quickly and increase ROI.
                         </p>
                     </div>
                     <div className="col-md-4 mb-3">
                         <span className="circleImgLicense"><img src="web/img/citydetail/brand-image.png" alt=""/></span>
                         <h3 className="headingServicesLic">Branding Excellence</h3>
                         <p className="subHeadingServicesLic"><b>Licensors:</b> Boost your brand's reputation by strategically licensing.
                         <br></br><br></br>
                         <b>Licensees:</b> Make use of well-known brands to increase your visibility in the marketplace.
                         </p>
                     </div>
                     <div className="col-md-4 mb-3">
                         <span className="circleImgLicense"><img src="web/img/citydetail/connection.png" alt=""/></span>
                         <h3 className="headingServicesLic">Lead Generation</h3>
                         <p className="subHeadingServicesLic"><b>Licensors:</b> Potential licensees that share your brand concept will be introduced to you by us.
                         <br></br><br></br>
                         <b>Licensees:</b> Find licensing options that align with your company's objectives.
                         </p>
                     </div>
                </div>
              </div>
           </section>*/}


                <section className="bookconsutancy">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Book A Free Consultation</h2>
                                <p>We will be glad to answer your questions. We are looking forward to work with you.</p>
                                <Link to="/request-more-info" className="moreInfoBtn" style={{ background: "#ff95a2" }}>Request More Information</Link>
                            </div>
                        </div>
                    </div>
                </section>



                {/*<section className="text-center">
            	  <div className="container">
            	     <div className="row">
            	         <div className="col-md-12">
            	            <h2 className="f-46">Move It - On Demand Delivery & Move</h2>
            	            <p className="f-22">Truck & Muscle Anytime You Need It ™</p>
            	            <img src="/web/img/logo.png" alt="Move It Logo"/>
            	         </div>
            	         
            	     </div>
            	  </div>
            </section>*/}






            </main>
            {/* <Modal modal="ContactUsThankYou" show={serviceChargeModal} handleClose={hideServiceChargeModal}></Modal> */}

            <Footer loading={loading}></Footer>
        </>
    );
}

export default MoveLicensing;
