import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './layout/Header'; //Include Heder
import Footer from './layout/Footer'; //Include Footer
import MetaHelmet from './metaHelmet'; //Include metaHelmet


function MoveItProMuscle(props) {

	const [config, setconfig] = useState(props.config);

	useEffect(() => {
		document.title = "Earn some extra cash by becoming muscle & pro for Move It";
		window.gtag('config', config.G_TAG, {
			page_title: document.title,
			page_path: window.location.pathname,
		});
	}, []);


	return (
		<>
			<MetaHelmet title="Earn some extra cash by becoming muscle & pro for Move It" description="Move It is currently accepting helper, cargo van, box or trailer. Don’t waste your time-sign-up to Move It and start earning right away." />
			<Header></Header>
			<main>
				<section className="hero-area mpvepro-muscles">
					<div className="breadcrumbs-wrapper">
						<div className="container">
							<div className="row">
								<div className="col-lg-5">
									<div className="page-title">
										<Link to="/helper-service-type" className="newbnt-promuscle"> <span>Sign Up Pro & Muscle </span></Link>
										<h1 className="title">Download the Move It helper app today.</h1>
										<ul className="breadcrumbs-link">
											<li><a href="https://apps.apple.com/in/app/move-it-helper/id1488199360"><img className="mr-2" src="web/img/app_store-btn.png" alt="" /></a></li>
											<li><a href="https://play.google.com/store/apps/details?id=com.moveit.helper&hl=en&gl=US"><img className="" src="web/img/play_store-btn.png" alt="" /></a></li>

										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="pro-muscles">
					<article>
						<h2 className="margin-bottom-lg text-center"><span className="flourish text"><span className="flourish-square"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(232,154,167)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span> How Much Money Can I Make?</span></h2>


						<div className="row d-flex align-items-center justify-content-center highlights">
							<div className="col-md-12 col-lg-9">

								<div className="row d-flex align-items-center no-gutters highlight">
									<div className="col-md-6">
										<img src="/web/img/move-it-pros.jpg" alt="" data-aos="fade-left" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out" />
									</div>
									<div className="col-md-6">
										<div className="promuscle">
											<h3>Move It Pro</h3>
											<h6>Earn up to $80+/hr</h6>
											<p>I have a pickup truck, cargo, box truck, or a vehicle with a trailer and can lift over 80 lbs</p>
										</div>
									</div>
								</div>
								<div className="row d-flex align-items-center no-gutters  highlight">
									<div className="col-md-6">
										<img src="/web/img/06.jpg" alt="" data-aos="fade-right" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out" />
									</div>
									<div className="col-md-6">
										<div className="promuscle">
											<h3>Muscle</h3>
											<h6>Earn up to $25+/hr</h6>
											<p>I don't have a pickup truck but can lift over 80 lbs to assist Move It Pros or jobs that need only muscle</p>
										</div>
									</div>
								</div>
								<div className="row d-flex align-items-center no-gutters highlight">
									<div className="col-md-6">
										<img src="/web/img/moveit-muscle-pro-both.jpg" alt="" data-aos="fade-left" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out" />
									</div>
									<div className="col-md-6">
										<div className="promuscle">
											<h3>Move It Pro & Muscle</h3>
											<h6></h6>
											<p> I have a pickup truck, cargo van, box or trailer. I am available for all Pro & Muscle jobs and I can lift over 80 lbs</p>
										</div>
									</div>
								</div>

							</div>
						</div>
					</article>
				</section>
				<section className="slate why-pro-muscles">
					<article>

						<div className="grid-center margin-bottom-sm">
							<div className="unit-2-1 unit-1-1-md">
								<h3 className="flourish text becomepromuscle"><span className="flourish-square zin1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(255,149,162)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span>Why Join Move It<br />
									<small>Helpers have made Move It their full time jobs</small></h3>
							</div>
						</div>



						<div className="row mb-5">
							<div className="col-md-6 col-lg-6">
								<div className="themestek-ihbox-inner" data-aos="fade-right" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out">
									<div className="themestek-ihbox-icon">
										<div className=""><img src="/web/img/coin.png" alt="" /></div>
									</div>
									<div className="themestek-ihbox-contents">
										<div className="themestek-vc_general">
											<h5 className="themestek-custom-heading ">Get Paid More</h5>
										</div>
										<div className="themestek-cta3-content-wrapper">Move It pays industry leading wages. Make more money per hour than most all delivery and moving company employees.</div>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-lg-6">
								<div className="themestek-ihbox-inner" data-aos="fade-left" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out">
									<div className="themestek-ihbox-icon">
										<div className=""><img src="/web/img/bank.png" alt="" /></div>
									</div>
									<div className="themestek-ihbox-contents">
										<div className="themestek-vc_general">
											<h5 className="themestek-custom-heading ">Weekly Pay</h5>
										</div>
										<div className="themestek-cta3-content-wrapper">You won’t wait long to get paid. Pros and Muscles get paid each week for the previous week’s activity via direct deposit.</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row mb-5">
							<div className="col-md-6 col-lg-6">
								<div className="themestek-ihbox-inner" data-aos="fade-right" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out">
									<div className="themestek-ihbox-icon">
										<div className=""><img src="/web/img/work-schedule.png" alt="" /></div>
									</div>
									<div className="themestek-ihbox-contents">
										<div className="themestek-vc_general">
											<h5 className="themestek-custom-heading ">Flexible Hours</h5>
										</div>
										<div className="themestek-cta3-content-wrapper">Work when you want, how often you want. You have full control over when you want to work. Choose the jobs that you want and fit in your schedule.</div>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-lg-6">
								<div className="themestek-ihbox-inner" data-aos="fade-left" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out">
									<div className="themestek-ihbox-icon">
										<div className=""><img src="/web/img/protection.png" alt="" /></div>
									</div>
									<div className="themestek-ihbox-contents">
										<div className="themestek-vc_general">
											<h5 className="themestek-custom-heading ">Move It Has You Covered</h5>
										</div>
										<div className="themestek-cta3-content-wrapper">We handle all the backend stuff for you. Guaranteed pricing, job details, in app customer communication, and more. Move It’s commercial liability insurance has you covered so you can enjoy doing what you do.</div>
									</div>
								</div>
							</div>
						</div>
					</article>
				</section>


				<section className="nabkimg-point">
					<article>
						<h3 className="margin-bottom-lg text-center"><span className="flourish text"><span className="flourish-square"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(232,154,167)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span> Minimum Requirements</span></h3>


						<div className="row d-flex align-items-center justify-content-center">
							<div className="col-md-4 col-lg-4">
								<ul className="rigtxt" data-aos="fade-left" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out">
									<li>Must be atleast 18 years of age.</li>
									<li>Have valid driver’s license and auto insurance.</li>
									<li>Move It Muscles need to have transportation to get to and from Move It job destinations.</li>
									<li>Willing and able to lift/carry large items (minimum 80 lbs).</li>
									<li>Must have ZELLE account for direct deposits.</li>
								</ul>
							</div>

							<div className="col-md-4 col-lg-4">
								<img src="/web/img/mobile-center.png" className="cen0img" alt="" />
							</div>


							<div className="col-md-4 col-lg-4">
								<ul className="lfttxt" data-aos="fade-right" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out">
									<li>Move It Pros must have a pickup truck, cargo van, box truck or vehicle w/a trailer model year 2000 or newer that is reliable and is capable of safely moving items in perfect condition.</li>

									<li>Must be able to pass background check.</li>
									<li>Must have an iPhone or Android smartphone.</li>
									<li>Must be able to speak English.</li>

									<li>Must be friendly and courteous to customers at all times.</li>
								</ul>
							</div>

						</div>
					</article>
				</section>
			</main>
			<Footer></Footer>
		</>
	);
}

export default MoveItProMuscle;
