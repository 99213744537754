// import {Helmet} from "react-helmet";
import { Helmet, HelmetProvider } from 'react-helmet-async';
const config = require('./../config.json');


const MetaHelmet=(props)=>{
    return (
        <>
            <HelmetProvider>
            <Helmet>
                <title>{props.title?props.title:"Move It - On Demand Delivery & Move"}</title>
            </Helmet> 
            </HelmetProvider>
        </>
    )
}

export default MetaHelmet;
