import { Link } from 'react-router-dom';

import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import MetaHelmet from './../metaHelmet'; //Include metaHelmet
import Service from './../../service';
import { useFormik } from 'formik';

import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

import toast, { } from 'react-hot-toast';
import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import { firebase, auth } from './../firebase.js';
const api = new Service();
let verify;
let recaptchaWrapperRef;
const Signup = () => {

    const navigate = useNavigate();
    // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const [loading, setloading] = useState(false);
    const [show, setShow] = useState(false);
    // const [defaultOTP, setdefaultOTP] = useState(1111);
    // const [OtpVerified, setOtpVerified] = useState(false);
    const [OTP, setOTP] = useState('');

    const [final, setfinal] = useState('');
    const [aboutList, setaboutList] = useState([]);
    const hideModal = () => {
        setShow(false);
    };
    // const verifYOTP = () => {
    //     if(OTP==defaultOTP) {
    //         toast.success("OTP verified successfully!");
    //         setShow(false,formik.handleSubmit())
    //     } else { 
    //         toast.error("Wrong OTP");
    //     }
    // }
    const setOTPValue = (event) => {
        setOTP(event.target.value);
    };
    const formik = useFormik({
        initialValues: {
            name: '',
            first_name: '',
            last_name: '',
            email_id: '',
            phone_num: '',
            password: '',
            term_and_condition: false,
            dial_code: "+1",
            photo_url: "",
            about_move_it: "",
        },

        validationSchema: yup.object({
            first_name: yup.string()
                .max(25, 'User can enter max 25 characters').required('Please enter your first name.'),
            last_name: yup.string().max(25, 'User can enter max 25 characters').required('Please enter your last name.'),
            phone_num: yup.number().typeError('you must specify a number').required('Please enter your phone number.'),
            password: yup.string().required('Please enter password.'),
            term_and_condition: yup.boolean().oneOf([true], 'Please select terms and conditions !'),
            checkEmail: yup.boolean(),
            email_id: yup.string().email('Invalid email address').required('Please enter your email address.'),
            dial_code: yup.string().required('Please select your country.'),
            about_move_it: yup.string().required('Please select one'),

        }),

        onSubmit: values => {
            setloading(true);
            values.phone_num = values.dial_code + ' ' + values.phone_num;
            let postData = { ...values, login_type: 'E', name: values.first_name + ' ' + values.last_name };
            api.postApi('customer-sign-up', postData).then(response => {
                setloading(false);
                toast.success('Registration successfully');
                formik.resetForm()
                localStorage.setItem("user_token", response.web_auth_key);
                localStorage.setItem("user_type", 'customer');
                localStorage.setItem("user_detail", response);
                localStorage.setItem("customer_id", response.customer_id)
                localStorage.setItem("is_login", 1)
                localStorage.setItem("login", 1)
                navigate('/book-move')
            }).catch(error => {
                setloading(false);
                toast.error(error.message);
            });
        }

    });

    const [passwordType, setPasswordType] = useState("password");
    // const [passwordInput, setPasswordInput] = useState("");
    const togglePassword = () => {
        if (passwordType == "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const checkEmailExist = (email_id) => {
        formik.setFieldValue('email_id', email_id)
        api.postApi('check-email-address', { email_id: email_id }).then(response => {
            if (response) {
                formik.setFieldValue('checkEmail', response)
            } else {
                formik.setFieldValue('checkEmail', false)
            }
            return response;
        }).catch(error => {
            formik.setFieldValue('checkEmail', false)
        });
    }

    const finalSubmit = (event) => {
        event.preventDefault();
        setloading(true);
        api.postApi('check-email-address', { email_id: formik.values.email_id }).then(response => {
            if (response) {
                formik.setFieldValue('checkEmail', response)
                setloading(false);
            } else {

                if (formik.isValid && formik.values.phone_num) {
                    sentOTP();
                } else {
                    formik.handleSubmit();
                    setloading(false);
                }
            }
        }).catch(error => {
            if (formik.isValid && formik.values.phone_num) {
                sentOTP();

            } else {
                formik.handleSubmit();
                setloading(false);
            }
        });
    }

    const sentOTP = () => {
        if (verify && recaptchaWrapperRef) {
            verify.clear()
            recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`
        }
        verify = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible'
        });
        auth.signInWithPhoneNumber(formik.values.dial_code + formik.values.phone_num, verify).then((result) => {
            setfinal(result);
            setShow(true);
            setloading(false);
        }).catch((err) => {
            setloading(false);
            toast.error("Please enter vaild phone number.");
        });
    }

    const ValidateOtp = () => {
        setloading(true);
        if (OTP == null || final == null)
            return;
        final.confirm(OTP).then((result) => {
            formik.handleSubmit();
        }).catch((err) => {
            setloading(false);
            toast.error("Please enter a vaild OTP.");
        })
    }

    const acceptedFiles = e => {
        if (e.target.files.length) {
            setloading(true);
            var formData = new FormData();
            for (let i = 0; i < e.target.files.length; i++) {
                formData.append('image[]', e.target.files[i])
            }
            formData.append("folder_name", 'customer_profile');
            api.postApi('upload-images', formData, true).then(response => {
                formik.setFieldValue("photo_url", response.data[0]);
                setloading(false);
                e.target.value = ''
            }).catch(error => {
                setloading(false);
                toast.error(error.message);
                e.target.value = ''
            });
        }
    }

    useEffect(() => {
        getCountry()
        getAboutMoveList();
    }, [])

    const getAboutMoveList = () => {
        setloading(true)
        api.getApi('get-about-moveit-list').then(response => {
            if (response && response.data) {
                setaboutList(response.data)
            }
            setloading(false)
        }).catch(error => {
            setloading(false)
            toast.error(error.message);
        });
    }

    const [country, setcountry] = useState([]);
    const getCountry = () => {
        api.getApi('get-country').then(response => {
            setcountry(response)
        }).catch(error => {
        });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        ValidateOtp();
    }
    return (
        <>
            <MetaHelmet title="Move It | Signup" description="" />
            {/* <Toaster position="bottom-right" reverseOrder={true}/> */}
            <Header></Header>
            <main>
                <section className="work white mobile-paddtop">
                    <article className="container">
                        <div className="row d-flex align-center" data-aos="fade-left" data-aos-delay="100">

                            <div className="col-md-6">
                                <div className="contact-wrapper">
                                    <div className="col-md-12">
                                        <div className="title">
                                            <h3 className="text-center">Sign Up</h3>

                                            <div ref={ref => recaptchaWrapperRef = ref}>

                                                <div id="recaptcha-container"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <form className="login-page" autoComplete="off" >
                                        <div className="row headshot d-flex justify-content-center customer-loginhead">
                                            <div className="avatar-upload mt-0 mb-0">
                                                <div className="avatar-edit">
                                                    <input type="file" id="imageUpload_profile" accept="image/png, image/gif, image/jpeg" onChange={acceptedFiles} />
                                                    <label htmlFor="imageUpload_profile"></label>

                                                </div>
                                                <div className="avatar-preview">
                                                    <div id="imagePreview" style={{ backgroundImage: formik.values.photo_url ? "url(" + formik.values.photo_url + ")" : "url(web/img/default-profile.png)" }}></div>
                                                </div>
                                            </div>
                                            {formik.touched.photo_url && formik.errors.photo_url ? <div className="col-12 col-md-12 col-lg-10 text-danger text-center">{formik.errors.photo_url}</div> : ''}
                                        </div>

                                        <div className="row" >
                                            <div className="col-md-6 login-page fnt">
                                                <div className="form-row">
                                                    <i className="fa fa-user" style={{ left: '20px' }}></i>
                                                    <input type="text" name="first_name" autoComplete="off"  {...formik.getFieldProps("first_name")} />
                                                    <span>First Name</span>
                                                </div>
                                                {formik.touched.first_name && formik.errors.first_name ? <span className="text-danger">{formik.errors.first_name}</span> : null}
                                            </div>
                                            <div className="col-md-6 login-page fnt">
                                                <div className="form-row">
                                                    <i className="fa fa-user" style={{ left: '20px' }}></i>

                                                    <input type="text" name="last_name" autoComplete="off"  {...formik.getFieldProps("last_name")} />

                                                    <span>Last Name</span>

                                                </div>

                                                {formik.touched.last_name && formik.errors.last_name ? <span className="text-danger">{formik.errors.last_name}</span> : null}
                                            </div>
                                        </div>
                                        <div className="form-row mb-0">
                                            <i className="fa fa-envelope" style={{ left: '20px' }}></i>

                                            <input type="email_id" name="email_id" autoComplete="off" onBlur={(e) => checkEmailExist(e.target.value)}
                                            />
                                            <span>Email</span>

                                        </div>
                                        {formik.touched.email_id && formik.errors.email_id ? <span className="text-danger">{formik.errors.email_id}</span> : null}
                                        {formik.values.checkEmail ? <span className="text-danger">Email already exists</span> : null}
                                        <div className="form-row mb-0">
                                            <i className="fa fa-lock" style={{ left: '20px' }}></i>
                                            <input id="password-field" autoComplete="new-password" name="password" type={passwordType}  {...formik.getFieldProps("password")} />
                                            <span>Password</span>
                                            <div toggle="#password-field" className={(passwordType == "password" ? 'fa fa-fw fa-eye-slash field-icon toggle-password ' : 'fa fa-fw field-icon toggle-password fa-eye')} onClick={togglePassword}></div>

                                        </div>
                                        {formik.touched.password && formik.errors.password ? <span className="text-danger">{formik.errors.password}</span> : null}
                                        <div className="row" >
                                            <div className="col-md-5 login-page fnt">

                                                <div className="form-row phonecountry">
                                                    <div className="select-input">
                                                        <select className="select-text" name="dial_code"  {...formik.getFieldProps("dial_code")} >
                                                            <option className="option-margin" value="">Select</option>
                                                            {country && country.map((option, index) => (
                                                                <option className="option-margin" value={option.id} key={index} >{option.text} </option>
                                                            ))}
                                                        </select>
                                                        <label className="select-label">Select Country</label>
                                                    </div>
                                                </div>
                                                {formik.touched.dial_code && formik.errors.dial_code ? <span className="text-danger">{formik.errors.dial_code}</span> : null}
                                            </div>
                                            <div className="col-md-7 login-page fnt">
                                                <div className="form-row">
                                                    <i className="fa fa-phone" style={{ left: '20px' }}></i>
                                                    <input type="text" autoComplete="off" name="phone_num" {...formik.getFieldProps("phone_num")} />
                                                    <span>Phone No.</span>
                                                </div>
                                                {formik.touched.phone_num && formik.errors.phone_num ? <span className="text-danger">{formik.errors.phone_num}</span> : null}
                                            </div>


                                            <div className="col-md-12 login-page fnt mt10px">
                                                <div className="select-input">
                                                    <select className="select-text" value={formik.values.about_move_it} onChange={(e) => formik.setFieldValue("about_move_it", e.target.value)}>
                                                        <option className="option-margin" value=""></option>
                                                        {aboutList.length > 0 && aboutList.map((option, index) => (
                                                            <option className="option-margin" value={option.value} key={index} >{option.key}</option>
                                                        ))}
                                                    </select>
                                                    <label className="select-label">How did you hear about Move It?</label>
                                                </div>
                                                {formik.touched.about_move_it && formik.errors.about_move_it ? <span className="text-danger">{formik.errors.about_move_it}</span> : null}
                                            </div>

                                        </div>

                                        <label className="checkbox">
                                            <input type="checkbox" name="term_and_condition"   {...formik.getFieldProps("term_and_condition")} className="checkbox__system-checkbox" />
                                            <span className="checkbox__custom-checkbox"></span>
                                            <span className="checkbox__label">By clicking on sign up button you agree to our
                                                <Link to="/terms-condition" target="_blank" style={{ fontSize: "12px" }}> Terms & Conditions</Link> and <Link to="/privacy-policy" target="_blank" style={{ fontSize: "12px" }}>Privacy Policy</Link></span>
                                        </label>
                                        {formik.touched.term_and_condition && formik.errors.term_and_condition ? <span className="text-danger">{formik.errors.term_and_condition}</span> : null}
                                        <div className="form-row">
                                            <button className="full" disabled={loading} type="button" onClick={(e) => finalSubmit(e)}   ><span>Sign Up</span></button>
                                        </div>

                                    </form>

                                    <div className="socials-wrapper">
                                        {/*<div className="s-account">
        							  <h2>Sign Up with your Social Account</h2>
        							</div>
        							<ul>
        							  <li><Link to="#" className="facebook"><i className="fab fa-facebook-square"></i></Link></li>
        							  <li><Link to="#" className="twitter"><i className="fab fa-google"></i></Link></li>
        							</ul>*/}

                                        <h6 className="text-center dont-acct">Already have an account? <Link to="/login">Login</Link></h6>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src="web/img/06.jpg" alt="moveit" className="login-right-img" />
                            </div>

                        </div>
                    </article>
                </section>
            </main>
            <Footer loading={loading} ></Footer>
            <div className={show ? "modal display-block" : "modal display-none"} id="otp-model" tabIndex="-1" role="dialog" aria-labelledby="def" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel2">Enter OTP</h4>
                            <button type="button" className="close" onClick={hideModal} ><span aria-hidden="true" onClick={hideModal}>&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <form className="login-page" onSubmit={handleSubmit}>
                                <p className="mb-0 f-14">Please enter the OTP sent on your mobile phone number.</p>
                                <div className="form-row">
                                    <i className="fa fa-phone"></i>
                                    <input type="text" onChange={setOTPValue} />

                                </div>

                                {/*<div className="form-row"></div>
                <div className="forgot-password f-14">We need your phone number so we can give updates on your moves</div>
                    
                    <div className="forgot-password f-16">
                    <br></br> Not Recevied OTP <Link  to="" >Resend OTP</Link> </div>*/}

                                <div className="form-row">
                                    <button className="full" type="button" onClick={ValidateOtp} ><span>Sign Up</span></button>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default Signup;
