import React,{useState,useEffect} from 'react';
import {Link,useLocation } from 'react-router-dom';

import Service from './../../service'; 
import toast, { Toaster } from 'react-hot-toast';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import Loading from './../loading';

const api = new Service();

const HelperSideBar = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setloading] = useState(false);
    const [isLoggedIn] = useState(localStorage.getItem('user_token') != undefined ? true : false);
    const [data, setData] = useState({})
    useEffect(() => {
        if(props.helperProfile) {
            setData(props.helperProfile)
        }
    }, [props.helperProfile])
    
    const logOut = (e) => {
        setloading(true); 
        api.postApiHelper('helper-sign-out').then(response => {
            localStorage.clear();
            setloading(false);
            toast.success("Logout successfully");
            navigate('/helper-login')
        }).catch(error => { 
            setloading(false);
            toast.error(error.message);
        });
    }
    
    return (
        <>
        <Toaster position="top-right" reverseOrder={false} />
        <div className="col-md-4 col-lg-3">
            <div id="sticky-anchor"></div>
            <Link to="#" id="filterfixed" className="filte-category" data-toggle="modal" data-target="#dashbaordmenu"> <i className="fas fa-bars"></i> Menu</Link>
            <div className="categoerybox-mnone">
                <div className="clearfix"></div>
                <div className="fixed-bar fl-wrap">
                    <div className="user-profile-menu-wrap fl-wrap block_box">
                        
                        <div className="user-profile-menu">
                            <ul className="no-list-style">
                                <li>
                                    <Link to="/helper-moves" className={location.pathname=='/helper-moves'? "user-profile-act" : ""}><i className="fas fa-box-open"></i> My Moves</Link>
                                </li>
                                {data?.service_type==3 || data?.service_type==1?
                                <li>
                                    <Link to="/helper-vehicle-info"  className={location.pathname=='/helper-vehicle-info'? "user-profile-act" : ""}><i className="fas fa-truck-moving"></i> Vehicle Info</Link>
                                </li>
                                : '' }
                                <li>
                                    <Link to="/helper-account-info" className={location.pathname=='/helper-account-info'? "user-profile-act" : ""}><i className="fas fa-file-invoice-dollar"></i> Accounting</Link>
                                </li>
                                <li>
                                    <Link to="/helper-tip-info" className={location.pathname=='/helper-tip-info'? "user-profile-act" : ""}><i className="fab fa-gratipay"></i> Tip Payment Info</Link>
                                </li>
                                <li>
                                    <Link to="/helper-payment-info" className={location.pathname=='/helper-payment-info'? "user-profile-act" : ""}><i className="fas fa-dollar"></i> Payment info</Link>
                                </li>
                                <li>
                                    <Link to="/helper-charge-service" className={location.pathname=='/helper-charge-service'? "user-profile-act" : ""}><i className="fas fa-gears"></i> Change Service</Link>
                                </li>
                                <li>
                                    <Link to="/helper-w9-form" className={location.pathname=='/helper-w9-form'? "user-profile-act" : ""}><i className="fab fa-wpforms"></i> W-9 Form</Link>
                                </li>
                                <li>
                                    <Link to="/helper-setting" className={location.pathname=='/helper-setting'? "user-profile-act" : ""}><i className="fas fa-gears"></i> Setting</Link>
                                </li>
                                <li>
                                    <Link to="/helper-chat"  className={location.pathname=='/helper-chat'? "user-profile-act" : ""}><i className="far fa-comments"></i> Messages</Link>
                                </li>
                                <li>
                                    <Link to="/helper-support" className={location.pathname=='/helper-support'? "user-profile-act" : ""} ><i className="fas fa-headset"></i> Move It Helper Support</Link>
                                </li>
                                {isLoggedIn==999?
                                <li>
                                    <Link to="" onClick={(e) => logOut(e) } ><i className="fas fa-sign-out-alt"></i> Logout</Link>
                                </li>
                                : '' }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
         <Loading loading={loading == true? true : false } />
         
        </>
    )
}
export default HelperSideBar;

