import React, {useState, useEffect} from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { requestForToken, onMessageListener } from './firebase';

const Notification = (props) => {
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isLoggedIn, setisLoggedIn] = useState(localStorage.getItem('user_token') != undefined ? true : false);
  const notify = () =>  {
        if(isLoggedIn) {
            toast.success((<div>
                 <p><b>{notification?.title}</b></p>
                <p>{notification?.body}</p>
            </div>))
        }
  }
  
  useEffect(() => { 
    if (notification?.body){
     notify() 
    } 
  }, [notification]) 
 
  onMessageListener()
    .then((payload) => {
      setNotification({title:"Move It", body: payload?.notification?.body});     
    })
    .catch((err) => console.log('failed: ', err));
  return (
    <Toaster position="top-right" reverseOrder={false} />
  )
}

export default Notification;