import { Link, useLocation, useParams } from 'react-router-dom';

import Header from './layout/Header'; //Include Heder
import Footer from './layout/Footer'; //Include Footer
import Service from './../service';
import toast, { Toaster } from 'react-hot-toast';
import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import MetaHelmet from './metaHelmet'; //Include metaHelmet
import { useNavigate } from 'react-router-dom';

import parse from 'html-react-parser';

const google = window.google;
const api = new Service();
var map;
const ServiceDetail = (props) => {
	const [config, setconfig] = useState(props.config);

	const location = useLocation();
	const [loading, setloading] = useState(false);
	const [moveTypeDetail, setMoveTypeDetail] = useState({});
	const { move_type_id } = useParams();
	const [MoveType, setMoveType] = useState([]);
	useEffect(() => {

		GetMoveTypeDetail();

	}, [])

	const GetMoveTypeDetail = () => {

		api.getApi('get-move-type-by-id', { move_type_id: '/service-' + move_type_id }).then(move_response => {
			setMoveTypeDetail(move_response.data);
			GetMoveType();
			setloading(false)
		}).catch(error => {
			setloading(false);
		});
	}

	const GetMoveType = () => {
		api.getApi('get-all-move-type').then(response => {
			setMoveType(response.move_type);
		}).catch(error => {

		});
	}

	useEffect(() => {
		GetMoveTypeDetail();
	}, [location.pathname])

	useEffect(() => {
		document.title = moveTypeDetail.meta_title;
		window.gtag('config', config.G_TAG, {
			page_title: document.title,
			page_path: window.location.pathname,
		});
	}, [moveTypeDetail]);


	return (
		<>
			<MetaHelmet title={moveTypeDetail && moveTypeDetail.meta_title ? moveTypeDetail.meta_title : "Move It Service"} description={moveTypeDetail && moveTypeDetail.meta_description ? moveTypeDetail.meta_description : "Move It Service"} />
			<Toaster position="top-right" reverseOrder={false} />
			<Header></Header>
			<main>
				<section className="hero clean faq-andimg" style={{ backgroundImage: `url('web/img/04.jpg')` }}>
					<article>
						<div className="grid">
							<div className="unit-2-3 unit-1-1-md">
								<h1 className="h2 f-46">Move It Services</h1>
							</div>
						</div>
					</article>
				</section>





				<div className="mv_lightgray_wrapper" data-scroll-index="1">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
								<div className="mv_aboutus1">
									<h2>{moveTypeDetail?.name}</h2>

									{moveTypeDetail ? parse("" + moveTypeDetail.web_description + "") : ''}
									{/* <h6>{moveTypeDetail?.description}</h6> */}
									{/* <Link to="" className="btn bookbtn-service step-btn"> Book Move </Link> */}

								</div>
							</div>
							<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
								<div className="mv_aboutus2">
									{moveTypeDetail?.image_url ?
										<img src={moveTypeDetail?.image_url} alt="movers-about" className="img-responsive" />
										: ''}
								</div>
							</div>
						</div>
					</div>
				</div>


				<section className="slate cspt-steps-counter">
					<article>
						<h3 className="margin-bottom-lg text-center howitworkpage"><span className="flourish text"><span className="flourish-square f-22"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(255,149,162)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span> How to book your Move It job</span></h3>



						<div className="vc_row wpb_row vc_inner row cspt-steps-counter cspt-row-inner cspt-bg-color-yes cspt-bg-color-transparent cspt-zindex-zero">

							<div className="vc_column_container col-sm-6 col-md-4 col-lg-4 cspt-column-inner cspt-bg-color-yes cspt-bg-color-transparent cspt-zindex-zero">
								<div className="vc_column-inner "><div className="wpb_wrapper">
									<div className="cspt-ihbox cspt-ihbox-style-5">
										<div className="cspt-ihbox-icon">
											<div className="cspt-ihbox-icon-wrapper">
												<span className="numberstep">01</span>
												<small>Step</small>
											</div>
										</div>
										<div className="cspt-ihbox-contents">
											<div className="cspt-ihbox-heading"><h2 className="vc_custom_heading">Enter Your Details</h2></div>
											<div className="cspt-ihbox-content">Tell us when, where, and what you need delivered or moved</div>
										</div>
									</div>
								</div>
								</div>
							</div>


							<div className="vc_column_container col-sm-6 col-md-4 col-lg-4 cspt-column-inner cspt-bg-color-yes cspt-bg-color-transparent cspt-zindex-zero">
								<div className="vc_column-inner ">
									<div className="wpb_wrapper">
										<div className="cspt-ihbox cspt-ihbox-style-5 ">
											<div className="cspt-ihbox-icon">
												<div className="cspt-ihbox-icon-wrapper">
													<span className="numberstep">02</span>
													<small>Step</small>
												</div>
											</div>

											<div className="cspt-ihbox-contents">
												<div className="cspt-ihbox-heading"><h2 className="vc_custom_heading">Get Your Guaranteed Price</h2></div>
												<div className="cspt-ihbox-content">Select how many helpers you need and get an instant price quote.</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="vc_column_container col-sm-6 col-md-4 col-lg-4 cspt-column-inner cspt-bg-color-yes cspt-bg-color-transparent cspt-zindex-zero">
								<div className="vc_column-inner ">
									<div className="wpb_wrapper">
										<div className="cspt-ihbox cspt-ihbox-style-5 ">
											<div className="cspt-ihbox-icon">
												<div className="cspt-ihbox-icon-wrapper">
													<span className="numberstep">03</span>
													<small>Step</small>
												</div>
											</div>

											<div className="cspt-ihbox-contents">
												<div className="cspt-ihbox-heading"><h2 className="vc_custom_heading">Book Your Move It Pros and/or Muscles</h2></div>
												<div className="cspt-ihbox-content">Get connected to and scheduled with Move It Pros and Muscles right away.</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>





					</article>
				</section>





				<section className="moveservicepage">
					<article>
						<h3 className="margin-bottom-lg text-center"><span className="flourish text"><span className="flourish-square"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(255,149,162)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span> Get the Moving and Delivery Help You Need, When You Need It</span></h3>
						<div className="grid grid-flex justify-content-center our-services">

							{MoveType.length > 0 && MoveType.map((option, index) => (
								<div className="unit-1-3 unit-1-3-md unit-1-2-sm margin-bottom serv" key={index}>
									<Link to={option.alias}>
										<p className="img-holder"><img alt="" src={option.image_url} className="" /></p>

										<div className="detailsservice">
											<h5>{option.name}</h5>
											<div className="icon-ser"></div>
											<p>{option.description}</p>
										</div>
									</Link>
								</div>
							))}


						</div>

					</article>
				</section>

				<Link className="section red padd-50" to="/contact-us">
					<article className="text-center">
						<h6 className="h2">Still have  <strong> questions? </strong></h6>
						<span className="button outline inverse">Contact Us <i className="fa fa-chevron-right"></i></span>
					</article>
				</Link>



			</main>
			<Footer loading={loading}></Footer>
		</>
	)

}

export default ServiceDetail;