// firebaseConfig.js
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/messaging"; // Include messaging if needed

const firebaseConfig = {
  apiKey: "AIzaSyAUxQrPnfK-uSFOqbFfbSFqQQq5iB7F6Qo",
  authDomain: "move-it-customer-android.firebaseapp.com",
  projectId: "move-it-customer-android",
  storageBucket: "move-it-customer-android.appspot.com",
  messagingSenderId: "749185849516",
  appId: "1:749185849516:web:049d3ac2b1d035d31e6709",
  measurementId: "G-SYD8CJWZ60"
};

// Initialize Firebase only if it hasn't been initialized yet
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Exporting auth and firestore instances
const auth = firebase.auth();
const firestore = firebase.firestore();
const messaging = firebase.messaging(); // If you use messaging

export { auth, firestore, messaging, firebase };
