import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
// 1 user typ2 customer 
// 2 user type helper
import Service from '../../service';
import { useNavigate } from 'react-router-dom';
const config = require('../../config.json');

const api = new Service();

const HeaderMoveitLicense = (props) => {
    // console.log(config.PRODUCTION_MODE)
    // const [config, setconfig] = useState(props.config);
    const location = useLocation();
    const { request_id } = useParams();
    const { draft_id } = useParams();
    const navigate = useNavigate();
    const [isLoggedIn] = useState(localStorage.getItem('user_token') ? true : false);
    const [UserType] = useState(localStorage.getItem('user_type'));
    const [Verfied] = useState(1);
    const [mobileMenu, setmobileMenu] = useState(false);
    const [sideBookMove, setsideBookMove] = useState(false);
    const [MoveType, setMoveType] = useState([]);
    const GetMoveType = () => {
        api.getApi('get-all-move-type').then(response => {
            setMoveType(response.move_type);
        }).catch(error => {

        });
    }

    useEffect(() => {

        if (props.helperProfile) {
            if (props.helperProfile.is_verified == 0) {
                navigate('/helper-pending')
            } else if ((props.helperProfile.w9_form_verified == 0 && props.helperProfile.w9_form_status == 0) || props.helperProfile.w9_form_verified == 2) {
                navigate('/helper-w9-form')
            } else if (location.pathname == '/helper-moves' && (props.helperProfile.w9_form_verified == 0 && props.helperProfile.w9_form_status == 1)) {
                navigate('/helper-pending-w9-form')
            }
        }

    }, [props.helperProfile])

    useEffect(() => {
        if (location.pathname) {
            setmobileMenu(false)

        }
        if (location.pathname == '/book-move' || location.pathname == '/book-move/' + request_id || location.pathname == '/book-moves/draft/' + draft_id) {
            setsideBookMove(true)
        } else {
            setsideBookMove(false)
        }
        GetMoveType();
    }, [location.pathname, request_id])


    const handleClick = () => {
        localStorage.removeItem('user_token');
        localStorage.removeItem('user_type');
        localStorage.removeItem('user_detail');
    }

    const openCloseMobileMenu = () => {
        if (mobileMenu == true) {
            setmobileMenu(false)
        } else {
            setmobileMenu(true)
        }
        console.log(mobileMenu)

    }


    return (
        <>

            <div className={mobileMenu ? "home menumega open" : "home menumega"}>
                <header>
                    <span className="hamburger" onClick={() => openCloseMobileMenu()} ><span></span></span>
                    <Link className="logo" to="/"><img src="/web/img/logo.png" alt="Move It Logo" /></Link>
                    <nav className="primary">
                        <ul>
                            <li> 
                                <Link to="/moveit-licensing" className="">Move It Licensing</Link>
                            </li>
                            <li>
                                <Link to="/moveit-licensing-our-services" className="">Our Services</Link>
                            </li>
                            <li>
                                <Link to="/moveit-licensing-why-us" className="">Why Us</Link>
                            </li>
                            <li>
                                <Link to="/moveit-licensing-faq" className="">FAQs</Link>
                            </li>
                            <li>
                                <Link to=""><span>Get Started<i className="fas fa-chevron-right"></i></span></Link>
                            </li>
                        </ul>
                    </nav>
                </header>
            </div>





        </>
    )
}
export default HeaderMoveitLicense;




